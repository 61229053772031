import type { EmailService } from '@voyage-lab/core-email';
import type { DatabaseEnum, PostgrestClientType } from '@voyage-lab/db';

import type { Payment } from './payment/base';
import { ShopifyPaymentProcessor } from './payment/provider/shopify';
import { StripePaymentProcessor } from './payment/provider/stripe';
import { Subscription } from './subscription';

export class BillingData extends Subscription {
	#stripeApiKey: string;
	#shopifyApiKey: string;
	#returnUrl: string;
	#dbClient: PostgrestClientType;
	#email: EmailService;

	constructor(props: {
		stripeApiKey: string;
		shopifyApiKey: string;
		dbClient: PostgrestClientType;
		email: EmailService;
	}) {
		super(props.dbClient);
		this.#stripeApiKey = props.stripeApiKey;
		this.#shopifyApiKey = props.shopifyApiKey;
		this.#dbClient = props.dbClient;
		this.#email = props.email;
	}

	public createPaymentProcessor(
		paymentSource: DatabaseEnum['t_payment_types'],
		returnUrl: string,
		environment: string
	): Payment {
		this.#returnUrl = returnUrl;
		if (paymentSource === 'stripe') {
			return new StripePaymentProcessor({
				stripeApiKey: this.#stripeApiKey,
				returnUrl: this.#returnUrl,
				dbClient: this.#dbClient,
				email: this.#email,
			});
		} else if (paymentSource === 'shopify') {
			return new ShopifyPaymentProcessor({
				shopifyApiKey: this.#shopifyApiKey,
				returnUrl: this.#returnUrl,
				dbClient: this.#dbClient,
				email: this.#email,
				environment: environment,
			});
		} else {
			throw new Error('Invalid payment gateway');
		}
	}
}
