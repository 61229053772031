const gql = String.raw;

export const CREATE_RECURRING_SUBSCRIPTION = gql`
	mutation AppSubscriptionCreate(
		$name: String!
		$lineItems: [AppSubscriptionLineItemInput!]!
		$returnUrl: URL!
		$trialDays: Int
		$test: Boolean
	) {
		appSubscriptionCreate(
			name: $name
			returnUrl: $returnUrl
			lineItems: $lineItems
			trialDays: $trialDays
			test: $test
		) {
			userErrors {
				field
				message
			}
			appSubscription {
				id
				test
			}
			confirmationUrl
		}
	}
`;

export const CANCEL_SUBSCRIPTION = gql`
	mutation AppSubscriptionCancel($id: ID!) {
		appSubscriptionCancel(id: $id) {
			userErrors {
				field
				message
			}
			appSubscription {
				id
				status
			}
		}
	}
`;

export const UPDATE_SUBSCRIPTION = gql`
	mutation appSubscriptionLineItemUpdate($cappedAmount: MoneyInput!, $id: ID!) {
		appSubscriptionLineItemUpdate(cappedAmount: $cappedAmount, id: $id) {
			userErrors {
				field
				message
			}
			confirmationUrl
			appSubscription {
				id
			}
		}
	}
`;
