import type { DatabaseEntity, DatabaseEnum, PostgrestClientType } from '@voyage-lab/db';
import { stemWord } from '@voyage-lab/core-common';
import { v4 as uuid } from 'uuid';
import { Schema } from '@voyage-lab/schema';
const now = new Date().toISOString();

export class KnowledgeBaseData {
	#dbClient: PostgrestClientType;

	/**
	 * Initializes a new instance of the KnowledgeBaseData class.
	 * @param dbClient - The database client object.
	 */
	constructor(dbClient: PostgrestClientType) {
		this.#dbClient = dbClient;
	}

	/**
	 * Search data from the database based on the provided search criteria.
	 * @param props - An object containing the search criteria.
	 * @param props.brandId - The ID of the front conversation.
	 * @param props.searchKey - The search keyword.
	 * @returns An array of data matching the search criteria.
	 */
	async search(props: { brandId?: string; searchKey?: string }) {
		if (!props?.brandId) return [];
		const query = this.#dbClient.from('kb_cards').select('*');

		let cleanWordOnlyArray: string[] = [];

		if (props?.searchKey) {
			cleanWordOnlyArray = props?.searchKey
				.split(' ')
				.flatMap((word) => word.replace(/[^a-zA-Z0-9]/g, ' ').split(' '))
				.filter((word) => word.length > 2);

			// unify the search query
			cleanWordOnlyArray = [...new Set(cleanWordOnlyArray)];

			// add stemmed words to the search query
			const stemmedWords = await Promise.all(cleanWordOnlyArray.map((word) => stemWord(word)));
			cleanWordOnlyArray = [...cleanWordOnlyArray, ...stemmedWords];

			// remove duplicates
			cleanWordOnlyArray = [...new Set(cleanWordOnlyArray)];

			const orSearchQuery = cleanWordOnlyArray.map((word) => `'${word}'`).join(' | ');
			query.or(`content.fts.${orSearchQuery},title.fts.${orSearchQuery}`);
		}

		if (props?.brandId) query.eq('brand_id', props?.brandId);

		const { data, error, count } = await query;

		// if no results then fetch all and do fuzzy search on client
		if (data?.length === 0 && cleanWordOnlyArray?.length > 0) {
			const fallbackQuery = this.#dbClient.from('kb_cards').select('*').neq('status', 'unpublished').limit(100);
			if (props?.brandId) fallbackQuery.eq('brand_id', props?.brandId);
			const { data: allData, error: allError } = await fallbackQuery;

			if (allError) throw allError;

			const fuzzySearchData = allData.filter(async (card) => {
				const valueToSearch = `${card.title} ${card.content}`;

				// tokenize, clean, stem, and remove duplicates
				const valueToSearchArray = valueToSearch
					.split(' ')
					.flatMap((word) => word.replace(/[^a-zA-Z0-9]/g, ' ').split(' '))
					.filter((word) => word.length > 2);

				const stemmedWords = await Promise.all(valueToSearchArray.map((word) => stemWord(word)));
				const cleanedWords = [...valueToSearchArray, ...stemmedWords];
				const uniqueWords = [...new Set(cleanedWords)];

				// check if any of the words are in the search query
				const isMatch = uniqueWords.some((word) => cleanWordOnlyArray.includes(word));

				return isMatch;
			});

			return fuzzySearchData;
		}

		if (error) throw error;
		return data;
	}

	/**
	 * Retrieves data from the database based on the provided criteria.
	 * @param props - An object containing the search criteria.
	 * @param props.brandId - The ID of the brand.
	 * @param props.flowId - The ID of the flow.
	 * @param props.ids - An array of specific IDs to retrieve.
	 * @returns An array of data matching the criteria.
	 */
	async get(props: { brandId?: string; flowId?: string; ids?: string[]; sort?: { priority: boolean } }) {
		const query = this.#dbClient.from('kb_cards').select('*');

		if (props.brandId) query.eq('brand_id', props.brandId);
		if (props.flowId) {
			query.eq('flow_id', props.flowId);
			query.eq('source', 'flow');
		}
		if (props.ids && props.ids.length > 0) {
			query.in('id', props.ids);
		}

		// if (props.sort?.priority) {
		// 	query.order('extra_data->weight', { ascending: false });
		// }

		// query.order('updated_at', { ascending: true });

		const { data } = await query;
		return data;
	}

	/**
	 * Creates a new knowledge base card.
	 * @param {DatabaseEntity<'insert'>['kb_cards']} props - The properties of the knowledge base card to create.
	 * @returns {Promise<DatabaseEntity<'insert'>['kb_cards']>} The created knowledge base card.
	 */
	async create(props: { data: Partial<DatabaseEntity<'insert'>['kb_cards']> }) {
		const parsed = Schema.kbCardsInputSchema
			.pick({
				author_id: true,
				brand_id: true,
				title: true,
				content: true,
				status: true,
				source: true,
				flow_id: true,
			})
			.parse(props.data);

		const res = await this.#dbClient
			.from('kb_cards')
			.insert({
				...parsed,
				id: uuid(),
				created_at: now,
				updated_at: now,
				legacy_migrated: false,
				extra_data: {
					weight: new Date().getTime(),
				},
			})
			.select('*');

		return res.data;
	}

	/**
	 * Updates an existing knowledge base card.
	 * @param {string} id - The ID of the knowledge base card to update.
	 * @param {DatabaseEntity<'update'>['kb_cards']} data - The updated data for the knowledge base card.
	 * @returns {Promise<PostgrestResponse<DatabaseEntity<'update'>['kb_cards']>>} The result of the update operation.
	 * @throws {Error} If no ID is provided for the update.
	 */
	async update(id: string, data: DatabaseEntity<'update'>['kb_cards']) {
		if (!id) throw new Error('No ID provided for update');
		const res = await this.#dbClient.from('kb_cards').update(data).eq('id', id);
		return res;
	}

	/**
	 * Updates the status of a knowledge base card or cards associated with a flow.
	 * @param {Object} props - The properties for the status update.
	 * @param {string} [props.id] - The ID of the specific knowledge base card to update.
	 * @param {string} [props.flowId] - The ID of the flow to update associated cards.
	 * @param {DatabaseEnum['t_kb_status']} [props.status] - The new status to set.
	 * @returns {Promise<PostgrestResponse<DatabaseEntity<'update'>['kb_cards']>>} The result of the update operation.
	 * @throws {Error} If neither id nor flowId is provided.
	 */
	async updateStatus(props: { id?: string; flowId?: string; status?: DatabaseEnum['t_kb_status'] }) {
		const query = this.#dbClient.from('kb_cards').update({
			status: props.status,
			updated_at: now,
		});

		if (props.flowId) query.eq('flow_id', props.flowId);
		if (props.id) query.eq('id', props.id);

		const res = await query;
		return res;
	}

	/**
	 * Retrieves a single knowledge base card by its ID.
	 * @param {Object} props - The properties for retrieving the card.
	 * @param {string} props.id - The ID of the knowledge base card to retrieve.
	 * @returns {Promise<DatabaseEntity<'select'>['kb_cards'] | null>} The retrieved knowledge base card or null if not found.
	 * @throws {Error} If no ID is provided for the retrieval.
	 */
	async getSingle(props: { id: string }) {
		if (!props?.id) throw new Error('No ID provided for getSingle');
		const res = await this.#dbClient.from('kb_cards').select('*').eq('id', props?.id).maybeSingle();
		return res.data;
	}
}
