// Generated by ts-to-zod
import { z } from 'zod';

export const brandIntegrationFrontJsonSchema = z.object({
	settings: z.object({
		channel_id: z.string(),
	}),
});

export const brandIntegrationInfobipJsonSchema = z.object({
	settings: z
		.object({
			sender_id: z.string().optional(),
			phone: z.string().optional(),
			credentials: z.object({
				api_base_url: z.string().optional(),
				api_key: z.string().optional(),
			}),
		})
		.optional(),
});

export const brandIntegrationShopifySettingsSchema = z.object({
	currency: z.string(),
	uninstalled_at: z.null().optional(),
	country_code: z.string(),
	shopify_id: z.number(),
	credentials: z.object({
		scopes: z.array(z.string()).optional().nullable(),
		access_token: z.string(),
	}),
	shopify_plan_name: z.string(),
	meta: z.record(z.string()),
	installed_at: z.string(),
	domain: z.string(),
	shopify_domain: z.string(),
	test: z.boolean().optional(),
});

export const paymentSourceSchema = z.object({
	external_id: z.string(),
	gateway: z.union([z.literal('shopify'), z.literal('stripe'), z.literal('not_applicable')]),
});

export const subscriptionSchema = z.object({
	status: z.union([
		z.literal('future'),
		z.literal('in_trial'),
		z.literal('active'),
		z.literal('non_renewing'),
		z.literal('paused'),
		z.literal('cancelled'),
		z.literal('pending'),
	]),
	external_id: z.string(),
	brand_id: z.string(),
	billing_method: z.union([z.literal('auto'), z.literal('manual')]).nullable(),
	plan_id: z.string(),
	payment_source_id: z.string(),
	brands: z
		.object({
			name: z.string(),
		})
		.nullable(),
	plans: z
		.object({
			usage_charge_percentage: z.number(),
		})
		.nullable(),
});

export const subscriptionChargeSchema = z.object({
	status: z.union([z.literal('queued'), z.literal('pending'), z.literal('complete')]),
	type: z.union([z.literal('usage'), z.literal('recurring')]),
	amount: z.number(),
	brand_id: z.string(),
	external_id: z.string().nullable(),
	subscription_id: z.string(),
});

export const twillioIntegrationInputSchema = z.object({
	integration_id: z.string(),
	account_sid: z.string().optional(),
	auth_token: z.string().optional(),
	customer_profile_bundle_sid: z.string().optional(),
	a2p_profile_bundle_sid: z.string().optional(),
	a2p_brand_registration_sid: z.string().optional(),
	messaging_service_sid: z.string(),
});

export const infoBipIntegrationInputSchema = z.object({
	integration_id: z.string(),
	api_base_url: z.string().url().optional(),
	api_key: z.string().optional(),
	sender_id: z.string().optional(),
	phone: z.string(),
});
