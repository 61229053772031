import { Array } from './array';
import { Object } from './object';
import { browserCookieStorage } from './browser-cookie-storage';
import { browserLocalStorage } from './browser-local-storage';
import { browserSessionStorage } from './browser-session-storage';
import { Calcs } from './calcs';
import { Csv } from './csv';
import { Number } from './number';
import { Phone } from './phone';
import { String } from './string';
import { Time } from './time';

export type { BrowserLocalStorageReturn } from './browser-local-storage';
export type { BrowserCookieStorageReturn } from './browser-cookie-storage';
export type { BrowserSessionStorageReturn } from './browser-session-storage';

interface HelpersReturn {
	Array: typeof Array;
	Object: typeof Object;
	browserLocalStorage: typeof browserLocalStorage;
	browserCookieStorage: typeof browserCookieStorage;
	browserSessionStorage: typeof browserSessionStorage;
	Calcs: typeof Calcs;
	Csv: typeof Csv;
	Phone: typeof Phone;
	String: typeof String;
	Time: typeof Time;
	Number: typeof Number;
}

export const Helpers = {
	Array,
	Object,
	browserLocalStorage: browserLocalStorage,
	browserCookieStorage: browserCookieStorage,
	browserSessionStorage: browserSessionStorage,
	Calcs,
	Csv,
	Phone,
	String,
	Time,
	Number,
} as HelpersReturn;
