import _groupBy from 'lodash/groupBy';

/**
 * Creates an object composed of keys generated from the results of running each element of collection through
 * iteratee. The corresponding value of each key is an array of the elements responsible for generating the
 * key. The iteratee is invoked with one argument: (value).
 *
 * @param array The collection to iterate over.
 * @param groupKey The property name to group by.
 * @return Returns the composed aggregate object.
 */

export const groupBy = <DataT>(array: DataT[], groupKey: keyof DataT) => _groupBy(array, groupKey);

/**
 * Calculates the sum of a specified field across an array of objects.
 *
 * @template T The type of objects in the array.
 * @param {T[] | undefined} data The array of objects to sum over.
 * @param {(d: T) => number | null | undefined} field A function that extracts the numeric value from each object.
 * @returns {number} The sum of the specified field across all objects, or 0 if the array is empty or undefined.
 */
export const sum = <T>(data: T[] | undefined, field: (d: T) => number | null | undefined) => {
	return data?.reduce((acc, item) => acc + (field(item) || 0), 0) || 0;
};

/**
 * Calculates the average of a specified field across an array of objects.
 *
 * @template T The type of objects in the array.
 * @param {T[] | undefined} data The array of objects to average over.
 * @param {(d: T) => number | null | undefined} field A function that extracts the numeric value from each object.
 * @returns {number} The average of the specified field across all objects, or 0 if the array is empty or undefined.
 */
export const avg = <T>(data: T[] | undefined, field: (d: T) => number | null | undefined) => {
	const total = data?.reduce((acc, item) => acc + (field(item) || 0), 0) || 0;
	return total / (data?.length || 1);
};

/**
 * Filters an array to remove duplicate values based on a specified field.
 *
 * @template T The type of objects in the array.
 * @param {T[] | undefined} data The array of objects to filter.
 * @param {(d: T) => number | string | null | undefined} field A function that extracts the value to check for uniqueness from each object.
 * @returns {T[] | undefined} An array with duplicate values removed based on the specified field.
 */
export const unique = <T>(data: T[] | undefined, field: (d: T) => number | string | null | undefined) => {
	return data?.filter((d, index, self) => index === self.findIndex((t) => field(t) === field(d))) || [];
};
