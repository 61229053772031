import { unparse } from 'papaparse';

/**
 * ### Parse array to comma separated string based on RFC 4180
 * @param values Array of values
 * @returns Values as comma separated string, double quotes are added to values with comma
 * @see https://tools.ietf.org/html/rfc4180
 */
export function arrayToString(values: unknown[]): string {
	if (!values) return '';

	const result = unparse([values]);
	return result;
}
