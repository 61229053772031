import type { DatabaseEnum } from '@voyage-lab/db';

export const CONVERSATION_OPEN_STATES: DatabaseEnum['t_checkout_state'][] = [
	'initial',
	'message_queued',
	'messaged',
	'discount_sent',
	'filtered',
	'no_identity',
];

export const CONVERSATION_CLOSED_STATES: DatabaseEnum['t_checkout_state'][] = [
	'recovered',
	'errored',
	'skipped',
	'abandoned',
	'abandoned_processing',
];
