const log = global.console.log;
const error = global.console.error;
const warn = global.console.warn;
const info = global.console.info;
const debug = global.console.debug;
const trace = global.console.trace;

export function setupLogger({
	level = 'info',
	prefix = '',
	timestamp = false,
	includeLogLevel = false,
	pretty = false,
	override = true,
}: SetupLoggerOptions) {
	const logLevel = LogLevelIndex[level];

	// Override the built-in console log with support for log levels and frameworks with pretty colors

	const formatMessage = (logLevel: string, args: unknown[]) => {
		const formattedArgs = [...args];
		if (timestamp) {
			formattedArgs.unshift(new Date().toISOString());
		}
		if (includeLogLevel) {
			formattedArgs.unshift(`[${logLevel.toUpperCase()}]`);
		}
		if (prefix) {
			formattedArgs.unshift(prefix);
		}
		return [...new Set(formattedArgs)];
	};

	const prettyPrint = pretty
		? (args: unknown[]) => args.map((arg) => (typeof arg === 'object' ? JSON.stringify(arg, null, 2) : arg))
		: (args: unknown[]) => args;

	// Override with pretty logging
	const console: typeof global.console = {
		...global.console,
		error: (...args) => {
			if (logLevel < LogLevelIndex.error) return;
			error(...prettyPrint(formatMessage('error', args)));
		},
		warn: (...args) => {
			if (logLevel < LogLevelIndex.warn) return;
			warn(...prettyPrint(formatMessage('warn', args)));
		},
		info: (...args) => {
			if (logLevel < LogLevelIndex.info) return;
			info(...prettyPrint(formatMessage('info', args)));
		},
		log: (...args) => {
			if (logLevel < LogLevelIndex.log) return;
			log(...prettyPrint(formatMessage('log', args)));
		},
		debug: (...args) => {
			if (logLevel < LogLevelIndex.debug) return;
			debug(...prettyPrint(formatMessage('debug', args)));
		},
		trace: (...args) => {
			if (logLevel < LogLevelIndex.trace) return;
			trace(...prettyPrint(formatMessage('trace', args)));
		},
	};

	if (override) {
		global.console = console;
	}

	return console;
}

type SetupLoggerOptions = {
	level: keyof typeof LogLevelIndex;
	prefix?: string;
	timestamp?: boolean;
	includeLogLevel?: boolean;
	pretty?: boolean;
	override?: boolean;
};

const LogLevelIndex = {
	error: 0,
	warn: 1,
	info: 2,
	log: 3,
	debug: 4,
	trace: 5,
};
