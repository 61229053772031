const gql = String.raw;

export const SCRIPT_TAG_QUERY = gql`
	query {
		scriptTags(first: 100) {
			edges {
				node {
					id
					cache
					src
					displayScope
					createdAt
					updatedAt
				}
			}
		}
	}
`;

export const SCRIPT_TAG_CREATE = gql`
	mutation ScriptTagCreate($input: ScriptTagInput!) {
		scriptTagCreate(input: $input) {
			scriptTag {
				id
				cache
				createdAt
				displayScope
				src
				updatedAt
			}
			userErrors {
				field
				message
			}
		}
	}
`;

export const SCRIPT_TAG_DELETE = gql`
	mutation ScriptTagDelete($id: ID!) {
		scriptTagDelete(id: $id) {
			deletedScriptTagId
			userErrors {
				field
				message
			}
		}
	}
`;
