interface BrowserLocalStorageReturn<Keys> {
	set: <Key extends keyof Keys>(key: Key, value: Keys[Key]) => void | Error;
	get: <Key extends keyof Keys>(key: Key) => ReturnData<Keys[Key]>;
	remove: <Key extends keyof Keys>(key: Key) => void | Error;
}

export interface ReturnDataError {
	ok: false;
	error: string;
	data?: never;
}

export interface ReturnDataSuccess<Data> {
	ok: true;
	error?: never;
	data: Data;
}

export type ReturnData<Data> = ReturnDataError | ReturnDataSuccess<Data>;

export function browserLocalStorage<Keys>(): BrowserLocalStorageReturn<Keys> {
	function getItem<Key extends keyof Keys>(key: Key): ReturnData<Keys[Key]> {
		try {
			const value = window.localStorage.getItem(String(key));
			if (value === null || value === undefined) {
				return {
					ok: false,
					error: 'Failed to get key',
				};
			}
			return {
				ok: true,
				data: JSON.parse(value),
			};
		} catch (err) {
			return {
				ok: false,
				error: 'Failed to get key',
			};
		}
	}
	function setItem<Key extends keyof Keys>(key: Key, value: Keys[Key]): void | Error {
		try {
			window.localStorage.setItem(String(key), JSON.stringify(value));
			return;
		} catch (err) {
			return new Error('Failed to set key');
		}
	}

	function removeItem<Key extends keyof Keys>(key: Key): void | Error {
		try {
			window.localStorage.removeItem(String(key));
			return;
		} catch (err) {
			return new Error('Failed to remove key');
		}
	}

	return {
		set: setItem,
		get: getItem,
		remove: removeItem,
	};
}

export type { BrowserLocalStorageReturn };
