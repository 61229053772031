import { Constants } from '../../constants';

/**
 * Returns a phone object from a phone number
 * @param phoneNumber
 */
export const getPhoneObject = (phoneNumber: number | string) => {
	// Return null if phone number is invalid
	if (!isValidPhone(phoneNumber)) return null;

	// Cleanup to only have digits
	const cleaned = String(phoneNumber).replace(/\D/g, '');

	// Match digits to US phone number format
	const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

	// Return null if phone number doesn't match US phone number format
	if (!match) return null;

	return {
		countryCode: Constants.Phone.UsCountryCode,
		areaCode: match[2],
		officeCode: match[3],
		lineNumber: match[4],
	};
};

/**
 * Returns true if phone number is valid
 * @param phoneNumber
 * @returns `true` if phone number is valid, `false` otherwise
 * @example `isValidPhone(1234567890) // true`
 */
export const isValidPhone = (phoneNumber: number | string): boolean => {
	const cleaned = String(phoneNumber).replace(/\D/g, '');

	// Phone number has proper digit count
	if (cleaned.length === Constants.Phone.DigitCount) return true;
	if (cleaned.length === Constants.Phone.FullDigitCount) return true;

	// Phone number doesn't have proper digit count
	return false;
};
