// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { z } from 'zod';

import {
	AppDeveloperType,
	AppInstallationCategory,
	AppInstallationPrivacy,
	AppInstallationSortKeys,
	AppPlanInput,
	AppPricingInterval,
	AppPublicCategory,
	AppPurchaseStatus,
	AppRecurringPricingInput,
	AppRevenueAttributionRecordCreateUserErrorCode,
	AppRevenueAttributionRecordDeleteUserErrorCode,
	AppRevenueAttributionRecordInput,
	AppRevenueAttributionRecordSortKeys,
	AppRevenueAttributionType,
	AppSubscriptionDiscountInput,
	AppSubscriptionDiscountValueInput,
	AppSubscriptionLineItemInput,
	AppSubscriptionReplacementBehavior,
	AppSubscriptionSortKeys,
	AppSubscriptionStatus,
	AppSubscriptionTrialExtendUserErrorCode,
	AppTransactionSortKeys,
	AppUsagePricingInput,
	AppUsageRecordSortKeys,
	AttributeInput,
	AutomaticDiscountSortKeys,
	BadgeType,
	BillingAttemptUserErrorCode,
	BulkMutationErrorCode,
	BulkOperationErrorCode,
	BulkOperationStatus,
	BulkOperationType,
	BulkProductResourceFeedbackCreateUserErrorCode,
	BusinessCustomerErrorCode,
	BuyerExperienceConfigurationInput,
	CheckoutProfileSortKeys,
	CodeDiscountSortKeys,
	CollectionAddProductsV2UserErrorCode,
	CollectionDeleteInput,
	CollectionInput,
	CollectionPublicationInput,
	CollectionPublishInput,
	CollectionRuleColumn,
	CollectionRuleInput,
	CollectionRuleRelation,
	CollectionRuleSetInput,
	CollectionSortKeys,
	CollectionSortOrder,
	CollectionUnpublishInput,
	CompanyAddressInput,
	CompanyAddressType,
	CompanyContactInput,
	CompanyContactRoleAssign,
	CompanyContactRoleAssignmentSortKeys,
	CompanyContactRoleSortKeys,
	CompanyContactSortKeys,
	CompanyCreateInput,
	CompanyInput,
	CompanyLocationInput,
	CompanyLocationRoleAssign,
	CompanyLocationSortKeys,
	CompanyLocationUpdateInput,
	CompanySortKeys,
	ContextualPricingContext,
	CountryCode,
	CountryHarmonizedSystemCodeInput,
	CreateMediaInput,
	CropRegion,
	CurrencyCode,
	CustomerConsentCollectedFrom,
	CustomerDeleteInput,
	CustomerEmailAddressMarketingState,
	CustomerEmailAddressOpenTrackingLevel,
	CustomerEmailMarketingConsentInput,
	CustomerEmailMarketingConsentUpdateInput,
	CustomerEmailMarketingConsentUpdateUserErrorCode,
	CustomerEmailMarketingState,
	CustomerInput,
	CustomerMarketingOptInLevel,
	CustomerPaymentMethodGetUpdateUrlUserErrorCode,
	CustomerPaymentMethodRemoteInput,
	CustomerPaymentMethodRemoteUserErrorCode,
	CustomerPaymentMethodRevocationReason,
	CustomerPaymentMethodUserErrorCode,
	CustomerPredictedSpendTier,
	CustomerProductSubscriberStatus,
	CustomerSavedSearchSortKeys,
	CustomerSmsMarketingConsentErrorCode,
	CustomerSmsMarketingConsentInput,
	CustomerSmsMarketingConsentUpdateInput,
	CustomerSmsMarketingState,
	CustomerSortKeys,
	CustomerState,
	DayOfTheWeek,
	DelegateAccessTokenCreateUserErrorCode,
	DelegateAccessTokenInput,
	DeletionEventSortKeys,
	DeletionEventSubjectType,
	DeliveryConditionField,
	DeliveryConditionOperator,
	DeliveryCountryInput,
	DeliveryLegacyModeBlockedReason,
	DeliveryLocationGroupZoneInput,
	DeliveryMethodDefinitionInput,
	DeliveryMethodDefinitionType,
	DeliveryMethodType,
	DeliveryParticipantInput,
	DeliveryParticipantServiceInput,
	DeliveryPriceConditionInput,
	DeliveryProfileInput,
	DeliveryProfileLocationGroupInput,
	DeliveryProvinceInput,
	DeliveryRateDefinitionInput,
	DeliverySettingInput,
	DeliveryUpdateConditionInput,
	DeliveryWeightConditionInput,
	DigitalWallet,
	DiscountAmountInput,
	DiscountApplicationAllocationMethod,
	DiscountApplicationLevel,
	DiscountApplicationTargetSelection,
	DiscountApplicationTargetType,
	DiscountAutomaticAppInput,
	DiscountAutomaticBasicInput,
	DiscountAutomaticBxgyInput,
	DiscountClass,
	DiscountCodeAppInput,
	DiscountCodeBasicInput,
	DiscountCodeBxgyInput,
	DiscountCodeFreeShippingInput,
	DiscountCodeSortKeys,
	DiscountCollectionsInput,
	DiscountCombinesWithInput,
	DiscountCountriesInput,
	DiscountCustomerBuysInput,
	DiscountCustomerBuysValueInput,
	DiscountCustomerGetsInput,
	DiscountCustomerGetsValueInput,
	DiscountCustomerSegmentsInput,
	DiscountCustomerSelectionInput,
	DiscountCustomersInput,
	DiscountEffectInput,
	DiscountErrorCode,
	DiscountItemsInput,
	DiscountMinimumQuantityInput,
	DiscountMinimumRequirementInput,
	DiscountMinimumSubtotalInput,
	DiscountOnQuantityInput,
	DiscountProductsInput,
	DiscountRedeemCodeInput,
	DiscountShareableUrlTargetType,
	DiscountShippingDestinationSelectionInput,
	DiscountSortKeys,
	DiscountStatus,
	DiscountTargetType,
	DiscountType,
	DisputeEvidenceUpdateUserErrorCode,
	DisputeStatus,
	DisputeType,
	DraftOrderAppliedDiscountInput,
	DraftOrderAppliedDiscountType,
	DraftOrderDeleteInput,
	DraftOrderInput,
	DraftOrderLineItemInput,
	DraftOrderSortKeys,
	DraftOrderStatus,
	EmailInput,
	ErrorsWebPixelUserErrorCode,
	EventBridgeWebhookSubscriptionInput,
	EventSortKeys,
	FileContentType,
	FileCreateInput,
	FileErrorCode,
	FileSortKeys,
	FileStatus,
	FileUpdateInput,
	FilesErrorCode,
	FulfillmentDisplayStatus,
	FulfillmentEventSortKeys,
	FulfillmentEventStatus,
	FulfillmentHoldReason,
	FulfillmentOrderAction,
	FulfillmentOrderAssignmentStatus,
	FulfillmentOrderHoldInput,
	FulfillmentOrderHoldUserErrorCode,
	FulfillmentOrderLineItemInput,
	FulfillmentOrderLineItemsInput,
	FulfillmentOrderMerchantRequestKind,
	FulfillmentOrderRejectionReason,
	FulfillmentOrderReleaseHoldUserErrorCode,
	FulfillmentOrderRequestStatus,
	FulfillmentOrderRescheduleUserErrorCode,
	FulfillmentOrderSortKeys,
	FulfillmentOrderStatus,
	FulfillmentOrdersSetFulfillmentDeadlineUserErrorCode,
	FulfillmentOriginAddressInput,
	FulfillmentServiceType,
	FulfillmentStatus,
	FulfillmentTrackingInput,
	FulfillmentV2Input,
	GiftCardCreateInput,
	GiftCardErrorCode,
	GiftCardSortKeys,
	GiftCardUpdateInput,
	ImageContentType,
	ImageInput,
	ImageTransformInput,
	IncomingRequestLineItemInput,
	InventoryAdjustItemInput,
	InventoryAdjustQuantityInput,
	InventoryBulkToggleActivationInput,
	InventoryBulkToggleActivationUserErrorCode,
	InventoryItemInput,
	InventoryItemUpdateInput,
	InventoryLevelInput,
	LocalizationExtensionInput,
	LocalizationExtensionKey,
	LocalizationExtensionPurpose,
	LocationActivateUserErrorCode,
	LocationAddAddressInput,
	LocationAddInput,
	LocationAddUserErrorCode,
	LocationDeactivateUserErrorCode,
	LocationDeleteUserErrorCode,
	LocationEditAddressInput,
	LocationEditInput,
	LocationEditUserErrorCode,
	LocationSortKeys,
	MailingAddressInput,
	MarketCreateInput,
	MarketCurrencySettingsUpdateInput,
	MarketCurrencySettingsUserErrorCode,
	MarketLocalizableResourceType,
	MarketLocalizationRegisterInput,
	MarketRegionCreateInput,
	MarketUpdateInput,
	MarketUserErrorCode,
	MarketWebPresenceCreateInput,
	MarketWebPresenceUpdateInput,
	MarketingActivityBudgetInput,
	MarketingActivityCreateExternalInput,
	MarketingActivityCreateInput,
	MarketingActivityExtensionAppErrorCode,
	MarketingActivitySortKeys,
	MarketingActivityStatus,
	MarketingActivityStatusBadgeType,
	MarketingActivityUpdateExternalInput,
	MarketingActivityUpdateInput,
	MarketingActivityUserErrorCode,
	MarketingBudgetBudgetType,
	MarketingChannel,
	MarketingEngagementInput,
	MarketingEventSortKeys,
	MarketingTactic,
	MediaContentType,
	MediaErrorCode,
	MediaHost,
	MediaPreviewImageStatus,
	MediaStatus,
	MediaUserErrorCode,
	MediaWarningCode,
	MerchandiseDiscountClass,
	MetafieldDefinitionCreateUserErrorCode,
	MetafieldDefinitionDeleteUserErrorCode,
	MetafieldDefinitionInput,
	MetafieldDefinitionPinUserErrorCode,
	MetafieldDefinitionPinnedStatus,
	MetafieldDefinitionSortKeys,
	MetafieldDefinitionUnpinUserErrorCode,
	MetafieldDefinitionUpdateInput,
	MetafieldDefinitionUpdateUserErrorCode,
	MetafieldDefinitionValidationInput,
	MetafieldDefinitionValidationStatus,
	MetafieldDeleteInput,
	MetafieldInput,
	MetafieldOwnerType,
	MetafieldStorefrontVisibilityInput,
	MetafieldValidationStatus,
	MetafieldValueType,
	MetafieldsSetInput,
	MetafieldsSetUserErrorCode,
	MethodDefinitionSortKeys,
	MoneyInput,
	MoveInput,
	OrderActionType,
	OrderCancelReason,
	OrderCaptureInput,
	OrderCloseInput,
	OrderCreateMandatePaymentUserErrorCode,
	OrderDisplayFinancialStatus,
	OrderDisplayFulfillmentStatus,
	OrderEditAppliedDiscountInput,
	OrderInput,
	OrderInvoiceSendUserErrorCode,
	OrderMarkAsPaidInput,
	OrderOpenInput,
	OrderPaymentStatusResult,
	OrderRiskLevel,
	OrderSortKeys,
	OrderTransactionErrorCode,
	OrderTransactionInput,
	OrderTransactionKind,
	OrderTransactionStatus,
	PaymentMethods,
	PaymentScheduleInput,
	PaymentTermsCreateInput,
	PaymentTermsCreateUserErrorCode,
	PaymentTermsDeleteInput,
	PaymentTermsDeleteUserErrorCode,
	PaymentTermsInput,
	PaymentTermsType,
	PaymentTermsUpdateInput,
	PaymentTermsUpdateUserErrorCode,
	PaypalExpressSubscriptionsGatewayStatus,
	PriceListAdjustmentInput,
	PriceListAdjustmentType,
	PriceListContext,
	PriceListContextRuleInput,
	PriceListCreateInput,
	PriceListParentCreateInput,
	PriceListParentUpdateInput,
	PriceListPriceInput,
	PriceListPriceOriginType,
	PriceListPriceUserErrorCode,
	PriceListSortKeys,
	PriceListUpdateInput,
	PriceListUserErrorCode,
	PriceRuleAllocationMethod,
	PriceRuleCustomerSelectionInput,
	PriceRuleDiscountCodeInput,
	PriceRuleEntitlementToPrerequisiteQuantityRatioInput,
	PriceRuleErrorCode,
	PriceRuleFeature,
	PriceRuleInput,
	PriceRuleItemEntitlementsInput,
	PriceRuleItemPrerequisitesInput,
	PriceRuleMoneyRangeInput,
	PriceRulePrerequisiteToEntitlementQuantityRatioInput,
	PriceRuleQuantityRangeInput,
	PriceRuleShareableUrlTargetType,
	PriceRuleShippingEntitlementsInput,
	PriceRuleSortKeys,
	PriceRuleStatus,
	PriceRuleTarget,
	PriceRuleTrait,
	PriceRuleValidityPeriodInput,
	PriceRuleValueInput,
	PrivateMetafieldDeleteInput,
	PrivateMetafieldInput,
	PrivateMetafieldValueInput,
	PrivateMetafieldValueType,
	ProductAppendImagesInput,
	ProductCategoryInput,
	ProductChangeStatusUserErrorCode,
	ProductCollectionSortKeys,
	ProductDeleteInput,
	ProductImageSortKeys,
	ProductInput,
	ProductMediaSortKeys,
	ProductPublicationInput,
	ProductPublishInput,
	ProductResourceFeedbackInput,
	ProductSortKeys,
	ProductStatus,
	ProductUnpublishInput,
	ProductVariantAppendMediaInput,
	ProductVariantDetachMediaInput,
	ProductVariantInput,
	ProductVariantInventoryManagement,
	ProductVariantInventoryPolicy,
	ProductVariantPositionInput,
	ProductVariantSortKeys,
	ProductVariantsBulkCreateUserErrorCode,
	ProductVariantsBulkDeleteUserErrorCode,
	ProductVariantsBulkInput,
	ProductVariantsBulkReorderUserErrorCode,
	ProductVariantsBulkUpdateUserErrorCode,
	ProfileItemSortKeys,
	PubSubWebhookSubscriptionCreateUserErrorCode,
	PubSubWebhookSubscriptionInput,
	PubSubWebhookSubscriptionUpdateUserErrorCode,
	PublicationInput,
	PurchasingCompanyInput,
	PurchasingEntityInput,
	RefundDutyInput,
	RefundDutyRefundType,
	RefundInput,
	RefundLineItemInput,
	RefundLineItemRestockType,
	RemoteAuthorizeNetCustomerPaymentProfileInput,
	RemoteBraintreePaymentMethodInput,
	RemoteStripePaymentMethodInput,
	ResourceAlertIcon,
	ResourceAlertSeverity,
	ResourceFeedbackState,
	SaleActionType,
	SaleLineType,
	SavedSearchCreateInput,
	SavedSearchDeleteInput,
	SavedSearchUpdateInput,
	ScriptTagDisplayScope,
	ScriptTagInput,
	SearchResultType,
	SegmentSortKeys,
	SellingPlanAnchorInput,
	SellingPlanAnchorType,
	SellingPlanBillingPolicyInput,
	SellingPlanCategory,
	SellingPlanCheckoutChargeInput,
	SellingPlanCheckoutChargeType,
	SellingPlanCheckoutChargeValueInput,
	SellingPlanDeliveryPolicyInput,
	SellingPlanFixedBillingPolicyInput,
	SellingPlanFixedDeliveryPolicyInput,
	SellingPlanFixedDeliveryPolicyIntent,
	SellingPlanFixedDeliveryPolicyPreAnchorBehavior,
	SellingPlanFixedPricingPolicyInput,
	SellingPlanFulfillmentTrigger,
	SellingPlanGroupInput,
	SellingPlanGroupResourceInput,
	SellingPlanGroupSortKeys,
	SellingPlanGroupUserErrorCode,
	SellingPlanInput,
	SellingPlanInterval,
	SellingPlanInventoryPolicyInput,
	SellingPlanPricingPolicyAdjustmentType,
	SellingPlanPricingPolicyInput,
	SellingPlanPricingPolicyValueInput,
	SellingPlanRecurringBillingPolicyInput,
	SellingPlanRecurringDeliveryPolicyInput,
	SellingPlanRecurringDeliveryPolicyIntent,
	SellingPlanRecurringDeliveryPolicyPreAnchorBehavior,
	SellingPlanRecurringPricingPolicyInput,
	SellingPlanRemainingBalanceChargeTrigger,
	SellingPlanReserve,
	SeoInput,
	ShippingDiscountClass,
	ShippingLineInput,
	ShippingRefundInput,
	ShopBranding,
	ShopCustomerAccountsSetting,
	ShopLocaleInput,
	ShopPolicyErrorCode,
	ShopPolicyInput,
	ShopPolicyType,
	ShopTagSort,
	ShopifyPaymentsBankAccountStatus,
	ShopifyPaymentsDisputeEvidenceFileType,
	ShopifyPaymentsDisputeEvidenceUpdateInput,
	ShopifyPaymentsDisputeFileUploadUpdateInput,
	ShopifyPaymentsDisputeReason,
	ShopifyPaymentsPayoutInterval,
	ShopifyPaymentsPayoutStatus,
	ShopifyPaymentsPayoutTransactionType,
	ShopifyPaymentsVerificationDocumentType,
	ShopifyPaymentsVerificationStatus,
	StaffMemberDefaultImage,
	StaffMemberPermission,
	StageImageInput,
	StagedUploadHttpMethodType,
	StagedUploadInput,
	StagedUploadTargetGenerateInput,
	StagedUploadTargetGenerateUploadResource,
	StandardMetafieldDefinitionEnableUserErrorCode,
	StandardizedProductTypeInput,
	StorefrontAccessTokenDeleteInput,
	StorefrontAccessTokenInput,
	SubscriptionBillingAttemptErrorCode,
	SubscriptionBillingAttemptInput,
	SubscriptionBillingCycleBillingCycleStatus,
	SubscriptionBillingCycleErrorCode,
	SubscriptionBillingCycleInput,
	SubscriptionBillingCycleScheduleEditInput,
	SubscriptionBillingCycleScheduleEditInputScheduleEditReason,
	SubscriptionBillingCycleSelector,
	SubscriptionBillingCyclesDateRangeSelector,
	SubscriptionBillingCyclesIndexRangeSelector,
	SubscriptionBillingCyclesSortKeys,
	SubscriptionBillingCyclesTargetSelection,
	SubscriptionBillingPolicyInput,
	SubscriptionContractCreateInput,
	SubscriptionContractErrorCode,
	SubscriptionContractLastPaymentStatus,
	SubscriptionContractSubscriptionStatus,
	SubscriptionDeliveryMethodInput,
	SubscriptionDeliveryMethodLocalDeliveryInput,
	SubscriptionDeliveryMethodLocalDeliveryOptionInput,
	SubscriptionDeliveryMethodPickupInput,
	SubscriptionDeliveryMethodPickupOptionInput,
	SubscriptionDeliveryMethodShippingInput,
	SubscriptionDeliveryMethodShippingOptionInput,
	SubscriptionDeliveryPolicyInput,
	SubscriptionDiscountRejectionReason,
	SubscriptionDraftErrorCode,
	SubscriptionDraftInput,
	SubscriptionFreeShippingDiscountInput,
	SubscriptionLineInput,
	SubscriptionLineUpdateInput,
	SubscriptionManualDiscountEntitledLinesInput,
	SubscriptionManualDiscountFixedAmountInput,
	SubscriptionManualDiscountInput,
	SubscriptionManualDiscountLinesInput,
	SubscriptionManualDiscountValueInput,
	SubscriptionPricingPolicyCycleDiscountsInput,
	SubscriptionPricingPolicyInput,
	SuggestedOrderTransactionKind,
	TaxExemption,
	TranslatableResourceType,
	TranslationErrorCode,
	TranslationInput,
	UnitSystem,
	UpdateMediaInput,
	UrlRedirectBulkDeleteByIdsUserErrorCode,
	UrlRedirectBulkDeleteBySavedSearchUserErrorCode,
	UrlRedirectBulkDeleteBySearchUserErrorCode,
	UrlRedirectErrorCode,
	UrlRedirectImportErrorCode,
	UrlRedirectInput,
	UrlRedirectSortKeys,
	UtmInput,
	WebPixelInput,
	WebhookSubscriptionFormat,
	WebhookSubscriptionInput,
	WebhookSubscriptionSortKeys,
	WebhookSubscriptionTopic,
	WeightInput,
	WeightUnit,
} from '../types/graphql.types.ts';

type Properties<T> = Required<{
	[K in keyof T]: z.ZodType<T[K], any, T[K]>;
}>;

type definedNonNullAny = {};

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny => v !== undefined && v !== null;

export const definedNonNullAnySchema = z.any().refine((v) => isDefinedNonNullAny(v));

export const AppDeveloperTypeSchema = z.enum(['MERCHANT', 'PARTNER', 'SHOPIFY', 'UNKNOWN']);

export const AppInstallationCategorySchema = z.enum(['CHANNEL', 'POS_EMBEDDED']);

export const AppInstallationPrivacySchema = z.enum(['PRIVATE', 'PUBLIC']);

export const AppInstallationSortKeysSchema = z.enum(['APP_TITLE', 'ID', 'INSTALLED_AT', 'RELEVANCE']);

export const AppPricingIntervalSchema = z.enum(['ANNUAL', 'EVERY_30_DAYS']);

export const AppPublicCategorySchema = z.enum(['CUSTOM', 'OTHER', 'PRIVATE', 'PUBLIC']);

export const AppPurchaseStatusSchema = z.enum(['ACCEPTED', 'ACTIVE', 'DECLINED', 'EXPIRED', 'PENDING']);

export const AppRevenueAttributionRecordCreateUserErrorCodeSchema = z.enum(['INVALID', 'TAKEN']);

export const AppRevenueAttributionRecordDeleteUserErrorCodeSchema = z.enum(['INVALID']);

export const AppRevenueAttributionRecordSortKeysSchema = z.enum(['CREATED_AT', 'ID', 'RELEVANCE']);

export const AppRevenueAttributionTypeSchema = z.enum([
	'APPLICATION_PURCHASE',
	'APPLICATION_SUBSCRIPTION',
	'APPLICATION_USAGE',
	'OTHER',
]);

export const AppSubscriptionReplacementBehaviorSchema = z.enum([
	'APPLY_IMMEDIATELY',
	'APPLY_ON_NEXT_BILLING_CYCLE',
	'STANDARD',
]);

export const AppSubscriptionSortKeysSchema = z.enum(['CREATED_AT', 'ID', 'RELEVANCE']);

export const AppSubscriptionStatusSchema = z.enum([
	'ACCEPTED',
	'ACTIVE',
	'CANCELLED',
	'DECLINED',
	'EXPIRED',
	'FROZEN',
	'PENDING',
]);

export const AppSubscriptionTrialExtendUserErrorCodeSchema = z.enum([
	'SUBSCRIPTION_NOT_ACTIVE',
	'SUBSCRIPTION_NOT_FOUND',
	'TRIAL_NOT_ACTIVE',
]);

export const AppTransactionSortKeysSchema = z.enum(['CREATED_AT', 'ID', 'RELEVANCE']);

export const AppUsageRecordSortKeysSchema = z.enum(['CREATED_AT', 'ID', 'RELEVANCE']);

export const AutomaticDiscountSortKeysSchema = z.enum(['CREATED_AT', 'ID', 'RELEVANCE']);

export const BadgeTypeSchema = z.enum(['ATTENTION', 'DEFAULT', 'INFO', 'SUCCESS', 'WARNING']);

export const BillingAttemptUserErrorCodeSchema = z.enum(['BLANK', 'CONTRACT_NOT_FOUND', 'INVALID']);

export const BulkMutationErrorCodeSchema = z.enum([
	'INTERNAL_FILE_SERVER_ERROR',
	'INVALID_MUTATION',
	'INVALID_STAGED_UPLOAD_FILE',
	'NO_SUCH_FILE',
	'OPERATION_IN_PROGRESS',
]);

export const BulkOperationErrorCodeSchema = z.enum(['ACCESS_DENIED', 'INTERNAL_SERVER_ERROR', 'TIMEOUT']);

export const BulkOperationStatusSchema = z.enum([
	'CANCELED',
	'CANCELING',
	'COMPLETED',
	'CREATED',
	'EXPIRED',
	'FAILED',
	'RUNNING',
]);

export const BulkOperationTypeSchema = z.enum(['MUTATION', 'QUERY']);

export const BulkProductResourceFeedbackCreateUserErrorCodeSchema = z.enum([
	'BLANK',
	'INVALID',
	'LESS_THAN_OR_EQUAL_TO',
	'MAXIMUM_FEEDBACK_LIMIT_EXCEEDED',
	'OUTDATED_FEEDBACK',
	'PRESENT',
	'PRODUCT_NOT_FOUND',
]);

export const BusinessCustomerErrorCodeSchema = z.enum([
	'BLANK',
	'FAILED_TO_DELETE',
	'INTERNAL_ERROR',
	'INVALID',
	'INVALID_INPUT',
	'LIMIT_REACHED',
	'NO_INPUT',
	'REQUIRED',
	'RESOURCE_NOT_FOUND',
	'TAKEN',
	'TOO_LONG',
	'UNEXPECTED_TYPE',
]);

export const CheckoutProfileSortKeysSchema = z.enum(['CREATED_AT', 'ID', 'IS_PUBLISHED', 'RELEVANCE', 'UPDATED_AT']);

export const CodeDiscountSortKeysSchema = z.enum([
	'CREATED_AT',
	'ENDS_AT',
	'ID',
	'RELEVANCE',
	'STARTS_AT',
	'TITLE',
	'UPDATED_AT',
]);

export const CollectionAddProductsV2UserErrorCodeSchema = z.enum([
	'CANT_ADD_TO_SMART_COLLECTION',
	'COLLECTION_DOES_NOT_EXIST',
]);

export const CollectionRuleColumnSchema = z.enum([
	'IS_PRICE_REDUCED',
	'PRODUCT_TAXONOMY_NODE_ID',
	'TAG',
	'TITLE',
	'TYPE',
	'VARIANT_COMPARE_AT_PRICE',
	'VARIANT_INVENTORY',
	'VARIANT_PRICE',
	'VARIANT_TITLE',
	'VARIANT_WEIGHT',
	'VENDOR',
]);

export const CollectionRuleRelationSchema = z.enum([
	'CONTAINS',
	'ENDS_WITH',
	'EQUALS',
	'GREATER_THAN',
	'IS_NOT_SET',
	'IS_SET',
	'LESS_THAN',
	'NOT_CONTAINS',
	'NOT_EQUALS',
	'STARTS_WITH',
]);

export const CollectionSortKeysSchema = z.enum(['ID', 'RELEVANCE', 'TITLE', 'UPDATED_AT']);

export const CollectionSortOrderSchema = z.enum([
	'ALPHA_ASC',
	'ALPHA_DESC',
	'BEST_SELLING',
	'CREATED',
	'CREATED_DESC',
	'MANUAL',
	'PRICE_ASC',
	'PRICE_DESC',
]);

export const CompanyAddressTypeSchema = z.enum(['BILLING', 'SHIPPING']);

export const CompanyContactRoleAssignmentSortKeysSchema = z.enum([
	'CREATED_AT',
	'ID',
	'LOCATION_NAME',
	'RELEVANCE',
	'UPDATED_AT',
]);

export const CompanyContactRoleSortKeysSchema = z.enum(['CREATED_AT', 'ID', 'RELEVANCE', 'UPDATED_AT']);

export const CompanyContactSortKeysSchema = z.enum([
	'COMPANY_ID',
	'CREATED_AT',
	'EMAIL',
	'ID',
	'NAME',
	'NAME_EMAIL',
	'RELEVANCE',
	'TITLE',
	'UPDATED_AT',
]);

export const CompanyLocationSortKeysSchema = z.enum([
	'COMPANY_AND_LOCATION_NAME',
	'COMPANY_ID',
	'CREATED_AT',
	'ID',
	'NAME',
	'RELEVANCE',
	'UPDATED_AT',
]);

export const CompanySortKeysSchema = z.enum([
	'CREATED_AT',
	'ID',
	'NAME',
	'ORDER_COUNT',
	'RELEVANCE',
	'SINCE_DATE',
	'TOTAL_SPENT',
	'UPDATED_AT',
]);

export const CountryCodeSchema = z.enum([
	'AC',
	'AD',
	'AE',
	'AF',
	'AG',
	'AI',
	'AL',
	'AM',
	'AN',
	'AO',
	'AR',
	'AT',
	'AU',
	'AW',
	'AX',
	'AZ',
	'BA',
	'BB',
	'BD',
	'BE',
	'BF',
	'BG',
	'BH',
	'BI',
	'BJ',
	'BL',
	'BM',
	'BN',
	'BO',
	'BQ',
	'BR',
	'BS',
	'BT',
	'BV',
	'BW',
	'BY',
	'BZ',
	'CA',
	'CC',
	'CD',
	'CF',
	'CG',
	'CH',
	'CI',
	'CK',
	'CL',
	'CM',
	'CN',
	'CO',
	'CR',
	'CU',
	'CV',
	'CW',
	'CX',
	'CY',
	'CZ',
	'DE',
	'DJ',
	'DK',
	'DM',
	'DO',
	'DZ',
	'EC',
	'EE',
	'EG',
	'EH',
	'ER',
	'ES',
	'ET',
	'FI',
	'FJ',
	'FK',
	'FO',
	'FR',
	'GA',
	'GB',
	'GD',
	'GE',
	'GF',
	'GG',
	'GH',
	'GI',
	'GL',
	'GM',
	'GN',
	'GP',
	'GQ',
	'GR',
	'GS',
	'GT',
	'GW',
	'GY',
	'HK',
	'HM',
	'HN',
	'HR',
	'HT',
	'HU',
	'ID',
	'IE',
	'IL',
	'IM',
	'IN',
	'IO',
	'IQ',
	'IR',
	'IS',
	'IT',
	'JE',
	'JM',
	'JO',
	'JP',
	'KE',
	'KG',
	'KH',
	'KI',
	'KM',
	'KN',
	'KP',
	'KR',
	'KW',
	'KY',
	'KZ',
	'LA',
	'LB',
	'LC',
	'LI',
	'LK',
	'LR',
	'LS',
	'LT',
	'LU',
	'LV',
	'LY',
	'MA',
	'MC',
	'MD',
	'ME',
	'MF',
	'MG',
	'MK',
	'ML',
	'MM',
	'MN',
	'MO',
	'MQ',
	'MR',
	'MS',
	'MT',
	'MU',
	'MV',
	'MW',
	'MX',
	'MY',
	'MZ',
	'NA',
	'NC',
	'NE',
	'NF',
	'NG',
	'NI',
	'NL',
	'NO',
	'NP',
	'NR',
	'NU',
	'NZ',
	'OM',
	'PA',
	'PE',
	'PF',
	'PG',
	'PH',
	'PK',
	'PL',
	'PM',
	'PN',
	'PS',
	'PT',
	'PY',
	'QA',
	'RE',
	'RO',
	'RS',
	'RU',
	'RW',
	'SA',
	'SB',
	'SC',
	'SD',
	'SE',
	'SG',
	'SH',
	'SI',
	'SJ',
	'SK',
	'SL',
	'SM',
	'SN',
	'SO',
	'SR',
	'SS',
	'ST',
	'SV',
	'SX',
	'SY',
	'SZ',
	'TA',
	'TC',
	'TD',
	'TF',
	'TG',
	'TH',
	'TJ',
	'TK',
	'TL',
	'TM',
	'TN',
	'TO',
	'TR',
	'TT',
	'TV',
	'TW',
	'TZ',
	'UA',
	'UG',
	'UM',
	'US',
	'UY',
	'UZ',
	'VA',
	'VC',
	'VE',
	'VG',
	'VN',
	'VU',
	'WF',
	'WS',
	'XK',
	'YE',
	'YT',
	'ZA',
	'ZM',
	'ZW',
	'ZZ',
]);

export const CropRegionSchema = z.enum(['BOTTOM', 'CENTER', 'LEFT', 'RIGHT', 'TOP']);

export const CurrencyCodeSchema = z.enum([
	'AED',
	'AFN',
	'ALL',
	'AMD',
	'ANG',
	'AOA',
	'ARS',
	'AUD',
	'AWG',
	'AZN',
	'BAM',
	'BBD',
	'BDT',
	'BGN',
	'BHD',
	'BIF',
	'BMD',
	'BND',
	'BOB',
	'BRL',
	'BSD',
	'BTN',
	'BWP',
	'BYN',
	'BYR',
	'BZD',
	'CAD',
	'CDF',
	'CHF',
	'CLP',
	'CNY',
	'COP',
	'CRC',
	'CVE',
	'CZK',
	'DJF',
	'DKK',
	'DOP',
	'DZD',
	'EGP',
	'ERN',
	'ETB',
	'EUR',
	'FJD',
	'FKP',
	'GBP',
	'GEL',
	'GHS',
	'GIP',
	'GMD',
	'GNF',
	'GTQ',
	'GYD',
	'HKD',
	'HNL',
	'HRK',
	'HTG',
	'HUF',
	'IDR',
	'ILS',
	'INR',
	'IQD',
	'IRR',
	'ISK',
	'JEP',
	'JMD',
	'JOD',
	'JPY',
	'KES',
	'KGS',
	'KHR',
	'KID',
	'KMF',
	'KRW',
	'KWD',
	'KYD',
	'KZT',
	'LAK',
	'LBP',
	'LKR',
	'LRD',
	'LSL',
	'LTL',
	'LVL',
	'LYD',
	'MAD',
	'MDL',
	'MGA',
	'MKD',
	'MMK',
	'MNT',
	'MOP',
	'MRU',
	'MUR',
	'MVR',
	'MWK',
	'MXN',
	'MYR',
	'MZN',
	'NAD',
	'NGN',
	'NIO',
	'NOK',
	'NPR',
	'NZD',
	'OMR',
	'PAB',
	'PEN',
	'PGK',
	'PHP',
	'PKR',
	'PLN',
	'PYG',
	'QAR',
	'RON',
	'RSD',
	'RUB',
	'RWF',
	'SAR',
	'SBD',
	'SCR',
	'SDG',
	'SEK',
	'SGD',
	'SHP',
	'SLL',
	'SOS',
	'SRD',
	'SSP',
	'STD',
	'STN',
	'SYP',
	'SZL',
	'THB',
	'TJS',
	'TMT',
	'TND',
	'TOP',
	'TRY',
	'TTD',
	'TWD',
	'TZS',
	'UAH',
	'UGX',
	'USD',
	'UYU',
	'UZS',
	'VED',
	'VEF',
	'VES',
	'VND',
	'VUV',
	'WST',
	'XAF',
	'XCD',
	'XOF',
	'XPF',
	'XXX',
	'YER',
	'ZAR',
	'ZMW',
]);

export const CustomerConsentCollectedFromSchema = z.enum(['OTHER', 'SHOPIFY']);

export const CustomerEmailAddressMarketingStateSchema = z.enum([
	'INVALID',
	'NOT_SUBSCRIBED',
	'PENDING',
	'SUBSCRIBED',
	'UNSUBSCRIBED',
]);

export const CustomerEmailAddressOpenTrackingLevelSchema = z.enum(['OPTED_IN', 'OPTED_OUT', 'UNKNOWN']);

export const CustomerEmailMarketingConsentUpdateUserErrorCodeSchema = z.enum([
	'INCLUSION',
	'INTERNAL_ERROR',
	'INVALID',
	'MISSING_ARGUMENT',
]);

export const CustomerEmailMarketingStateSchema = z.enum([
	'INVALID',
	'NOT_SUBSCRIBED',
	'PENDING',
	'REDACTED',
	'SUBSCRIBED',
	'UNSUBSCRIBED',
]);

export const CustomerMarketingOptInLevelSchema = z.enum(['CONFIRMED_OPT_IN', 'SINGLE_OPT_IN', 'UNKNOWN']);

export const CustomerPaymentMethodGetUpdateUrlUserErrorCodeSchema = z.enum([
	'CUSTOMER_DOES_NOT_EXIST',
	'INVALID_INSTRUMENT',
	'PAYMENT_METHOD_DOES_NOT_EXIST',
	'TOO_MANY_REQUESTS',
]);

export const CustomerPaymentMethodRemoteUserErrorCodeSchema = z.enum([
	'AUTHORIZE_NET_NOT_ENABLED_FOR_SUBSCRIPTIONS',
	'BRAINTREE_NOT_ENABLED_FOR_SUBSCRIPTIONS',
	'EXACTLY_ONE_REMOTE_REFERENCE_REQUIRED',
	'INVALID',
	'PRESENT',
	'TAKEN',
]);

export const CustomerPaymentMethodRevocationReasonSchema = z.enum([
	'AUTHORIZE_NET_GATEWAY_NOT_ENABLED',
	'AUTHORIZE_NET_RETURNED_NO_PAYMENT_METHOD',
	'BRAINTREE_API_AUTHENTICATION_ERROR',
	'BRAINTREE_GATEWAY_NOT_ENABLED',
	'BRAINTREE_PAYMENT_METHOD_NOT_CARD',
	'BRAINTREE_RETURNED_NO_PAYMENT_METHOD',
	'FAILED_TO_UPDATE_CREDIT_CARD',
	'MANUALLY_REVOKED',
	'MERGED',
	'STRIPE_API_AUTHENTICATION_ERROR',
	'STRIPE_API_INVALID_REQUEST_ERROR',
	'STRIPE_GATEWAY_NOT_ENABLED',
	'STRIPE_PAYMENT_METHOD_NOT_CARD',
	'STRIPE_RETURNED_NO_PAYMENT_METHOD',
]);

export const CustomerPaymentMethodUserErrorCodeSchema = z.enum(['INVALID', 'PRESENT', 'TAKEN']);

export const CustomerPredictedSpendTierSchema = z.enum(['HIGH', 'LOW', 'MEDIUM']);

export const CustomerProductSubscriberStatusSchema = z.enum([
	'ACTIVE',
	'CANCELLED',
	'EXPIRED',
	'FAILED',
	'NEVER_SUBSCRIBED',
	'PAUSED',
]);

export const CustomerSavedSearchSortKeysSchema = z.enum(['ID', 'NAME', 'RELEVANCE']);

export const CustomerSmsMarketingConsentErrorCodeSchema = z.enum([
	'INCLUSION',
	'INTERNAL_ERROR',
	'INVALID',
	'MISSING_ARGUMENT',
]);

export const CustomerSmsMarketingStateSchema = z.enum([
	'NOT_SUBSCRIBED',
	'PENDING',
	'REDACTED',
	'SUBSCRIBED',
	'UNSUBSCRIBED',
]);

export const CustomerSortKeysSchema = z.enum([
	'CREATED_AT',
	'ID',
	'LAST_ORDER_DATE',
	'LOCATION',
	'NAME',
	'ORDERS_COUNT',
	'RELEVANCE',
	'TOTAL_SPENT',
	'UPDATED_AT',
]);

export const CustomerStateSchema = z.enum(['DECLINED', 'DISABLED', 'ENABLED', 'INVITED']);

export const DayOfTheWeekSchema = z.enum([
	'FRIDAY',
	'MONDAY',
	'SATURDAY',
	'SUNDAY',
	'THURSDAY',
	'TUESDAY',
	'WEDNESDAY',
]);

export const DelegateAccessTokenCreateUserErrorCodeSchema = z.enum([
	'DELEGATE_ACCESS_TOKEN',
	'EMPTY_ACCESS_SCOPE',
	'EXPIRES_AFTER_PARENT',
	'NEGATIVE_EXPIRES_IN',
	'PERSISTENCE_FAILED',
	'REFRESH_TOKEN',
	'UNKNOWN_SCOPES',
]);

export const DeletionEventSortKeysSchema = z.enum(['CREATED_AT', 'ID', 'RELEVANCE']);

export const DeletionEventSubjectTypeSchema = z.enum(['COLLECTION', 'PRODUCT']);

export const DeliveryConditionFieldSchema = z.enum(['TOTAL_PRICE', 'TOTAL_WEIGHT']);

export const DeliveryConditionOperatorSchema = z.enum(['GREATER_THAN_OR_EQUAL_TO', 'LESS_THAN_OR_EQUAL_TO']);

export const DeliveryLegacyModeBlockedReasonSchema = z.enum([
	'MULTI_LOCATION_DISABLED',
	'NO_LOCATIONS_FULFILLING_ONLINE_ORDERS',
]);

export const DeliveryMethodDefinitionTypeSchema = z.enum(['MERCHANT', 'PARTICIPANT']);

export const DeliveryMethodTypeSchema = z.enum(['LOCAL', 'NONE', 'PICK_UP', 'RETAIL', 'SHIPPING']);

export const DigitalWalletSchema = z.enum(['ANDROID_PAY', 'APPLE_PAY', 'GOOGLE_PAY', 'SHOPIFY_PAY']);

export const DiscountApplicationAllocationMethodSchema = z.enum(['ACROSS', 'EACH', 'ONE']);

export const DiscountApplicationLevelSchema = z.enum(['LINE', 'ORDER']);

export const DiscountApplicationTargetSelectionSchema = z.enum(['ALL', 'ENTITLED', 'EXPLICIT']);

export const DiscountApplicationTargetTypeSchema = z.enum(['LINE_ITEM', 'SHIPPING_LINE']);

export const DiscountClassSchema = z.enum(['ORDER', 'PRODUCT', 'SHIPPING']);

export const DiscountCodeSortKeysSchema = z.enum(['CODE', 'CREATED_AT', 'ID', 'RELEVANCE']);

export const DiscountErrorCodeSchema = z.enum([
	'ACTIVE_PERIOD_OVERLAP',
	'BLANK',
	'CONFLICT',
	'DUPLICATE',
	'EQUAL_TO',
	'EXCEEDED_MAX',
	'GREATER_THAN',
	'GREATER_THAN_OR_EQUAL_TO',
	'IMPLICIT_DUPLICATE',
	'INCLUSION',
	'INTERNAL_ERROR',
	'INVALID',
	'INVALID_COMBINES_WITH_FOR_DISCOUNT_CLASS',
	'INVALID_DISCOUNT_CLASS_FOR_PRICE_RULE',
	'LESS_THAN',
	'LESS_THAN_OR_EQUAL_TO',
	'MAX_APP_DISCOUNTS',
	'MINIMUM_SUBTOTAL_AND_QUANTITY_RANGE_BOTH_PRESENT',
	'MISSING_ARGUMENT',
	'PRESENT',
	'TAKEN',
	'TOO_LONG',
	'TOO_MANY_ARGUMENTS',
	'TOO_SHORT',
	'VALUE_OUTSIDE_RANGE',
]);

export const DiscountShareableUrlTargetTypeSchema = z.enum(['COLLECTION', 'HOME', 'PRODUCT']);

export const DiscountSortKeysSchema = z.enum([
	'CREATED_AT',
	'ENDS_AT',
	'ID',
	'RELEVANCE',
	'STARTS_AT',
	'TITLE',
	'UPDATED_AT',
]);

export const DiscountStatusSchema = z.enum(['ACTIVE', 'EXPIRED', 'SCHEDULED']);

export const DiscountTargetTypeSchema = z.enum(['LINE_ITEM', 'SHIPPING_LINE']);

export const DiscountTypeSchema = z.enum(['CODE_DISCOUNT', 'MANUAL']);

export const DisputeEvidenceUpdateUserErrorCodeSchema = z.enum([
	'DISPUTE_EVIDENCE_NOT_FOUND',
	'EVIDENCE_ALREADY_ACCEPTED',
	'EVIDENCE_PAST_DUE_DATE',
	'FILES_SIZE_EXCEEDED_LIMIT',
	'INVALID',
	'TOO_LARGE',
]);

export const DisputeStatusSchema = z.enum([
	'ACCEPTED',
	'CHARGE_REFUNDED',
	'LOST',
	'NEEDS_RESPONSE',
	'UNDER_REVIEW',
	'WON',
]);

export const DisputeTypeSchema = z.enum(['CHARGEBACK', 'INQUIRY']);

export const DraftOrderAppliedDiscountTypeSchema = z.enum(['FIXED_AMOUNT', 'PERCENTAGE']);

export const DraftOrderSortKeysSchema = z.enum([
	'CUSTOMER_NAME',
	'ID',
	'NUMBER',
	'RELEVANCE',
	'STATUS',
	'TOTAL_PRICE',
	'UPDATED_AT',
]);

export const DraftOrderStatusSchema = z.enum(['COMPLETED', 'INVOICE_SENT', 'OPEN']);

export const ErrorsWebPixelUserErrorCodeSchema = z.enum([
	'BLANK',
	'INVALID_SETTINGS',
	'NOT_FOUND',
	'TAKEN',
	'UNABLE_TO_DELETE',
]);

export const EventSortKeysSchema = z.enum(['CREATED_AT', 'ID', 'RELEVANCE']);

export const FileContentTypeSchema = z.enum(['FILE', 'IMAGE', 'VIDEO']);

export const FileErrorCodeSchema = z.enum([
	'EXTERNAL_VIDEO_EMBED_DISABLED',
	'EXTERNAL_VIDEO_EMBED_NOT_FOUND_OR_TRANSCODING',
	'EXTERNAL_VIDEO_INVALID_ASPECT_RATIO',
	'EXTERNAL_VIDEO_NOT_FOUND',
	'EXTERNAL_VIDEO_UNLISTED',
	'FILE_STORAGE_LIMIT_EXCEEDED',
	'GENERIC_FILE_DOWNLOAD_FAILURE',
	'GENERIC_FILE_INVALID_SIZE',
	'IMAGE_DOWNLOAD_FAILURE',
	'IMAGE_PROCESSING_FAILURE',
	'INVALID_IMAGE_ASPECT_RATIO',
	'INVALID_IMAGE_FILE_SIZE',
	'INVALID_IMAGE_RESOLUTION',
	'INVALID_SIGNED_URL',
	'MEDIA_TIMEOUT_ERROR',
	'MODEL3D_GLB_OUTPUT_CREATION_ERROR',
	'MODEL3D_GLB_TO_USDZ_CONVERSION_ERROR',
	'MODEL3D_PROCESSING_FAILURE',
	'MODEL3D_THUMBNAIL_GENERATION_ERROR',
	'MODEL3D_VALIDATION_ERROR',
	'UNKNOWN',
	'UNSUPPORTED_IMAGE_FILE_TYPE',
	'VIDEO_INVALID_FILETYPE_ERROR',
	'VIDEO_MAX_DURATION_ERROR',
	'VIDEO_MAX_HEIGHT_ERROR',
	'VIDEO_MAX_WIDTH_ERROR',
	'VIDEO_METADATA_READ_ERROR',
	'VIDEO_MIN_DURATION_ERROR',
	'VIDEO_MIN_HEIGHT_ERROR',
	'VIDEO_MIN_WIDTH_ERROR',
	'VIDEO_VALIDATION_ERROR',
]);

export const FileSortKeysSchema = z.enum([
	'CREATED_AT',
	'FILENAME',
	'ID',
	'ORIGINAL_UPLOAD_SIZE',
	'RELEVANCE',
	'UPDATED_AT',
]);

export const FileStatusSchema = z.enum(['FAILED', 'PROCESSING', 'READY', 'UPLOADED']);

export const FilesErrorCodeSchema = z.enum([
	'ALT_VALUE_LIMIT_EXCEEDED',
	'BLANK_SEARCH',
	'FILE_DOES_NOT_EXIST',
	'FILE_LOCKED',
	'INVALID',
	'INVALID_QUERY',
	'MISSING_ARGUMENTS',
	'NON_IMAGE_MEDIA_PER_SHOP_LIMIT_EXCEEDED',
	'TOO_MANY_ARGUMENTS',
	'UNACCEPTABLE_ASSET',
	'UNACCEPTABLE_TRIAL_ASSET',
	'UNACCEPTABLE_UNVERIFIED_TRIAL_ASSET',
]);

export const FulfillmentDisplayStatusSchema = z.enum([
	'ATTEMPTED_DELIVERY',
	'CANCELED',
	'CONFIRMED',
	'DELIVERED',
	'FAILURE',
	'FULFILLED',
	'IN_TRANSIT',
	'LABEL_PRINTED',
	'LABEL_PURCHASED',
	'LABEL_VOIDED',
	'MARKED_AS_FULFILLED',
	'NOT_DELIVERED',
	'OUT_FOR_DELIVERY',
	'PICKED_UP',
	'READY_FOR_PICKUP',
	'SUBMITTED',
]);

export const FulfillmentEventSortKeysSchema = z.enum(['HAPPENED_AT', 'ID', 'RELEVANCE']);

export const FulfillmentEventStatusSchema = z.enum([
	'ATTEMPTED_DELIVERY',
	'CONFIRMED',
	'DELIVERED',
	'FAILURE',
	'IN_TRANSIT',
	'LABEL_PRINTED',
	'LABEL_PURCHASED',
	'OUT_FOR_DELIVERY',
	'READY_FOR_PICKUP',
]);

export const FulfillmentHoldReasonSchema = z.enum([
	'AWAITING_PAYMENT',
	'HIGH_RISK_OF_FRAUD',
	'INCORRECT_ADDRESS',
	'INVENTORY_OUT_OF_STOCK',
	'OTHER',
	'UNKNOWN_DELIVERY_DATE',
]);

export const FulfillmentOrderActionSchema = z.enum([
	'CANCEL_FULFILLMENT_ORDER',
	'CREATE_FULFILLMENT',
	'EXTERNAL',
	'HOLD',
	'MARK_AS_OPEN',
	'MOVE',
	'RELEASE_HOLD',
	'REQUEST_CANCELLATION',
	'REQUEST_FULFILLMENT',
]);

export const FulfillmentOrderAssignmentStatusSchema = z.enum([
	'CANCELLATION_REQUESTED',
	'FULFILLMENT_ACCEPTED',
	'FULFILLMENT_REQUESTED',
]);

export const FulfillmentOrderHoldUserErrorCodeSchema = z.enum(['FULFILLMENT_ORDER_NOT_FOUND', 'TAKEN']);

export const FulfillmentOrderMerchantRequestKindSchema = z.enum(['CANCELLATION_REQUEST', 'FULFILLMENT_REQUEST']);

export const FulfillmentOrderRejectionReasonSchema = z.enum([
	'INCORRECT_ADDRESS',
	'INELIGIBLE_PRODUCT',
	'INVENTORY_OUT_OF_STOCK',
	'OTHER',
	'UNDELIVERABLE_DESTINATION',
]);

export const FulfillmentOrderReleaseHoldUserErrorCodeSchema = z.enum(['FULFILLMENT_ORDER_NOT_FOUND']);

export const FulfillmentOrderRequestStatusSchema = z.enum([
	'ACCEPTED',
	'CANCELLATION_ACCEPTED',
	'CANCELLATION_REJECTED',
	'CANCELLATION_REQUESTED',
	'CLOSED',
	'REJECTED',
	'SUBMITTED',
	'UNSUBMITTED',
]);

export const FulfillmentOrderRescheduleUserErrorCodeSchema = z.enum(['FULFILLMENT_ORDER_NOT_FOUND']);

export const FulfillmentOrderSortKeysSchema = z.enum(['ID', 'RELEVANCE']);

export const FulfillmentOrderStatusSchema = z.enum([
	'CANCELLED',
	'CLOSED',
	'INCOMPLETE',
	'IN_PROGRESS',
	'ON_HOLD',
	'OPEN',
	'SCHEDULED',
]);

export const FulfillmentOrdersSetFulfillmentDeadlineUserErrorCodeSchema = z.enum(['FULFILLMENT_ORDERS_NOT_FOUND']);

export const FulfillmentServiceTypeSchema = z.enum(['GIFT_CARD', 'MANUAL', 'THIRD_PARTY']);

export const FulfillmentStatusSchema = z.enum(['CANCELLED', 'ERROR', 'FAILURE', 'OPEN', 'PENDING', 'SUCCESS']);

export const GiftCardErrorCodeSchema = z.enum([
	'GREATER_THAN',
	'INTERNAL_ERROR',
	'INVALID',
	'MISSING_ARGUMENT',
	'TAKEN',
	'TOO_LONG',
	'TOO_SHORT',
]);

export const GiftCardSortKeysSchema = z.enum([
	'AMOUNT_SPENT',
	'BALANCE',
	'CODE',
	'CREATED_AT',
	'CUSTOMER_NAME',
	'DISABLED_AT',
	'EXPIRES_ON',
	'ID',
	'INITIAL_VALUE',
	'RELEVANCE',
	'UPDATED_AT',
]);

export const ImageContentTypeSchema = z.enum(['JPG', 'PNG', 'WEBP']);

export const InventoryBulkToggleActivationUserErrorCodeSchema = z.enum(['GENERIC_ERROR']);

export const LocalizationExtensionKeySchema = z.enum([
	'SHIPPING_CREDENTIAL_BR',
	'SHIPPING_CREDENTIAL_CN',
	'SHIPPING_CREDENTIAL_KR',
	'TAX_CREDENTIAL_BR',
	'TAX_CREDENTIAL_IT',
	'TAX_EMAIL_IT',
]);

export const LocalizationExtensionPurposeSchema = z.enum(['SHIPPING', 'TAX']);

export const LocationActivateUserErrorCodeSchema = z.enum([
	'GENERIC_ERROR',
	'HAS_ONGOING_RELOCATION',
	'LOCATION_LIMIT',
	'LOCATION_NOT_FOUND',
]);

export const LocationAddUserErrorCodeSchema = z.enum([
	'BLANK',
	'GENERIC_ERROR',
	'INVALID',
	'INVALID_US_ZIPCODE',
	'TAKEN',
	'TOO_LONG',
]);

export const LocationDeactivateUserErrorCodeSchema = z.enum([
	'CANNOT_DISABLE_ONLINE_ORDER_FULFILLMENT',
	'DESTINATION_LOCATION_IS_THE_SAME_LOCATION',
	'DESTINATION_LOCATION_NOT_FOUND_OR_INACTIVE',
	'FAILED_TO_RELOCATE_ACTIVE_INVENTORIES',
	'FAILED_TO_RELOCATE_INCOMING_MOVEMENTS',
	'FAILED_TO_RELOCATE_OPEN_PURCHASE_ORDERS',
	'FAILED_TO_RELOCATE_OPEN_TRANSFERS',
	'HAS_ACTIVE_INVENTORY_ERROR',
	'HAS_ACTIVE_RETAIL_SUBSCRIPTIONS',
	'HAS_FULFILLMENT_ORDERS_ERROR',
	'HAS_INCOMING_MOVEMENTS_ERROR',
	'HAS_OPEN_PURCHASE_ORDERS_ERROR',
	'HAS_OPEN_TRANSFERS_ERROR',
	'INVALID',
	'LOCATION_NOT_FOUND',
	'PERMANENTLY_BLOCKED_FROM_DEACTIVATION_ERROR',
	'TEMPORARILY_BLOCKED_FROM_DEACTIVATION_ERROR',
]);

export const LocationDeleteUserErrorCodeSchema = z.enum([
	'GENERIC_ERROR',
	'LOCATION_HAS_ACTIVE_RETAIL_SUBSCRIPTION',
	'LOCATION_HAS_INVENTORY',
	'LOCATION_HAS_PENDING_ORDERS',
	'LOCATION_IS_ACTIVE',
	'LOCATION_NOT_FOUND',
]);

export const LocationEditUserErrorCodeSchema = z.enum([
	'BLANK',
	'CANNOT_DISABLE_ONLINE_ORDER_FULFILLMENT',
	'GENERIC_ERROR',
	'INVALID',
	'INVALID_US_ZIPCODE',
	'NOT_FOUND',
	'TOO_LONG',
]);

export const LocationSortKeysSchema = z.enum(['ID', 'NAME', 'RELEVANCE']);

export const MarketCurrencySettingsUserErrorCodeSchema = z.enum([
	'MANAGED_MARKET',
	'MARKET_NOT_FOUND',
	'MULTIPLE_CURRENCIES_NOT_SUPPORTED',
	'NO_LOCAL_CURRENCIES_ON_SINGLE_COUNTRY_MARKET',
	'PRIMARY_MARKET_USES_SHOP_CURRENCY',
	'UNSUPPORTED_CURRENCY',
]);

export const MarketLocalizableResourceTypeSchema = z.enum(['METAFIELD']);

export const MarketUserErrorCodeSchema = z.enum([
	'BLANK',
	'CANNOT_ADD_CUSTOMER_DOMAIN',
	'CANNOT_ADD_REGIONS_TO_PRIMARY_MARKET',
	'CANNOT_ADD_WEB_PRESENCE_TO_PRIMARY_MARKET',
	'CANNOT_DELETE_ONLY_REGION',
	'CANNOT_DELETE_PRIMARY_MARKET',
	'CANNOT_DELETE_PRIMARY_MARKET_WEB_PRESENCE',
	'CANNOT_DISABLE_PRIMARY_MARKET',
	'CANNOT_HAVE_SUBFOLDER_AND_DOMAIN',
	'CANNOT_SET_DEFAULT_LOCALE_TO_NULL',
	'DISABLED_LANGUAGE',
	'DOMAIN_NOT_FOUND',
	'DUPLICATE_LANGUAGES',
	'INVALID',
	'MARKET_NOT_FOUND',
	'NO_LANGUAGES',
	'PRIMARY_MARKET_MUST_USE_PRIMARY_DOMAIN',
	'REGION_NOT_FOUND',
	'REGION_SPECIFIC_LANGUAGE',
	'REQUIRES_DOMAIN_OR_SUBFOLDER',
	'REQUIRES_EXACTLY_ONE_OPTION',
	'SHOP_REACHED_MARKETS_LIMIT',
	'SUBFOLDER_NOT_ALLOWED_FOR_CCTLD_DOMAINS',
	'SUBFOLDER_SUFFIX_MUST_CONTAIN_ONLY_LETTERS',
	'TAKEN',
	'TOO_LONG',
	'TOO_SHORT',
	'UNPUBLISHED_LANGUAGE',
	'UNSUPPORTED_COUNTRY_REGION',
	'WEB_PRESENCE_NOT_FOUND',
]);

export const MarketingActivityExtensionAppErrorCodeSchema = z.enum([
	'API_ERROR',
	'INSTALL_REQUIRED_ERROR',
	'NOT_ONBOARDED_ERROR',
	'PLATFORM_ERROR',
	'VALIDATION_ERROR',
]);

export const MarketingActivitySortKeysSchema = z.enum(['CREATED_AT', 'ID', 'RELEVANCE', 'TITLE']);

export const MarketingActivityStatusSchema = z.enum([
	'ACTIVE',
	'DELETED',
	'DELETED_EXTERNALLY',
	'DISCONNECTED',
	'DRAFT',
	'FAILED',
	'INACTIVE',
	'PAUSED',
	'PENDING',
	'SCHEDULED',
	'UNDEFINED',
]);

export const MarketingActivityStatusBadgeTypeSchema = z.enum(['ATTENTION', 'DEFAULT', 'INFO', 'SUCCESS', 'WARNING']);

export const MarketingActivityUserErrorCodeSchema = z.enum(['INVALID', 'TAKEN']);

export const MarketingBudgetBudgetTypeSchema = z.enum(['DAILY', 'LIFETIME']);

export const MarketingChannelSchema = z.enum(['DISPLAY', 'EMAIL', 'REFERRAL', 'SEARCH', 'SOCIAL']);

export const MarketingEventSortKeysSchema = z.enum(['ID', 'RELEVANCE', 'STARTED_AT']);

export const MarketingTacticSchema = z.enum([
	'ABANDONED_CART',
	'AD',
	'AFFILIATE',
	'DIRECT',
	'DISPLAY',
	'FOLLOW_UP',
	'LINK',
	'LOYALTY',
	'MESSAGE',
	'NEWSLETTER',
	'NOTIFICATION',
	'POST',
	'RECEIPT',
	'RETARGETING',
	'SEARCH',
	'SEO',
	'STOREFRONT_APP',
	'TRANSACTIONAL',
]);

export const MediaContentTypeSchema = z.enum(['EXTERNAL_VIDEO', 'IMAGE', 'MODEL_3D', 'VIDEO']);

export const MediaErrorCodeSchema = z.enum([
	'EXTERNAL_VIDEO_EMBED_DISABLED',
	'EXTERNAL_VIDEO_EMBED_NOT_FOUND_OR_TRANSCODING',
	'EXTERNAL_VIDEO_INVALID_ASPECT_RATIO',
	'EXTERNAL_VIDEO_NOT_FOUND',
	'EXTERNAL_VIDEO_UNLISTED',
	'FILE_STORAGE_LIMIT_EXCEEDED',
	'GENERIC_FILE_DOWNLOAD_FAILURE',
	'GENERIC_FILE_INVALID_SIZE',
	'IMAGE_DOWNLOAD_FAILURE',
	'IMAGE_PROCESSING_FAILURE',
	'INVALID_IMAGE_ASPECT_RATIO',
	'INVALID_IMAGE_FILE_SIZE',
	'INVALID_IMAGE_RESOLUTION',
	'INVALID_SIGNED_URL',
	'MEDIA_TIMEOUT_ERROR',
	'MODEL3D_GLB_OUTPUT_CREATION_ERROR',
	'MODEL3D_GLB_TO_USDZ_CONVERSION_ERROR',
	'MODEL3D_PROCESSING_FAILURE',
	'MODEL3D_THUMBNAIL_GENERATION_ERROR',
	'MODEL3D_VALIDATION_ERROR',
	'UNKNOWN',
	'UNSUPPORTED_IMAGE_FILE_TYPE',
	'VIDEO_INVALID_FILETYPE_ERROR',
	'VIDEO_MAX_DURATION_ERROR',
	'VIDEO_MAX_HEIGHT_ERROR',
	'VIDEO_MAX_WIDTH_ERROR',
	'VIDEO_METADATA_READ_ERROR',
	'VIDEO_MIN_DURATION_ERROR',
	'VIDEO_MIN_HEIGHT_ERROR',
	'VIDEO_MIN_WIDTH_ERROR',
	'VIDEO_VALIDATION_ERROR',
]);

export const MediaHostSchema = z.enum(['VIMEO', 'YOUTUBE']);

export const MediaPreviewImageStatusSchema = z.enum(['FAILED', 'PROCESSING', 'READY', 'UPLOADED']);

export const MediaStatusSchema = z.enum(['FAILED', 'PROCESSING', 'READY', 'UPLOADED']);

export const MediaUserErrorCodeSchema = z.enum([
	'BLANK',
	'INVALID',
	'INVALID_MEDIA_TYPE',
	'MAXIMUM_VARIANT_MEDIA_PAIRS_EXCEEDED',
	'MEDIA_CANNOT_BE_MODIFIED',
	'MEDIA_DOES_NOT_EXIST',
	'MEDIA_DOES_NOT_EXIST_ON_PRODUCT',
	'MEDIA_IS_NOT_ATTACHED_TO_VARIANT',
	'MODEL3D_THROTTLE_EXCEEDED',
	'MODEL3D_VALIDATION_ERROR',
	'NON_READY_MEDIA',
	'PRODUCT_DOES_NOT_EXIST',
	'PRODUCT_MEDIA_LIMIT_EXCEEDED',
	'PRODUCT_VARIANT_ALREADY_HAS_MEDIA',
	'PRODUCT_VARIANT_DOES_NOT_EXIST_ON_PRODUCT',
	'PRODUCT_VARIANT_SPECIFIED_MULTIPLE_TIMES',
	'SHOP_MEDIA_LIMIT_EXCEEDED',
	'TOO_MANY_MEDIA_PER_INPUT_PAIR',
	'VIDEO_THROTTLE_EXCEEDED',
	'VIDEO_VALIDATION_ERROR',
]);

export const MediaWarningCodeSchema = z.enum(['MODEL_LARGE_PHYSICAL_SIZE', 'MODEL_SMALL_PHYSICAL_SIZE']);

export const MerchandiseDiscountClassSchema = z.enum(['ORDER', 'PRODUCT']);

export const MetafieldDefinitionCreateUserErrorCodeSchema = z.enum([
	'DUPLICATE_OPTION',
	'INCLUSION',
	'INVALID',
	'INVALID_CHARACTER',
	'INVALID_OPTION',
	'LIMIT_EXCEEDED',
	'PINNED_LIMIT_REACHED',
	'PRESENT',
	'RESERVED_NAMESPACE_KEY',
	'RESOURCE_TYPE_LIMIT_EXCEEDED',
	'TAKEN',
	'TOO_LONG',
	'TOO_SHORT',
	'UNSTRUCTURED_ALREADY_EXISTS',
]);

export const MetafieldDefinitionDeleteUserErrorCodeSchema = z.enum([
	'INTERNAL_ERROR',
	'NOT_FOUND',
	'PRESENT',
	'REFERENCE_TYPE_DELETION_ERROR',
]);

export const MetafieldDefinitionPinUserErrorCodeSchema = z.enum([
	'ALREADY_PINNED',
	'INTERNAL_ERROR',
	'NOT_FOUND',
	'PINNED_LIMIT_REACHED',
]);

export const MetafieldDefinitionPinnedStatusSchema = z.enum(['ANY', 'PINNED', 'UNPINNED']);

export const MetafieldDefinitionSortKeysSchema = z.enum(['ID', 'NAME', 'PINNED_POSITION', 'RELEVANCE']);

export const MetafieldDefinitionUnpinUserErrorCodeSchema = z.enum(['INTERNAL_ERROR', 'NOT_FOUND', 'NOT_PINNED']);

export const MetafieldDefinitionUpdateUserErrorCodeSchema = z.enum([
	'INTERNAL_ERROR',
	'INVALID_INPUT',
	'NOT_FOUND',
	'PINNED_LIMIT_REACHED',
	'PRESENT',
	'TOO_LONG',
]);

export const MetafieldDefinitionValidationStatusSchema = z.enum(['ALL_VALID', 'IN_PROGRESS', 'SOME_INVALID']);

export const MetafieldOwnerTypeSchema = z.enum([
	'API_PERMISSION',
	'ARTICLE',
	'BLOG',
	'COLLECTION',
	'CUSTOMER',
	'DISCOUNT',
	'DRAFTORDER',
	'LOCATION',
	'ORDER',
	'PAGE',
	'PRODUCT',
	'PRODUCTIMAGE',
	'PRODUCTVARIANT',
	'SHOP',
]);

export const MetafieldValidationStatusSchema = z.enum(['ANY', 'INVALID', 'VALID']);

export const MetafieldValueTypeSchema = z.enum(['BOOLEAN', 'INTEGER', 'JSON_STRING', 'STRING']);

export const MetafieldsSetUserErrorCodeSchema = z.enum([
	'APP_NOT_AUTHORIZED',
	'BLANK',
	'INCLUSION',
	'INVALID_TYPE',
	'INVALID_VALUE',
	'LESS_THAN_OR_EQUAL_TO',
	'PRESENT',
	'TOO_LONG',
	'TOO_SHORT',
]);

export const MethodDefinitionSortKeysSchema = z.enum(['ID', 'RATE_PROVIDER_TYPE', 'RELEVANCE']);

export const OrderActionTypeSchema = z.enum(['ORDER', 'ORDER_EDIT', 'REFUND', 'UNKNOWN']);

export const OrderCancelReasonSchema = z.enum(['CUSTOMER', 'DECLINED', 'FRAUD', 'INVENTORY', 'OTHER']);

export const OrderCreateMandatePaymentUserErrorCodeSchema = z.enum(['ORDER_MANDATE_PAYMENT_ERROR_CODE']);

export const OrderDisplayFinancialStatusSchema = z.enum([
	'AUTHORIZED',
	'EXPIRED',
	'PAID',
	'PARTIALLY_PAID',
	'PARTIALLY_REFUNDED',
	'PENDING',
	'REFUNDED',
	'VOIDED',
]);

export const OrderDisplayFulfillmentStatusSchema = z.enum([
	'FULFILLED',
	'IN_PROGRESS',
	'ON_HOLD',
	'OPEN',
	'PARTIALLY_FULFILLED',
	'PENDING_FULFILLMENT',
	'RESTOCKED',
	'SCHEDULED',
	'UNFULFILLED',
]);

export const OrderInvoiceSendUserErrorCodeSchema = z.enum(['ORDER_INVOICE_SEND_UNSUCCESSFUL']);

export const OrderPaymentStatusResultSchema = z.enum([
	'AUTHORIZED',
	'CAPTURED',
	'ERROR',
	'PROCESSING',
	'PURCHASED',
	'REDIRECT_REQUIRED',
	'REFUNDED',
	'RETRYABLE',
	'SUCCESS',
	'UNKNOWN',
	'VOIDED',
]);

export const OrderRiskLevelSchema = z.enum(['HIGH', 'LOW', 'MEDIUM']);

export const OrderSortKeysSchema = z.enum([
	'CREATED_AT',
	'CUSTOMER_NAME',
	'FINANCIAL_STATUS',
	'FULFILLMENT_STATUS',
	'ID',
	'ORDER_NUMBER',
	'PROCESSED_AT',
	'RELEVANCE',
	'TOTAL_PRICE',
	'UPDATED_AT',
]);

export const OrderTransactionErrorCodeSchema = z.enum([
	'AMAZON_PAYMENTS_INVALID_PAYMENT_METHOD',
	'AMAZON_PAYMENTS_MAX_AMOUNT_CHARGED',
	'AMAZON_PAYMENTS_MAX_AMOUNT_REFUNDED',
	'AMAZON_PAYMENTS_MAX_AUTHORIZATIONS_CAPTURED',
	'AMAZON_PAYMENTS_MAX_REFUNDS_PROCESSED',
	'AMAZON_PAYMENTS_ORDER_REFERENCE_CANCELED',
	'AMAZON_PAYMENTS_STALE',
	'CALL_ISSUER',
	'CARD_DECLINED',
	'CONFIG_ERROR',
	'EXPIRED_CARD',
	'GENERIC_ERROR',
	'INCORRECT_ADDRESS',
	'INCORRECT_CVC',
	'INCORRECT_NUMBER',
	'INCORRECT_PIN',
	'INCORRECT_ZIP',
	'INVALID_AMOUNT',
	'INVALID_COUNTRY',
	'INVALID_CVC',
	'INVALID_EXPIRY_DATE',
	'INVALID_NUMBER',
	'PAYMENT_METHOD_UNAVAILABLE',
	'PICK_UP_CARD',
	'PROCESSING_ERROR',
	'TEST_MODE_LIVE_CARD',
	'UNSUPPORTED_FEATURE',
]);

export const OrderTransactionKindSchema = z.enum([
	'AUTHORIZATION',
	'CAPTURE',
	'CHANGE',
	'EMV_AUTHORIZATION',
	'REFUND',
	'SALE',
	'SUGGESTED_REFUND',
	'VOID',
]);

export const OrderTransactionStatusSchema = z.enum([
	'AWAITING_RESPONSE',
	'ERROR',
	'FAILURE',
	'PENDING',
	'SUCCESS',
	'UNKNOWN',
]);

export const PaymentMethodsSchema = z.enum([
	'AMERICAN_EXPRESS',
	'BITCOIN',
	'BOGUS',
	'DANKORT',
	'DINERS_CLUB',
	'DISCOVER',
	'DOGECOIN',
	'ELO',
	'FORBRUGSFORENINGEN',
	'INTERAC',
	'JCB',
	'LITECOIN',
	'MAESTRO',
	'MASTERCARD',
	'PAYPAL',
	'UNIONPAY',
	'VISA',
]);

export const PaymentTermsCreateUserErrorCodeSchema = z.enum(['PAYMENT_TERMS_CREATION_UNSUCCESSFUL']);

export const PaymentTermsDeleteUserErrorCodeSchema = z.enum(['PAYMENT_TERMS_DELETE_UNSUCCESSFUL']);

export const PaymentTermsTypeSchema = z.enum(['FIXED', 'NET', 'RECEIPT', 'UNKNOWN']);

export const PaymentTermsUpdateUserErrorCodeSchema = z.enum(['PAYMENT_TERMS_UPDATE_UNSUCCESSFUL']);

export const PaypalExpressSubscriptionsGatewayStatusSchema = z.enum(['DISABLED', 'ENABLED', 'PENDING']);

export const PriceListAdjustmentTypeSchema = z.enum(['PERCENTAGE_DECREASE', 'PERCENTAGE_INCREASE']);

export const PriceListPriceOriginTypeSchema = z.enum(['FIXED', 'RELATIVE']);

export const PriceListPriceUserErrorCodeSchema = z.enum([
	'BLANK',
	'PRICE_LIST_CURRENCY_MISMATCH',
	'PRICE_LIST_NOT_FOUND',
	'PRICE_NOT_FIXED',
	'VARIANT_NOT_FOUND',
]);

export const PriceListSortKeysSchema = z.enum(['ID', 'NAME', 'RELEVANCE']);

export const PriceListUserErrorCodeSchema = z.enum([
	'CONTEXT_RULE_COUNTRIES_LIMIT',
	'CONTEXT_RULE_COUNTRY_TAKEN',
	'CONTEXT_RULE_LIMIT_ONE_OPTION',
	'CONTEXT_RULE_LIMIT_REACHED',
	'CONTEXT_RULE_MARKET_NOT_FOUND',
	'CONTEXT_RULE_MARKET_TAKEN',
	'COUNTRY_CURRENCY_MISMATCH',
	'CURRENCY_COUNTRY_MISMATCH',
	'CURRENCY_MARKET_MISMATCH',
	'CURRENCY_NOT_SUPPORTED',
	'INVALID_ADJUSTMENT_VALUE',
	'MARKET_CURRENCY_MISMATCH',
	'PRICE_LIST_NOT_ALLOWED_FOR_PRIMARY_MARKET',
	'PRICE_LIST_NOT_FOUND',
	'TAKEN',
]);

export const PriceRuleAllocationMethodSchema = z.enum(['ACROSS', 'EACH']);

export const PriceRuleErrorCodeSchema = z.enum([
	'ALLOCATION_METHOD_MUST_BE_ACROSS_FOR_GIVEN_TARGET_SELECTION',
	'APPLIES_ON_NOTHING',
	'BLANK',
	'BOGO_INVALID_TARGET_SELECTION',
	'BOGO_INVALID_TARGET_TYPE',
	'BOGO_INVALID_VALUE_TYPE',
	'BOTH_CUSTOMER_AND_SAVED_SEARCH_PREREQUISITES_SELECTED',
	'BOTH_CUSTOMER_AND_SEGMENT_PREREQUISITES_SELECTED',
	'BOTH_SAVED_SEARCH_AND_SEGMENT_PREREQUISITES_SELECTED',
	'CANNOT_ENTITLE_COLLECTIONS_WITH_PRODUCTS_OR_VARIANTS',
	'CANNOT_PREREQUISITE_COLLECTION_WITH_PRODUCT_OR_VARIANTS',
	'CUSTOMER_PREREQUISITES_EXCEEDED_MAX',
	'CUSTOMER_PREREQUISITES_INVALID_SELECTION',
	'CUSTOMER_PREREQUISITES_MISSING',
	'CUSTOMER_PREREQUISITE_DUPLICATE',
	'CUSTOMER_SAVED_SEARCH_DUPLICATE',
	'CUSTOMER_SAVED_SEARCH_EXCEEDED_MAX',
	'CUSTOMER_SAVED_SEARCH_INVALID',
	'CUSTOMER_SEGMENT_EXCEEDED_MAX',
	'CUSTOMER_SEGMENT_INVALID',
	'CUSTOMER_SEGMENT_PREREQUISITE_DUPLICATE',
	'DISCOUNT_CODE_DUPLICATE',
	'END_DATE_BEFORE_START_DATE',
	'EQUAL_TO',
	'EXCEEDED_MAX',
	'GREATER_THAN',
	'GREATER_THAN_OR_EQUAL_TO',
	'INTERNAL_ERROR',
	'INVALID',
	'INVALID_COMBINES_WITH_FOR_DISCOUNT_CLASS',
	'INVALID_DISCOUNT_CLASS_FOR_PRICE_RULE',
	'INVALID_TARGET_TYPE_PREREQUISITE_SHIPPING_PRICE_RANGE',
	'ITEM_ENTITLEMENTS_DUPLICATE_COLLECTION',
	'ITEM_ENTITLEMENTS_DUPLICATE_PRODUCT',
	'ITEM_ENTITLEMENTS_DUPLICATE_VARIANT',
	'ITEM_ENTITLEMENTS_EXCEEDED_MAX_COLLECTION',
	'ITEM_ENTITLEMENTS_EXCEEDED_MAX_PRODUCT',
	'ITEM_ENTITLEMENTS_EXCEEDED_MAX_VARIANT',
	'ITEM_ENTITLEMENTS_INVALID_COLLECTION',
	'ITEM_ENTITLEMENTS_INVALID_PRODUCT',
	'ITEM_ENTITLEMENTS_INVALID_TARGET_TYPE_OR_SELECTION',
	'ITEM_ENTITLEMENTS_INVALID_VARIANT',
	'ITEM_ENTITLEMENTS_MISSING',
	'ITEM_ENTITLEMENT_INVALID_TYPE',
	'ITEM_PREREQUISITES_DUPLICATE_COLLECTION',
	'ITEM_PREREQUISITES_DUPLICATE_PRODUCT',
	'ITEM_PREREQUISITES_DUPLICATE_VARIANT',
	'ITEM_PREREQUISITES_EXCEEDED_MAX',
	'ITEM_PREREQUISITES_INVALID_COLLECTION',
	'ITEM_PREREQUISITES_INVALID_PRODUCT',
	'ITEM_PREREQUISITES_INVALID_TYPE',
	'ITEM_PREREQUISITES_INVALID_VARIANT',
	'ITEM_PREREQUISITES_MISSING',
	'ITEM_PREREQUISITES_MUST_BE_EMPTY',
	'LESS_THAN',
	'LESS_THAN_OR_EQUAL_TO',
	'MISSING_ARGUMENT',
	'MULTIPLE_RECURRING_CYCLE_LIMIT_FOR_NON_SUBSCRIPTION_ITEMS',
	'PREREQUISITE_SUBTOTAL_AND_QUANTITY_RANGE_BOTH_PRESENT',
	'PRICE_RULE_ALLOCATION_LIMIT_IS_ZERO',
	'PRICE_RULE_ALLOCATION_LIMIT_ON_NON_BOGO',
	'PRICE_RULE_EXCEEDED_MAX_DISCOUNT_CODE',
	'PRICE_RULE_PERCENTAGE_VALUE_OUTSIDE_RANGE',
	'SHIPPING_ENTITLEMENTS_DUPLICATE_COUNTRY',
	'SHIPPING_ENTITLEMENTS_EXCEEDED_MAX',
	'SHIPPING_ENTITLEMENTS_INVALID_COUNTRY',
	'SHIPPING_ENTITLEMENTS_INVALID_TARGET_TYPE_OR_SELECTION',
	'SHIPPING_ENTITLEMENTS_MISSING',
	'SHIPPING_ENTITLEMENTS_UNSUPPORTED_DESTINATION_TYPE',
	'SHOP_EXCEEDED_MAX_PRICE_RULES',
	'TAKEN',
	'TOO_LONG',
	'TOO_MANY_ARGUMENTS',
	'TOO_SHORT',
	'VARIANT_ALREADY_ENTITLED_THROUGH_PRODUCT',
]);

export const PriceRuleFeatureSchema = z.enum([
	'BULK',
	'BUY_ONE_GET_ONE',
	'BUY_ONE_GET_ONE_WITH_ALLOCATION_LIMIT',
	'QUANTITY_DISCOUNTS',
	'SPECIFIC_CUSTOMERS',
]);

export const PriceRuleShareableUrlTargetTypeSchema = z.enum(['COLLECTION', 'HOME', 'PRODUCT']);

export const PriceRuleSortKeysSchema = z.enum([
	'CREATED_AT',
	'ENDS_AT',
	'ID',
	'RELEVANCE',
	'STARTS_AT',
	'TITLE',
	'UPDATED_AT',
]);

export const PriceRuleStatusSchema = z.enum(['ACTIVE', 'EXPIRED', 'SCHEDULED']);

export const PriceRuleTargetSchema = z.enum(['LINE_ITEM', 'SHIPPING_LINE']);

export const PriceRuleTraitSchema = z.enum([
	'BULK',
	'BUY_ONE_GET_ONE',
	'BUY_ONE_GET_ONE_WITH_ALLOCATION_LIMIT',
	'QUANTITY_DISCOUNTS',
	'SPECIFIC_CUSTOMERS',
]);

export const PrivateMetafieldValueTypeSchema = z.enum(['INTEGER', 'JSON_STRING', 'STRING']);

export const ProductChangeStatusUserErrorCodeSchema = z.enum(['PRODUCT_NOT_FOUND']);

export const ProductCollectionSortKeysSchema = z.enum([
	'BEST_SELLING',
	'COLLECTION_DEFAULT',
	'CREATED',
	'ID',
	'MANUAL',
	'PRICE',
	'RELEVANCE',
	'TITLE',
]);

export const ProductImageSortKeysSchema = z.enum(['CREATED_AT', 'ID', 'POSITION', 'RELEVANCE']);

export const ProductMediaSortKeysSchema = z.enum(['ID', 'POSITION', 'RELEVANCE']);

export const ProductSortKeysSchema = z.enum([
	'CREATED_AT',
	'ID',
	'INVENTORY_TOTAL',
	'PRODUCT_TYPE',
	'PUBLISHED_AT',
	'RELEVANCE',
	'TITLE',
	'UPDATED_AT',
	'VENDOR',
]);

export const ProductStatusSchema = z.enum(['ACTIVE', 'ARCHIVED', 'DRAFT']);

export const ProductVariantInventoryManagementSchema = z.enum(['FULFILLMENT_SERVICE', 'NOT_MANAGED', 'SHOPIFY']);

export const ProductVariantInventoryPolicySchema = z.enum(['CONTINUE', 'DENY']);

export const ProductVariantSortKeysSchema = z.enum([
	'FULL_TITLE',
	'ID',
	'INVENTORY_LEVELS_AVAILABLE',
	'INVENTORY_MANAGEMENT',
	'INVENTORY_POLICY',
	'INVENTORY_QUANTITY',
	'NAME',
	'POPULAR',
	'POSITION',
	'RELEVANCE',
	'SKU',
	'TITLE',
]);

export const ProductVariantsBulkCreateUserErrorCodeSchema = z.enum([
	'GREATER_THAN_OR_EQUAL_TO',
	'INVALID',
	'MUST_BE_FOR_THIS_PRODUCT',
	'NEED_TO_ADD_OPTION_VALUES',
	'NEGATIVE_PRICE_VALUE',
	'NOT_DEFINED_FOR_SHOP',
	'NO_KEY_ON_CREATE',
	'OPTION_VALUES_FOR_NUMBER_OF_UNKNOWN_OPTIONS',
	'PRODUCT_DOES_NOT_EXIST',
	'SUBSCRIPTION_VIOLATION',
	'TOO_MANY_INVENTORY_LOCATIONS',
	'TRACKED_VARIANT_LOCATION_NOT_FOUND',
	'VARIANT_ALREADY_EXISTS',
	'VARIANT_ALREADY_EXISTS_CHANGE_OPTION_VALUE',
]);

export const ProductVariantsBulkDeleteUserErrorCodeSchema = z.enum([
	'AT_LEAST_ONE_VARIANT_DOES_NOT_BELONG_TO_THE_PRODUCT',
	'CANNOT_DELETE_LAST_VARIANT',
	'PRODUCT_DOES_NOT_EXIST',
]);

export const ProductVariantsBulkReorderUserErrorCodeSchema = z.enum([
	'DUPLICATED_VARIANT_ID',
	'INVALID_POSITION',
	'MISSING_VARIANT',
	'PRODUCT_DOES_NOT_EXIST',
]);

export const ProductVariantsBulkUpdateUserErrorCodeSchema = z.enum([
	'GREATER_THAN_OR_EQUAL_TO',
	'NEED_TO_ADD_OPTION_VALUES',
	'NEGATIVE_PRICE_VALUE',
	'NO_INVENTORY_QUANTITES_DURING_UPDATE',
	'NO_INVENTORY_QUANTITIES_ON_VARIANTS_UPDATE',
	'OPTION_VALUES_FOR_NUMBER_OF_UNKNOWN_OPTIONS',
	'PRODUCT_DOES_NOT_EXIST',
	'PRODUCT_VARIANT_DOES_NOT_EXIST',
	'PRODUCT_VARIANT_ID_MISSING',
	'SUBSCRIPTION_VIOLATION',
	'VARIANT_ALREADY_EXISTS',
]);

export const ProfileItemSortKeysSchema = z.enum([
	'CREATED_AT',
	'ID',
	'INVENTORY_TOTAL',
	'PRODUCT_TYPE',
	'PUBLISHED_AT',
	'RELEVANCE',
	'TITLE',
	'UPDATED_AT',
	'VENDOR',
]);

export const PubSubWebhookSubscriptionCreateUserErrorCodeSchema = z.enum(['INVALID_PARAMETERS']);

export const PubSubWebhookSubscriptionUpdateUserErrorCodeSchema = z.enum(['INVALID_PARAMETERS']);

export const RefundDutyRefundTypeSchema = z.enum(['FULL', 'PROPORTIONAL']);

export const RefundLineItemRestockTypeSchema = z.enum(['CANCEL', 'LEGACY_RESTOCK', 'NO_RESTOCK', 'RETURN']);

export const ResourceAlertIconSchema = z.enum(['CHECKMARK_CIRCLE', 'INFORMATION_CIRCLE']);

export const ResourceAlertSeveritySchema = z.enum(['CRITICAL', 'DEFAULT', 'ERROR', 'INFO', 'SUCCESS', 'WARNING']);

export const ResourceFeedbackStateSchema = z.enum(['ACCEPTED', 'REQUIRES_ACTION']);

export const SaleActionTypeSchema = z.enum(['ORDER', 'RETURN', 'UNKNOWN', 'UPDATE']);

export const SaleLineTypeSchema = z.enum(['ADJUSTMENT', 'DUTY', 'GIFT_CARD', 'PRODUCT', 'SHIPPING', 'TIP', 'UNKNOWN']);

export const ScriptTagDisplayScopeSchema = z.enum(['ALL', 'ONLINE_STORE', 'ORDER_STATUS']);

export const SearchResultTypeSchema = z.enum([
	'COLLECTION',
	'CUSTOMER',
	'DISCOUNT_REDEEM_CODE',
	'DRAFT_ORDER',
	'FILE',
	'ONLINE_STORE_ARTICLE',
	'ONLINE_STORE_BLOG',
	'ONLINE_STORE_PAGE',
	'ORDER',
	'PRICE_RULE',
	'PRODUCT',
	'URL_REDIRECT',
]);

export const SegmentSortKeysSchema = z.enum(['CREATION_DATE', 'ID', 'LAST_EDIT_DATE', 'RELEVANCE']);

export const SellingPlanAnchorTypeSchema = z.enum(['MONTHDAY', 'WEEKDAY', 'YEARDAY']);

export const SellingPlanCategorySchema = z.enum(['OTHER', 'PRE_ORDER', 'SUBSCRIPTION', 'TRY_BEFORE_YOU_BUY']);

export const SellingPlanCheckoutChargeTypeSchema = z.enum(['PERCENTAGE', 'PRICE']);

export const SellingPlanFixedDeliveryPolicyIntentSchema = z.enum(['FULFILLMENT_BEGIN']);

export const SellingPlanFixedDeliveryPolicyPreAnchorBehaviorSchema = z.enum(['ASAP', 'NEXT']);

export const SellingPlanFulfillmentTriggerSchema = z.enum(['ANCHOR', 'ASAP', 'EXACT_TIME', 'UNKNOWN']);

export const SellingPlanGroupSortKeysSchema = z.enum(['CREATED_AT', 'ID', 'NAME', 'RELEVANCE', 'UPDATED_AT']);

export const SellingPlanGroupUserErrorCodeSchema = z.enum([
	'BILLING_AND_DELIVERY_POLICY_TYPES_MUST_BE_THE_SAME',
	'BLANK',
	'CHECKOUT_CHARGE_VALUE_AND_TYPE_MUST_MATCH',
	'EQUAL_TO',
	'ERROR_ADDING_RESOURCE_TO_GROUP',
	'FULFILLMENT_EXACT_TIME_NOT_ALLOWED',
	'FULFILLMENT_EXACT_TIME_REQUIRED',
	'GREATER_THAN',
	'GREATER_THAN_OR_EQUAL_TO',
	'GROUP_COULD_NOT_BE_DELETED',
	'GROUP_DOES_NOT_EXIST',
	'INCLUSION',
	'INVALID',
	'LESS_THAN',
	'LESS_THAN_OR_EQUAL_TO',
	'NOT_A_NUMBER',
	'NOT_FOUND',
	'ONLY_NEED_ONE_BILLING_POLICY_TYPE',
	'ONLY_NEED_ONE_CHECKOUT_CHARGE_VALUE',
	'ONLY_NEED_ONE_DELIVERY_POLICY_TYPE',
	'ONLY_NEED_ONE_PRICING_POLICY_TYPE',
	'ONLY_NEED_ONE_PRICING_POLICY_VALUE',
	'ONLY_ONE_OF_FIXED_OR_RECURRING_BILLING',
	'ONLY_ONE_OF_FIXED_OR_RECURRING_DELIVERY',
	'PLAN_DOES_NOT_EXIST',
	'PLAN_ID_MUST_BE_SPECIFIED_TO_UPDATE',
	'PRESENT',
	'PRICING_POLICY_ADJUSTMENT_VALUE_AND_TYPE_MUST_MATCH',
	'PRODUCT_DOES_NOT_EXIST',
	'PRODUCT_VARIANT_DOES_NOT_EXIST',
	'REMAINING_BALANCE_CHARGE_EXACT_TIME_NOT_ALLOWED',
	'REMAINING_BALANCE_CHARGE_EXACT_TIME_REQUIRED',
	'REMAINING_BALANCE_CHARGE_TIME_AFTER_CHECKOUT_MUST_BE_GREATER_THAN_ZERO',
	'REMAINING_BALANCE_CHARGE_TRIGGER_NO_REMAINING_BALANCE_ON_PARTIAL_PERCENTAGE_CHECKOUT_CHARGE',
	'REMAINING_BALANCE_CHARGE_TRIGGER_NO_REMAINING_BALANCE_ON_PRICE_CHECKOUT_CHARGE',
	'REMAINING_BALANCE_CHARGE_TRIGGER_ON_FULL_CHECKOUT',
	'RESOURCE_LIST_CONTAINS_INVALID_IDS',
	'SELLING_PLAN_ANCHORS_NOT_ALLOWED',
	'SELLING_PLAN_ANCHORS_REQUIRED',
	'SELLING_PLAN_BILLING_AND_DELIVERY_POLICY_ANCHORS_MUST_BE_EQUAL',
	'SELLING_PLAN_BILLING_CYCLE_MUST_BE_A_MULTIPLE_OF_DELIVERY_CYCLE',
	'SELLING_PLAN_BILLING_POLICY_MISSING',
	'SELLING_PLAN_COUNT_LOWER_BOUND',
	'SELLING_PLAN_COUNT_UPPER_BOUND',
	'SELLING_PLAN_DELIVERY_POLICY_MISSING',
	'SELLING_PLAN_DUPLICATE_NAME',
	'SELLING_PLAN_DUPLICATE_OPTIONS',
	'SELLING_PLAN_FIXED_PRICING_POLICIES_LIMIT',
	'SELLING_PLAN_MAX_CYCLES_MUST_BE_GREATER_THAN_MIN_CYCLES',
	'SELLING_PLAN_MISSING_OPTION2_LABEL_ON_PARENT_GROUP',
	'SELLING_PLAN_MISSING_OPTION3_LABEL_ON_PARENT_GROUP',
	'SELLING_PLAN_OPTION2_REQUIRED_AS_DEFINED_ON_PARENT_GROUP',
	'SELLING_PLAN_OPTION3_REQUIRED_AS_DEFINED_ON_PARENT_GROUP',
	'SELLING_PLAN_PRICING_POLICIES_LIMIT',
	'SELLING_PLAN_PRICING_POLICIES_MUST_CONTAIN_A_FIXED_PRICING_POLICY',
	'TAKEN',
	'TOO_BIG',
	'TOO_LONG',
	'TOO_SHORT',
	'WRONG_LENGTH',
]);

export const SellingPlanIntervalSchema = z.enum(['DAY', 'MONTH', 'WEEK', 'YEAR']);

export const SellingPlanPricingPolicyAdjustmentTypeSchema = z.enum(['FIXED_AMOUNT', 'PERCENTAGE', 'PRICE']);

export const SellingPlanRecurringDeliveryPolicyIntentSchema = z.enum(['FULFILLMENT_BEGIN']);

export const SellingPlanRecurringDeliveryPolicyPreAnchorBehaviorSchema = z.enum(['ASAP', 'NEXT']);

export const SellingPlanRemainingBalanceChargeTriggerSchema = z.enum([
	'EXACT_TIME',
	'NO_REMAINING_BALANCE',
	'TIME_AFTER_CHECKOUT',
]);

export const SellingPlanReserveSchema = z.enum(['ON_FULFILLMENT', 'ON_SALE']);

export const ShippingDiscountClassSchema = z.enum(['SHIPPING']);

export const ShopBrandingSchema = z.enum(['ROGERS', 'SHOPIFY', 'SHOPIFY_GOLD', 'SHOPIFY_PLUS']);

export const ShopCustomerAccountsSettingSchema = z.enum(['DISABLED', 'OPTIONAL', 'REQUIRED']);

export const ShopPolicyErrorCodeSchema = z.enum(['TOO_BIG']);

export const ShopPolicyTypeSchema = z.enum([
	'LEGAL_NOTICE',
	'PRIVACY_POLICY',
	'REFUND_POLICY',
	'SHIPPING_POLICY',
	'SUBSCRIPTION_POLICY',
	'TERMS_OF_SALE',
	'TERMS_OF_SERVICE',
]);

export const ShopTagSortSchema = z.enum(['ALPHABETICAL', 'POPULAR']);

export const ShopifyPaymentsBankAccountStatusSchema = z.enum(['ERRORED', 'NEW', 'VALIDATED', 'VERIFIED']);

export const ShopifyPaymentsDisputeEvidenceFileTypeSchema = z.enum([
	'CANCELLATION_POLICY_FILE',
	'CUSTOMER_COMMUNICATION_FILE',
	'REFUND_POLICY_FILE',
	'SERVICE_DOCUMENTATION_FILE',
	'SHIPPING_DOCUMENTATION_FILE',
	'UNCATEGORIZED_FILE',
]);

export const ShopifyPaymentsDisputeReasonSchema = z.enum([
	'BANK_CANNOT_PROCESS',
	'CREDIT_NOT_PROCESSED',
	'CUSTOMER_INITIATED',
	'DEBIT_NOT_AUTHORIZED',
	'DUPLICATE',
	'FRAUDULENT',
	'GENERAL',
	'INCORRECT_ACCOUNT_DETAILS',
	'INSUFFICIENT_FUNDS',
	'PRODUCT_NOT_RECEIVED',
	'PRODUCT_UNACCEPTABLE',
	'SUBSCRIPTION_CANCELLED',
	'UNRECOGNIZED',
]);

export const ShopifyPaymentsPayoutIntervalSchema = z.enum(['DAILY', 'MANUAL', 'MONTHLY', 'WEEKLY']);

export const ShopifyPaymentsPayoutStatusSchema = z.enum(['CANCELED', 'FAILED', 'IN_TRANSIT', 'PAID', 'SCHEDULED']);

export const ShopifyPaymentsPayoutTransactionTypeSchema = z.enum(['DEPOSIT', 'WITHDRAWAL']);

export const ShopifyPaymentsVerificationDocumentTypeSchema = z.enum([
	'DRIVERS_LICENSE',
	'GOVERNMENT_IDENTIFICATION',
	'PASSPORT',
]);

export const ShopifyPaymentsVerificationStatusSchema = z.enum(['PENDING', 'UNVERIFIED', 'VERIFIED']);

export const StaffMemberDefaultImageSchema = z.enum(['DEFAULT', 'NOT_FOUND', 'TRANSPARENT']);

export const StaffMemberPermissionSchema = z.enum([
	'APPLICATIONS',
	'CHANNELS',
	'CUSTOMERS',
	'DASHBOARD',
	'DOMAINS',
	'DRAFT_ORDERS',
	'EDIT_ORDERS',
	'FULL',
	'GIFT_CARDS',
	'LINKS',
	'LOCATIONS',
	'MARKETING',
	'MARKETING_SECTION',
	'ORDERS',
	'OVERVIEWS',
	'PAGES',
	'PAY_ORDERS_BY_VAULTED_CARD',
	'PREFERENCES',
	'PRODUCTS',
	'REPORTS',
	'THEMES',
	'TRANSLATIONS',
]);

export const StagedUploadHttpMethodTypeSchema = z.enum(['POST', 'PUT']);

export const StagedUploadTargetGenerateUploadResourceSchema = z.enum([
	'BULK_MUTATION_VARIABLES',
	'COLLECTION_IMAGE',
	'FILE',
	'IMAGE',
	'MODEL_3D',
	'PRODUCT_IMAGE',
	'SHOP_IMAGE',
	'URL_REDIRECT_IMPORT',
	'VIDEO',
]);

export const StandardMetafieldDefinitionEnableUserErrorCodeSchema = z.enum([
	'INVALID',
	'LIMIT_EXCEEDED',
	'TAKEN',
	'TEMPLATE_NOT_FOUND',
	'UNSTRUCTURED_ALREADY_EXISTS',
]);

export const SubscriptionBillingAttemptErrorCodeSchema = z.enum([
	'AMOUNT_TOO_SMALL',
	'AUTHENTICATION_ERROR',
	'BUYER_CANCELED_PAYMENT_METHOD',
	'CUSTOMER_INVALID',
	'CUSTOMER_NOT_FOUND',
	'EXPIRED_PAYMENT_METHOD',
	'INVALID_CUSTOMER_BILLING_AGREEMENT',
	'INVALID_PAYMENT_METHOD',
	'INVALID_SHIPPING_ADDRESS',
	'INVOICE_ALREADY_PAID',
	'PAYMENT_METHOD_DECLINED',
	'PAYMENT_METHOD_INCOMPATIBLE_WITH_GATEWAY_CONFIG',
	'PAYMENT_METHOD_NOT_FOUND',
	'PAYMENT_PROVIDER_IS_NOT_ENABLED',
	'TEST_MODE',
	'UNEXPECTED_ERROR',
]);

export const SubscriptionBillingCycleBillingCycleStatusSchema = z.enum(['BILLED', 'UNBILLED']);

export const SubscriptionBillingCycleErrorCodeSchema = z.enum([
	'BILLING_DATE_SET_ON_SKIPPED',
	'CYCLE_NOT_FOUND',
	'EMPTY_BILLING_CYCLE_EDIT_SCHEDULE_INPUT',
	'INVALID',
	'INVALID_CYCLE_INDEX',
	'INVALID_DATE',
	'NO_CYCLE_EDITS',
	'OUT_OF_BOUNDS',
]);

export const SubscriptionBillingCycleScheduleEditInputScheduleEditReasonSchema = z.enum([
	'BUYER_INITIATED',
	'DEV_INITIATED',
	'MERCHANT_INITIATED',
]);

export const SubscriptionBillingCyclesSortKeysSchema = z.enum(['CYCLE_INDEX', 'ID', 'RELEVANCE']);

export const SubscriptionBillingCyclesTargetSelectionSchema = z.enum(['ALL']);

export const SubscriptionContractErrorCodeSchema = z.enum(['INVALID']);

export const SubscriptionContractLastPaymentStatusSchema = z.enum(['FAILED', 'SUCCEEDED']);

export const SubscriptionContractSubscriptionStatusSchema = z.enum([
	'ACTIVE',
	'CANCELLED',
	'EXPIRED',
	'FAILED',
	'PAUSED',
]);

export const SubscriptionDiscountRejectionReasonSchema = z.enum([
	'CURRENTLY_INACTIVE',
	'CUSTOMER_NOT_ELIGIBLE',
	'CUSTOMER_USAGE_LIMIT_REACHED',
	'INCOMPATIBLE_PURCHASE_TYPE',
	'INTERNAL_ERROR',
	'NOT_FOUND',
	'NO_ENTITLED_LINE_ITEMS',
	'NO_ENTITLED_SHIPPING_LINES',
	'PURCHASE_NOT_IN_RANGE',
	'QUANTITY_NOT_IN_RANGE',
	'USAGE_LIMIT_REACHED',
]);

export const SubscriptionDraftErrorCodeSchema = z.enum([
	'ALREADY_REMOVED',
	'BILLING_CYCLE_ABSENT',
	'BILLING_CYCLE_CONTRACT_DRAFT_BILLING_POLICY_INVALID',
	'BILLING_CYCLE_CONTRACT_DRAFT_DELIVERY_POLICY_INVALID',
	'BILLING_CYCLE_PRESENT',
	'BLANK',
	'COMMITTED',
	'CONCATENATION_BILLING_CYCLE_CONTRACT_DRAFT_REQUIRED',
	'CURRENCY_NOT_ENABLED',
	'CUSTOMER_DOES_NOT_EXIST',
	'CUSTOMER_MISMATCH',
	'CYCLE_DISCOUNTS_UNIQUE_AFTER_CYCLE',
	'CYCLE_INDEX_OUT_OF_RANGE',
	'CYCLE_SELECTOR_VALIDATE_ONE_OF',
	'CYCLE_START_DATE_OUT_OF_RANGE',
	'DELIVERY_METHOD_REQUIRED',
	'DELIVERY_MUST_BE_MULTIPLE_OF_BILLING',
	'DUPLICATE_CONCATENATED_CONTRACTS',
	'EXCEEDED_MAX_CONCATENATED_CONTRACTS',
	'GREATER_THAN',
	'GREATER_THAN_OR_EQUAL_TO',
	'HAS_FUTURE_EDITS',
	'INVALID',
	'INVALID_ADJUSTMENT_TYPE',
	'INVALID_ADJUSTMENT_VALUE',
	'INVALID_BILLING_DATE',
	'INVALID_LINES',
	'INVALID_NOTE_LENGTH',
	'LESS_THAN',
	'LESS_THAN_OR_EQUAL_TO',
	'NOT_AN_INTEGER',
	'NOT_IN_RANGE',
	'NO_ENTITLED_LINES',
	'PRESENCE',
	'SELLING_PLAN_MAX_CYCLES_MUST_BE_GREATER_THAN_MIN_CYCLES',
	'STALE_CONTRACT',
	'TOO_LONG',
	'TOO_SHORT',
	'UPCOMING_CYCLE_LIMIT_EXCEEDED',
]);

export const SuggestedOrderTransactionKindSchema = z.enum(['SUGGESTED_REFUND']);

export const TaxExemptionSchema = z.enum([
	'CA_BC_COMMERCIAL_FISHERY_EXEMPTION',
	'CA_BC_CONTRACTOR_EXEMPTION',
	'CA_BC_PRODUCTION_AND_MACHINERY_EXEMPTION',
	'CA_BC_RESELLER_EXEMPTION',
	'CA_BC_SUB_CONTRACTOR_EXEMPTION',
	'CA_DIPLOMAT_EXEMPTION',
	'CA_MB_COMMERCIAL_FISHERY_EXEMPTION',
	'CA_MB_FARMER_EXEMPTION',
	'CA_MB_RESELLER_EXEMPTION',
	'CA_NS_COMMERCIAL_FISHERY_EXEMPTION',
	'CA_NS_FARMER_EXEMPTION',
	'CA_ON_PURCHASE_EXEMPTION',
	'CA_PE_COMMERCIAL_FISHERY_EXEMPTION',
	'CA_SK_COMMERCIAL_FISHERY_EXEMPTION',
	'CA_SK_CONTRACTOR_EXEMPTION',
	'CA_SK_FARMER_EXEMPTION',
	'CA_SK_PRODUCTION_AND_MACHINERY_EXEMPTION',
	'CA_SK_RESELLER_EXEMPTION',
	'CA_SK_SUB_CONTRACTOR_EXEMPTION',
	'CA_STATUS_CARD_EXEMPTION',
	'EU_REVERSE_CHARGE_EXEMPTION_RULE',
	'US_AK_RESELLER_EXEMPTION',
	'US_AL_RESELLER_EXEMPTION',
	'US_AR_RESELLER_EXEMPTION',
	'US_AZ_RESELLER_EXEMPTION',
	'US_CA_RESELLER_EXEMPTION',
	'US_CO_RESELLER_EXEMPTION',
	'US_CT_RESELLER_EXEMPTION',
	'US_DC_RESELLER_EXEMPTION',
	'US_DE_RESELLER_EXEMPTION',
	'US_FL_RESELLER_EXEMPTION',
	'US_GA_RESELLER_EXEMPTION',
	'US_HI_RESELLER_EXEMPTION',
	'US_IA_RESELLER_EXEMPTION',
	'US_ID_RESELLER_EXEMPTION',
	'US_IL_RESELLER_EXEMPTION',
	'US_IN_RESELLER_EXEMPTION',
	'US_KS_RESELLER_EXEMPTION',
	'US_KY_RESELLER_EXEMPTION',
	'US_LA_RESELLER_EXEMPTION',
	'US_MA_RESELLER_EXEMPTION',
	'US_MD_RESELLER_EXEMPTION',
	'US_ME_RESELLER_EXEMPTION',
	'US_MI_RESELLER_EXEMPTION',
	'US_MN_RESELLER_EXEMPTION',
	'US_MO_RESELLER_EXEMPTION',
	'US_MS_RESELLER_EXEMPTION',
	'US_MT_RESELLER_EXEMPTION',
	'US_NC_RESELLER_EXEMPTION',
	'US_ND_RESELLER_EXEMPTION',
	'US_NE_RESELLER_EXEMPTION',
	'US_NH_RESELLER_EXEMPTION',
	'US_NJ_RESELLER_EXEMPTION',
	'US_NM_RESELLER_EXEMPTION',
	'US_NV_RESELLER_EXEMPTION',
	'US_NY_RESELLER_EXEMPTION',
	'US_OH_RESELLER_EXEMPTION',
	'US_OK_RESELLER_EXEMPTION',
	'US_OR_RESELLER_EXEMPTION',
	'US_PA_RESELLER_EXEMPTION',
	'US_RI_RESELLER_EXEMPTION',
	'US_SC_RESELLER_EXEMPTION',
	'US_SD_RESELLER_EXEMPTION',
	'US_TN_RESELLER_EXEMPTION',
	'US_TX_RESELLER_EXEMPTION',
	'US_UT_RESELLER_EXEMPTION',
	'US_VA_RESELLER_EXEMPTION',
	'US_VT_RESELLER_EXEMPTION',
	'US_WA_RESELLER_EXEMPTION',
	'US_WI_RESELLER_EXEMPTION',
	'US_WV_RESELLER_EXEMPTION',
	'US_WY_RESELLER_EXEMPTION',
]);

export const TranslatableResourceTypeSchema = z.enum([
	'COLLECTION',
	'DELIVERY_METHOD_DEFINITION',
	'EMAIL_TEMPLATE',
	'LINK',
	'METAFIELD',
	'ONLINE_STORE_ARTICLE',
	'ONLINE_STORE_BLOG',
	'ONLINE_STORE_MENU',
	'ONLINE_STORE_PAGE',
	'ONLINE_STORE_THEME',
	'PACKING_SLIP_TEMPLATE',
	'PAYMENT_GATEWAY',
	'PRODUCT',
	'PRODUCT_OPTION',
	'PRODUCT_VARIANT',
	'SELLING_PLAN',
	'SELLING_PLAN_GROUP',
	'SHOP',
	'SHOP_POLICY',
	'SMS_TEMPLATE',
]);

export const TranslationErrorCodeSchema = z.enum([
	'BLANK',
	'FAILS_RESOURCE_VALIDATION',
	'INVALID',
	'INVALID_CODE',
	'INVALID_FORMAT',
	'INVALID_KEY_FOR_MODEL',
	'INVALID_LOCALE_FOR_MARKET',
	'INVALID_LOCALE_FOR_SHOP',
	'INVALID_MARKET_LOCALIZABLE_CONTENT',
	'INVALID_TRANSLATABLE_CONTENT',
	'INVALID_VALUE_FOR_HANDLE_TRANSLATION',
	'MARKET_CUSTOM_CONTENT_NOT_ALLOWED',
	'MARKET_DOES_NOT_EXIST',
	'MARKET_LOCALE_CREATION_FAILED',
	'RESOURCE_NOT_FOUND',
	'RESOURCE_NOT_MARKET_CUSTOMIZABLE',
	'TOO_MANY_KEYS_FOR_RESOURCE',
]);

export const UnitSystemSchema = z.enum(['IMPERIAL_SYSTEM', 'METRIC_SYSTEM']);

export const UrlRedirectBulkDeleteByIdsUserErrorCodeSchema = z.enum(['IDS_EMPTY']);

export const UrlRedirectBulkDeleteBySavedSearchUserErrorCodeSchema = z.enum([
	'INVALID_SAVED_SEARCH_QUERY',
	'SAVED_SEARCH_NOT_FOUND',
]);

export const UrlRedirectBulkDeleteBySearchUserErrorCodeSchema = z.enum(['INVALID_SEARCH_ARGUMENT']);

export const UrlRedirectErrorCodeSchema = z.enum(['CREATE_FAILED', 'DELETE_FAILED', 'DOES_NOT_EXIST', 'UPDATE_FAILED']);

export const UrlRedirectImportErrorCodeSchema = z.enum([
	'ALREADY_IMPORTED',
	'FILE_DOES_NOT_EXIST',
	'IN_PROGRESS',
	'NOT_FOUND',
]);

export const UrlRedirectSortKeysSchema = z.enum(['ID', 'PATH', 'RELEVANCE']);

export const WebhookSubscriptionFormatSchema = z.enum(['JSON', 'XML']);

export const WebhookSubscriptionSortKeysSchema = z.enum(['CREATED_AT', 'ID', 'RELEVANCE']);

export const WebhookSubscriptionTopicSchema = z.enum([
	'APP_PURCHASES_ONE_TIME_UPDATE',
	'APP_SUBSCRIPTIONS_APPROACHING_CAPPED_AMOUNT',
	'APP_SUBSCRIPTIONS_UPDATE',
	'APP_UNINSTALLED',
	'ATTRIBUTED_SESSIONS_FIRST',
	'ATTRIBUTED_SESSIONS_LAST',
	'BULK_OPERATIONS_FINISH',
	'CARTS_CREATE',
	'CARTS_UPDATE',
	'CHANNELS_DELETE',
	'CHECKOUTS_CREATE',
	'CHECKOUTS_DELETE',
	'CHECKOUTS_UPDATE',
	'COLLECTIONS_CREATE',
	'COLLECTIONS_DELETE',
	'COLLECTIONS_UPDATE',
	'COLLECTION_LISTINGS_ADD',
	'COLLECTION_LISTINGS_REMOVE',
	'COLLECTION_LISTINGS_UPDATE',
	'COLLECTION_PUBLICATIONS_CREATE',
	'COLLECTION_PUBLICATIONS_DELETE',
	'COLLECTION_PUBLICATIONS_UPDATE',
	'CUSTOMERS_CREATE',
	'CUSTOMERS_DELETE',
	'CUSTOMERS_DISABLE',
	'CUSTOMERS_ENABLE',
	'CUSTOMERS_MARKETING_CONSENT_UPDATE',
	'CUSTOMERS_UPDATE',
	'CUSTOMER_GROUPS_CREATE',
	'CUSTOMER_GROUPS_DELETE',
	'CUSTOMER_GROUPS_UPDATE',
	'CUSTOMER_PAYMENT_METHODS_CREATE',
	'CUSTOMER_PAYMENT_METHODS_REVOKE',
	'CUSTOMER_PAYMENT_METHODS_UPDATE',
	'DISPUTES_CREATE',
	'DISPUTES_UPDATE',
	'DOMAINS_CREATE',
	'DOMAINS_DESTROY',
	'DOMAINS_UPDATE',
	'DRAFT_ORDERS_CREATE',
	'DRAFT_ORDERS_DELETE',
	'DRAFT_ORDERS_UPDATE',
	'FULFILLMENTS_CREATE',
	'FULFILLMENTS_UPDATE',
	'FULFILLMENT_EVENTS_CREATE',
	'FULFILLMENT_EVENTS_DELETE',
	'INVENTORY_ITEMS_CREATE',
	'INVENTORY_ITEMS_DELETE',
	'INVENTORY_ITEMS_UPDATE',
	'INVENTORY_LEVELS_CONNECT',
	'INVENTORY_LEVELS_DISCONNECT',
	'INVENTORY_LEVELS_UPDATE',
	'LOCALES_CREATE',
	'LOCALES_UPDATE',
	'LOCATIONS_CREATE',
	'LOCATIONS_DELETE',
	'LOCATIONS_UPDATE',
	'MARKETS_CREATE',
	'MARKETS_DELETE',
	'MARKETS_UPDATE',
	'ORDERS_CANCELLED',
	'ORDERS_CREATE',
	'ORDERS_DELETE',
	'ORDERS_EDITED',
	'ORDERS_FULFILLED',
	'ORDERS_PAID',
	'ORDERS_PARTIALLY_FULFILLED',
	'ORDERS_UPDATED',
	'ORDER_TRANSACTIONS_CREATE',
	'PAYMENT_TERMS_CREATE',
	'PAYMENT_TERMS_DELETE',
	'PAYMENT_TERMS_UPDATE',
	'PRODUCTS_CREATE',
	'PRODUCTS_DELETE',
	'PRODUCTS_UPDATE',
	'PRODUCT_LISTINGS_ADD',
	'PRODUCT_LISTINGS_REMOVE',
	'PRODUCT_LISTINGS_UPDATE',
	'PRODUCT_PUBLICATIONS_CREATE',
	'PRODUCT_PUBLICATIONS_DELETE',
	'PRODUCT_PUBLICATIONS_UPDATE',
	'PROFILES_CREATE',
	'PROFILES_DELETE',
	'PROFILES_UPDATE',
	'REFUNDS_CREATE',
	'SCHEDULED_PRODUCT_LISTINGS_ADD',
	'SCHEDULED_PRODUCT_LISTINGS_REMOVE',
	'SCHEDULED_PRODUCT_LISTINGS_UPDATE',
	'SEGMENTS_CREATE',
	'SEGMENTS_DELETE',
	'SEGMENTS_UPDATE',
	'SELLING_PLAN_GROUPS_CREATE',
	'SELLING_PLAN_GROUPS_DELETE',
	'SELLING_PLAN_GROUPS_UPDATE',
	'SHIPPING_ADDRESSES_CREATE',
	'SHIPPING_ADDRESSES_UPDATE',
	'SHOP_UPDATE',
	'SUBSCRIPTION_BILLING_ATTEMPTS_CHALLENGED',
	'SUBSCRIPTION_BILLING_ATTEMPTS_FAILURE',
	'SUBSCRIPTION_BILLING_ATTEMPTS_SUCCESS',
	'SUBSCRIPTION_CONTRACTS_CREATE',
	'SUBSCRIPTION_CONTRACTS_UPDATE',
	'TAX_SERVICES_CREATE',
	'TAX_SERVICES_UPDATE',
	'TENDER_TRANSACTIONS_CREATE',
	'THEMES_CREATE',
	'THEMES_DELETE',
	'THEMES_PUBLISH',
	'THEMES_UPDATE',
	'VARIANTS_IN_STOCK',
	'VARIANTS_OUT_OF_STOCK',
]);

export const WeightUnitSchema = z.enum(['GRAMS', 'KILOGRAMS', 'OUNCES', 'POUNDS']);

export function AppPlanInputSchema(): z.ZodObject<Properties<AppPlanInput>> {
	return z.object({
		appRecurringPricingDetails: z.lazy(() => AppRecurringPricingInputSchema().nullish()),
		appUsagePricingDetails: z.lazy(() => AppUsagePricingInputSchema().nullish()),
	});
}

export function AppRecurringPricingInputSchema(): z.ZodObject<Properties<AppRecurringPricingInput>> {
	return z.object({
		discount: z.lazy(() => AppSubscriptionDiscountInputSchema().nullish()),
		interval: AppPricingIntervalSchema.nullish(),
		price: z.lazy(() => MoneyInputSchema()),
	});
}

export function AppRevenueAttributionRecordInputSchema(): z.ZodObject<Properties<AppRevenueAttributionRecordInput>> {
	return z.object({
		amount: z.lazy(() => MoneyInputSchema()),
		capturedAt: definedNonNullAnySchema,
		idempotencyKey: z.string(),
		test: z.boolean(),
		type: AppRevenueAttributionTypeSchema,
	});
}

export function AppSubscriptionDiscountInputSchema(): z.ZodObject<Properties<AppSubscriptionDiscountInput>> {
	return z.object({
		durationLimitInIntervals: z.number().nullish(),
		value: z.lazy(() => AppSubscriptionDiscountValueInputSchema().nullish()),
	});
}

export function AppSubscriptionDiscountValueInputSchema(): z.ZodObject<Properties<AppSubscriptionDiscountValueInput>> {
	return z.object({
		amount: definedNonNullAnySchema.nullish(),
		percentage: z.number().nullish(),
	});
}

export function AppSubscriptionLineItemInputSchema(): z.ZodObject<Properties<AppSubscriptionLineItemInput>> {
	return z.object({
		plan: z.lazy(() => AppPlanInputSchema()),
	});
}

export function AppUsagePricingInputSchema(): z.ZodObject<Properties<AppUsagePricingInput>> {
	return z.object({
		cappedAmount: z.lazy(() => MoneyInputSchema()),
		terms: z.string(),
	});
}

export function AttributeInputSchema(): z.ZodObject<Properties<AttributeInput>> {
	return z.object({
		key: z.string(),
		value: z.string(),
	});
}

export function BuyerExperienceConfigurationInputSchema(): z.ZodObject<Properties<BuyerExperienceConfigurationInput>> {
	return z.object({
		checkoutToDraft: z.boolean().nullish(),
		paymentTermsTemplateId: z.string().nullish(),
	});
}

export function CollectionDeleteInputSchema(): z.ZodObject<Properties<CollectionDeleteInput>> {
	return z.object({
		id: z.string(),
	});
}

export function CollectionInputSchema(): z.ZodObject<Properties<CollectionInput>> {
	return z.object({
		descriptionHtml: z.string().nullish(),
		handle: z.string().nullish(),
		id: z.string().nullish(),
		image: z.lazy(() => ImageInputSchema().nullish()),
		metafields: z.array(z.lazy(() => MetafieldInputSchema())).nullish(),
		products: z.array(z.string()).nullish(),
		redirectNewHandle: z.boolean().nullish(),
		ruleSet: z.lazy(() => CollectionRuleSetInputSchema().nullish()),
		seo: z.lazy(() => SeoInputSchema().nullish()),
		sortOrder: CollectionSortOrderSchema.nullish(),
		templateSuffix: z.string().nullish(),
		title: z.string().nullish(),
	});
}

export function CollectionPublicationInputSchema(): z.ZodObject<Properties<CollectionPublicationInput>> {
	return z.object({
		publicationId: z.string().nullish(),
	});
}

export function CollectionPublishInputSchema(): z.ZodObject<Properties<CollectionPublishInput>> {
	return z.object({
		collectionPublications: z.array(z.lazy(() => CollectionPublicationInputSchema())),
		id: z.string(),
	});
}

export function CollectionRuleInputSchema(): z.ZodObject<Properties<CollectionRuleInput>> {
	return z.object({
		column: CollectionRuleColumnSchema,
		condition: z.string(),
		relation: CollectionRuleRelationSchema,
	});
}

export function CollectionRuleSetInputSchema(): z.ZodObject<Properties<CollectionRuleSetInput>> {
	return z.object({
		appliedDisjunctively: z.boolean(),
		rules: z.array(z.lazy(() => CollectionRuleInputSchema())).nullish(),
	});
}

export function CollectionUnpublishInputSchema(): z.ZodObject<Properties<CollectionUnpublishInput>> {
	return z.object({
		collectionPublications: z.array(z.lazy(() => CollectionPublicationInputSchema())),
		id: z.string(),
	});
}

export function CompanyAddressInputSchema(): z.ZodObject<Properties<CompanyAddressInput>> {
	return z.object({
		address1: z.string().nullish(),
		address2: z.string().nullish(),
		city: z.string().nullish(),
		countryCode: CountryCodeSchema.nullish(),
		phone: z.string().nullish(),
		recipient: z.string().nullish(),
		zip: z.string().nullish(),
		zoneCode: z.string().nullish(),
	});
}

export function CompanyContactInputSchema(): z.ZodObject<Properties<CompanyContactInput>> {
	return z.object({
		email: z.string().nullish(),
		firstName: z.string().nullish(),
		lastName: z.string().nullish(),
		locale: z.string().nullish(),
		phone: z.string().nullish(),
		title: z.string().nullish(),
	});
}

export function CompanyContactRoleAssignSchema(): z.ZodObject<Properties<CompanyContactRoleAssign>> {
	return z.object({
		companyContactRoleId: z.string(),
		companyLocationId: z.string(),
	});
}

export function CompanyCreateInputSchema(): z.ZodObject<Properties<CompanyCreateInput>> {
	return z.object({
		company: z.lazy(() => CompanyInputSchema()),
		companyContact: z.lazy(() => CompanyContactInputSchema().nullish()),
		companyLocation: z.lazy(() => CompanyLocationInputSchema().nullish()),
	});
}

export function CompanyInputSchema(): z.ZodObject<Properties<CompanyInput>> {
	return z.object({
		customerSince: definedNonNullAnySchema.nullish(),
		externalId: z.string().nullish(),
		name: z.string().nullish(),
		note: z.string().nullish(),
	});
}

export function CompanyLocationInputSchema(): z.ZodObject<Properties<CompanyLocationInput>> {
	return z.object({
		billingAddress: z.lazy(() => CompanyAddressInputSchema().nullish()),
		billingSameAsShipping: z.boolean().nullish(),
		buyerExperienceConfiguration: z.lazy(() => BuyerExperienceConfigurationInputSchema().nullish()),
		externalId: z.string().nullish(),
		locale: z.string().nullish(),
		name: z.string().nullish(),
		note: z.string().nullish(),
		phone: z.string().nullish(),
		shippingAddress: z.lazy(() => CompanyAddressInputSchema().nullish()),
		taxExemptions: z.array(TaxExemptionSchema).nullish(),
		taxRegistrationId: z.string().nullish(),
	});
}

export function CompanyLocationRoleAssignSchema(): z.ZodObject<Properties<CompanyLocationRoleAssign>> {
	return z.object({
		companyContactId: z.string(),
		companyContactRoleId: z.string(),
	});
}

export function CompanyLocationUpdateInputSchema(): z.ZodObject<Properties<CompanyLocationUpdateInput>> {
	return z.object({
		buyerExperienceConfiguration: z.lazy(() => BuyerExperienceConfigurationInputSchema().nullish()),
		externalId: z.string().nullish(),
		locale: z.string().nullish(),
		name: z.string().nullish(),
		note: z.string().nullish(),
		phone: z.string().nullish(),
	});
}

export function ContextualPricingContextSchema(): z.ZodObject<Properties<ContextualPricingContext>> {
	return z.object({
		companyLocationId: z.string().nullish(),
		country: CountryCodeSchema.nullish(),
	});
}

export function CountryHarmonizedSystemCodeInputSchema(): z.ZodObject<Properties<CountryHarmonizedSystemCodeInput>> {
	return z.object({
		countryCode: CountryCodeSchema,
		harmonizedSystemCode: z.string(),
	});
}

export function CreateMediaInputSchema(): z.ZodObject<Properties<CreateMediaInput>> {
	return z.object({
		alt: z.string().nullish(),
		mediaContentType: MediaContentTypeSchema,
		originalSource: z.string(),
	});
}

export function CustomerDeleteInputSchema(): z.ZodObject<Properties<CustomerDeleteInput>> {
	return z.object({
		id: z.string(),
	});
}

export function CustomerEmailMarketingConsentInputSchema(): z.ZodObject<
	Properties<CustomerEmailMarketingConsentInput>
> {
	return z.object({
		consentUpdatedAt: definedNonNullAnySchema.nullish(),
		marketingOptInLevel: CustomerMarketingOptInLevelSchema.nullish(),
		marketingState: CustomerEmailMarketingStateSchema,
	});
}

export function CustomerEmailMarketingConsentUpdateInputSchema(): z.ZodObject<
	Properties<CustomerEmailMarketingConsentUpdateInput>
> {
	return z.object({
		customerId: z.string(),
		emailMarketingConsent: z.lazy(() => CustomerEmailMarketingConsentInputSchema()),
	});
}

export function CustomerInputSchema(): z.ZodObject<Properties<CustomerInput>> {
	return z.object({
		addresses: z.array(z.lazy(() => MailingAddressInputSchema())).nullish(),
		email: z.string().nullish(),
		emailMarketingConsent: z.lazy(() => CustomerEmailMarketingConsentInputSchema().nullish()),
		firstName: z.string().nullish(),
		id: z.string().nullish(),
		lastName: z.string().nullish(),
		locale: z.string().nullish(),
		metafields: z.array(z.lazy(() => MetafieldInputSchema())).nullish(),
		note: z.string().nullish(),
		phone: z.string().nullish(),
		smsMarketingConsent: z.lazy(() => CustomerSmsMarketingConsentInputSchema().nullish()),
		tags: z.array(z.string()).nullish(),
		taxExempt: z.boolean().nullish(),
		taxExemptions: z.array(TaxExemptionSchema).nullish(),
	});
}

export function CustomerPaymentMethodRemoteInputSchema(): z.ZodObject<Properties<CustomerPaymentMethodRemoteInput>> {
	return z.object({
		authorizeNetCustomerPaymentProfile: z.lazy(() =>
			RemoteAuthorizeNetCustomerPaymentProfileInputSchema().nullish()
		),
		braintreePaymentMethod: z.lazy(() => RemoteBraintreePaymentMethodInputSchema().nullish()),
		stripePaymentMethod: z.lazy(() => RemoteStripePaymentMethodInputSchema().nullish()),
	});
}

export function CustomerSmsMarketingConsentInputSchema(): z.ZodObject<Properties<CustomerSmsMarketingConsentInput>> {
	return z.object({
		consentUpdatedAt: definedNonNullAnySchema.nullish(),
		marketingOptInLevel: CustomerMarketingOptInLevelSchema.nullish(),
		marketingState: CustomerSmsMarketingStateSchema,
	});
}

export function CustomerSmsMarketingConsentUpdateInputSchema(): z.ZodObject<
	Properties<CustomerSmsMarketingConsentUpdateInput>
> {
	return z.object({
		customerId: z.string(),
		smsMarketingConsent: z.lazy(() => CustomerSmsMarketingConsentInputSchema()),
	});
}

export function DelegateAccessTokenInputSchema(): z.ZodObject<Properties<DelegateAccessTokenInput>> {
	return z.object({
		delegateAccessScope: z.array(z.string()),
		expiresIn: z.number().nullish(),
	});
}

export function DeliveryCountryInputSchema(): z.ZodObject<Properties<DeliveryCountryInput>> {
	return z.object({
		code: CountryCodeSchema.nullish(),
		includeAllProvinces: z.boolean().nullish(),
		provinces: z.array(z.lazy(() => DeliveryProvinceInputSchema())).nullish(),
		restOfWorld: z.boolean().nullish(),
	});
}

export function DeliveryLocationGroupZoneInputSchema(): z.ZodObject<Properties<DeliveryLocationGroupZoneInput>> {
	return z.object({
		countries: z.array(z.lazy(() => DeliveryCountryInputSchema())).nullish(),
		id: z.string().nullish(),
		methodDefinitionsToCreate: z.array(z.lazy(() => DeliveryMethodDefinitionInputSchema())).nullish(),
		methodDefinitionsToUpdate: z.array(z.lazy(() => DeliveryMethodDefinitionInputSchema())).nullish(),
		name: z.string().nullish(),
	});
}

export function DeliveryMethodDefinitionInputSchema(): z.ZodObject<Properties<DeliveryMethodDefinitionInput>> {
	return z.object({
		active: z.boolean().nullish(),
		conditionsToUpdate: z.array(z.lazy(() => DeliveryUpdateConditionInputSchema())).nullish(),
		description: z.string().nullish(),
		id: z.string().nullish(),
		name: z.string().nullish(),
		participant: z.lazy(() => DeliveryParticipantInputSchema().nullish()),
		priceConditionsToCreate: z.array(z.lazy(() => DeliveryPriceConditionInputSchema())).nullish(),
		rateDefinition: z.lazy(() => DeliveryRateDefinitionInputSchema().nullish()),
		weightConditionsToCreate: z.array(z.lazy(() => DeliveryWeightConditionInputSchema())).nullish(),
	});
}

export function DeliveryParticipantInputSchema(): z.ZodObject<Properties<DeliveryParticipantInput>> {
	return z.object({
		adaptToNewServices: z.boolean().nullish(),
		carrierServiceId: z.string().nullish(),
		fixedFee: z.lazy(() => MoneyInputSchema().nullish()),
		id: z.string().nullish(),
		participantServices: z.array(z.lazy(() => DeliveryParticipantServiceInputSchema())).nullish(),
		percentageOfRateFee: z.number().nullish(),
	});
}

export function DeliveryParticipantServiceInputSchema(): z.ZodObject<Properties<DeliveryParticipantServiceInput>> {
	return z.object({
		active: z.boolean(),
		name: z.string(),
	});
}

export function DeliveryPriceConditionInputSchema(): z.ZodObject<Properties<DeliveryPriceConditionInput>> {
	return z.object({
		criteria: z.lazy(() => MoneyInputSchema().nullish()),
		operator: DeliveryConditionOperatorSchema.nullish(),
	});
}

export function DeliveryProfileInputSchema(): z.ZodObject<Properties<DeliveryProfileInput>> {
	return z.object({
		conditionsToDelete: z.array(z.string()).nullish(),
		locationGroupsToCreate: z.array(z.lazy(() => DeliveryProfileLocationGroupInputSchema())).nullish(),
		locationGroupsToDelete: z.array(z.string()).nullish(),
		locationGroupsToUpdate: z.array(z.lazy(() => DeliveryProfileLocationGroupInputSchema())).nullish(),
		methodDefinitionsToDelete: z.array(z.string()).nullish(),
		name: z.string().nullish(),
		profileLocationGroups: z.array(z.lazy(() => DeliveryProfileLocationGroupInputSchema())).nullish(),
		sellingPlanGroupsToAssociate: z.array(z.string()).nullish(),
		sellingPlanGroupsToDissociate: z.array(z.string()).nullish(),
		variantsToAssociate: z.array(z.string()).nullish(),
		variantsToDissociate: z.array(z.string()).nullish(),
		zonesToDelete: z.array(z.string()).nullish(),
	});
}

export function DeliveryProfileLocationGroupInputSchema(): z.ZodObject<Properties<DeliveryProfileLocationGroupInput>> {
	return z.object({
		id: z.string().nullish(),
		locations: z.array(z.string()).nullish(),
		zonesToCreate: z.array(z.lazy(() => DeliveryLocationGroupZoneInputSchema())).nullish(),
		zonesToUpdate: z.array(z.lazy(() => DeliveryLocationGroupZoneInputSchema())).nullish(),
	});
}

export function DeliveryProvinceInputSchema(): z.ZodObject<Properties<DeliveryProvinceInput>> {
	return z.object({
		code: z.string(),
	});
}

export function DeliveryRateDefinitionInputSchema(): z.ZodObject<Properties<DeliveryRateDefinitionInput>> {
	return z.object({
		id: z.string().nullish(),
		price: z.lazy(() => MoneyInputSchema()),
	});
}

export function DeliverySettingInputSchema(): z.ZodObject<Properties<DeliverySettingInput>> {
	return z.object({
		legacyModeProfiles: z.boolean().nullish(),
	});
}

export function DeliveryUpdateConditionInputSchema(): z.ZodObject<Properties<DeliveryUpdateConditionInput>> {
	return z.object({
		criteria: z.number().nullish(),
		criteriaUnit: z.string().nullish(),
		field: DeliveryConditionFieldSchema.nullish(),
		id: z.string(),
		operator: DeliveryConditionOperatorSchema.nullish(),
	});
}

export function DeliveryWeightConditionInputSchema(): z.ZodObject<Properties<DeliveryWeightConditionInput>> {
	return z.object({
		criteria: z.lazy(() => WeightInputSchema().nullish()),
		operator: DeliveryConditionOperatorSchema.nullish(),
	});
}

export function DiscountAmountInputSchema(): z.ZodObject<Properties<DiscountAmountInput>> {
	return z.object({
		amount: definedNonNullAnySchema.nullish(),
		appliesOnEachItem: z.boolean().nullish(),
	});
}

export function DiscountAutomaticAppInputSchema(): z.ZodObject<Properties<DiscountAutomaticAppInput>> {
	return z.object({
		combinesWith: z.lazy(() => DiscountCombinesWithInputSchema().nullish()),
		endsAt: definedNonNullAnySchema.nullish(),
		functionId: z.string().nullish(),
		metafields: z.array(z.lazy(() => MetafieldInputSchema())).nullish(),
		startsAt: definedNonNullAnySchema.nullish(),
		title: z.string().nullish(),
	});
}

export function DiscountAutomaticBasicInputSchema(): z.ZodObject<Properties<DiscountAutomaticBasicInput>> {
	return z.object({
		combinesWith: z.lazy(() => DiscountCombinesWithInputSchema().nullish()),
		customerGets: z.lazy(() => DiscountCustomerGetsInputSchema().nullish()),
		endsAt: definedNonNullAnySchema.nullish(),
		minimumRequirement: z.lazy(() => DiscountMinimumRequirementInputSchema().nullish()),
		startsAt: definedNonNullAnySchema.nullish(),
		title: z.string().nullish(),
	});
}

export function DiscountAutomaticBxgyInputSchema(): z.ZodObject<Properties<DiscountAutomaticBxgyInput>> {
	return z.object({
		combinesWith: z.lazy(() => DiscountCombinesWithInputSchema().nullish()),
		customerBuys: z.lazy(() => DiscountCustomerBuysInputSchema().nullish()),
		customerGets: z.lazy(() => DiscountCustomerGetsInputSchema().nullish()),
		endsAt: definedNonNullAnySchema.nullish(),
		startsAt: definedNonNullAnySchema.nullish(),
		title: z.string().nullish(),
		usesPerOrderLimit: definedNonNullAnySchema.nullish(),
	});
}

export function DiscountCodeAppInputSchema(): z.ZodObject<Properties<DiscountCodeAppInput>> {
	return z.object({
		appliesOncePerCustomer: z.boolean().nullish(),
		code: z.string().nullish(),
		combinesWith: z.lazy(() => DiscountCombinesWithInputSchema().nullish()),
		customerSelection: z.lazy(() => DiscountCustomerSelectionInputSchema().nullish()),
		endsAt: definedNonNullAnySchema.nullish(),
		functionId: z.string().nullish(),
		metafields: z.array(z.lazy(() => MetafieldInputSchema())).nullish(),
		startsAt: definedNonNullAnySchema.nullish(),
		title: z.string().nullish(),
		usageLimit: z.number().nullish(),
	});
}

export function DiscountCodeBasicInputSchema(): z.ZodObject<Properties<DiscountCodeBasicInput>> {
	return z.object({
		appliesOncePerCustomer: z.boolean().nullish(),
		code: z.string().nullish(),
		combinesWith: z.lazy(() => DiscountCombinesWithInputSchema().nullish()),
		customerGets: z.lazy(() => DiscountCustomerGetsInputSchema().nullish()),
		customerSelection: z.lazy(() => DiscountCustomerSelectionInputSchema().nullish()),
		endsAt: definedNonNullAnySchema.nullish(),
		minimumRequirement: z.lazy(() => DiscountMinimumRequirementInputSchema().nullish()),
		recurringCycleLimit: z.number().nullish(),
		startsAt: definedNonNullAnySchema.nullish(),
		title: z.string().nullish(),
		usageLimit: z.number().nullish(),
	});
}

export function DiscountCodeBxgyInputSchema(): z.ZodObject<Properties<DiscountCodeBxgyInput>> {
	return z.object({
		appliesOncePerCustomer: z.boolean().nullish(),
		code: z.string().nullish(),
		combinesWith: z.lazy(() => DiscountCombinesWithInputSchema().nullish()),
		customerBuys: z.lazy(() => DiscountCustomerBuysInputSchema().nullish()),
		customerGets: z.lazy(() => DiscountCustomerGetsInputSchema().nullish()),
		customerSelection: z.lazy(() => DiscountCustomerSelectionInputSchema().nullish()),
		endsAt: definedNonNullAnySchema.nullish(),
		startsAt: definedNonNullAnySchema.nullish(),
		title: z.string().nullish(),
		usageLimit: z.number().nullish(),
		usesPerOrderLimit: z.number().nullish(),
	});
}

export function DiscountCodeFreeShippingInputSchema(): z.ZodObject<Properties<DiscountCodeFreeShippingInput>> {
	return z.object({
		appliesOnOneTimePurchase: z.boolean().nullish(),
		appliesOnSubscription: z.boolean().nullish(),
		appliesOncePerCustomer: z.boolean().nullish(),
		code: z.string().nullish(),
		combinesWith: z.lazy(() => DiscountCombinesWithInputSchema().nullish()),
		customerSelection: z.lazy(() => DiscountCustomerSelectionInputSchema().nullish()),
		destination: z.lazy(() => DiscountShippingDestinationSelectionInputSchema().nullish()),
		endsAt: definedNonNullAnySchema.nullish(),
		maximumShippingPrice: definedNonNullAnySchema.nullish(),
		minimumRequirement: z.lazy(() => DiscountMinimumRequirementInputSchema().nullish()),
		recurringCycleLimit: z.number().nullish(),
		startsAt: definedNonNullAnySchema.nullish(),
		title: z.string().nullish(),
		usageLimit: z.number().nullish(),
	});
}

export function DiscountCollectionsInputSchema(): z.ZodObject<Properties<DiscountCollectionsInput>> {
	return z.object({
		add: z.array(z.string()).nullish(),
		remove: z.array(z.string()).nullish(),
	});
}

export function DiscountCombinesWithInputSchema(): z.ZodObject<Properties<DiscountCombinesWithInput>> {
	return z.object({
		orderDiscounts: z.boolean().nullish(),
		productDiscounts: z.boolean().nullish(),
		shippingDiscounts: z.boolean().nullish(),
	});
}

export function DiscountCountriesInputSchema(): z.ZodObject<Properties<DiscountCountriesInput>> {
	return z.object({
		add: z.array(CountryCodeSchema).nullish(),
		includeRestOfWorld: z.boolean().nullish(),
		remove: z.array(CountryCodeSchema).nullish(),
	});
}

export function DiscountCustomerBuysInputSchema(): z.ZodObject<Properties<DiscountCustomerBuysInput>> {
	return z.object({
		items: z.lazy(() => DiscountItemsInputSchema().nullish()),
		value: z.lazy(() => DiscountCustomerBuysValueInputSchema().nullish()),
	});
}

export function DiscountCustomerBuysValueInputSchema(): z.ZodObject<Properties<DiscountCustomerBuysValueInput>> {
	return z.object({
		amount: definedNonNullAnySchema.nullish(),
		quantity: definedNonNullAnySchema.nullish(),
	});
}

export function DiscountCustomerGetsInputSchema(): z.ZodObject<Properties<DiscountCustomerGetsInput>> {
	return z.object({
		appliesOnOneTimePurchase: z.boolean().nullish(),
		appliesOnSubscription: z.boolean().nullish(),
		items: z.lazy(() => DiscountItemsInputSchema().nullish()),
		value: z.lazy(() => DiscountCustomerGetsValueInputSchema().nullish()),
	});
}

export function DiscountCustomerGetsValueInputSchema(): z.ZodObject<Properties<DiscountCustomerGetsValueInput>> {
	return z.object({
		discountAmount: z.lazy(() => DiscountAmountInputSchema().nullish()),
		discountOnQuantity: z.lazy(() => DiscountOnQuantityInputSchema().nullish()),
		percentage: z.number().nullish(),
	});
}

export function DiscountCustomerSegmentsInputSchema(): z.ZodObject<Properties<DiscountCustomerSegmentsInput>> {
	return z.object({
		add: z.array(z.string()).nullish(),
		remove: z.array(z.string()).nullish(),
	});
}

export function DiscountCustomerSelectionInputSchema(): z.ZodObject<Properties<DiscountCustomerSelectionInput>> {
	return z.object({
		all: z.boolean().nullish(),
		customerSegments: z.lazy(() => DiscountCustomerSegmentsInputSchema().nullish()),
		customers: z.lazy(() => DiscountCustomersInputSchema().nullish()),
	});
}

export function DiscountCustomersInputSchema(): z.ZodObject<Properties<DiscountCustomersInput>> {
	return z.object({
		add: z.array(z.string()).nullish(),
		remove: z.array(z.string()).nullish(),
	});
}

export function DiscountEffectInputSchema(): z.ZodObject<Properties<DiscountEffectInput>> {
	return z.object({
		percentage: z.number().nullish(),
	});
}

export function DiscountItemsInputSchema(): z.ZodObject<Properties<DiscountItemsInput>> {
	return z.object({
		all: z.boolean().nullish(),
		collections: z.lazy(() => DiscountCollectionsInputSchema().nullish()),
		products: z.lazy(() => DiscountProductsInputSchema().nullish()),
	});
}

export function DiscountMinimumQuantityInputSchema(): z.ZodObject<Properties<DiscountMinimumQuantityInput>> {
	return z.object({
		greaterThanOrEqualToQuantity: definedNonNullAnySchema.nullish(),
	});
}

export function DiscountMinimumRequirementInputSchema(): z.ZodObject<Properties<DiscountMinimumRequirementInput>> {
	return z.object({
		quantity: z.lazy(() => DiscountMinimumQuantityInputSchema().nullish()),
		subtotal: z.lazy(() => DiscountMinimumSubtotalInputSchema().nullish()),
	});
}

export function DiscountMinimumSubtotalInputSchema(): z.ZodObject<Properties<DiscountMinimumSubtotalInput>> {
	return z.object({
		greaterThanOrEqualToSubtotal: definedNonNullAnySchema.nullish(),
	});
}

export function DiscountOnQuantityInputSchema(): z.ZodObject<Properties<DiscountOnQuantityInput>> {
	return z.object({
		effect: z.lazy(() => DiscountEffectInputSchema().nullish()),
		quantity: definedNonNullAnySchema.nullish(),
	});
}

export function DiscountProductsInputSchema(): z.ZodObject<Properties<DiscountProductsInput>> {
	return z.object({
		productVariantsToAdd: z.array(z.string()).nullish(),
		productVariantsToRemove: z.array(z.string()).nullish(),
		productsToAdd: z.array(z.string()).nullish(),
		productsToRemove: z.array(z.string()).nullish(),
	});
}

export function DiscountRedeemCodeInputSchema(): z.ZodObject<Properties<DiscountRedeemCodeInput>> {
	return z.object({
		code: z.string(),
	});
}

export function DiscountShippingDestinationSelectionInputSchema(): z.ZodObject<
	Properties<DiscountShippingDestinationSelectionInput>
> {
	return z.object({
		all: z.boolean().nullish(),
		countries: z.lazy(() => DiscountCountriesInputSchema().nullish()),
	});
}

export function DraftOrderAppliedDiscountInputSchema(): z.ZodObject<Properties<DraftOrderAppliedDiscountInput>> {
	return z.object({
		amount: definedNonNullAnySchema.nullish(),
		description: z.string().nullish(),
		title: z.string().nullish(),
		value: z.number(),
		valueType: DraftOrderAppliedDiscountTypeSchema,
	});
}

export function DraftOrderDeleteInputSchema(): z.ZodObject<Properties<DraftOrderDeleteInput>> {
	return z.object({
		id: z.string(),
	});
}

export function DraftOrderInputSchema(): z.ZodObject<Properties<DraftOrderInput>> {
	return z.object({
		appliedDiscount: z.lazy(() => DraftOrderAppliedDiscountInputSchema().nullish()),
		billingAddress: z.lazy(() => MailingAddressInputSchema().nullish()),
		customAttributes: z.array(z.lazy(() => AttributeInputSchema())).nullish(),
		email: z.string().nullish(),
		lineItems: z.array(z.lazy(() => DraftOrderLineItemInputSchema())).nullish(),
		localizationExtensions: z.array(z.lazy(() => LocalizationExtensionInputSchema())).nullish(),
		marketRegionCountryCode: CountryCodeSchema.nullish(),
		metafields: z.array(z.lazy(() => MetafieldInputSchema())).nullish(),
		note: z.string().nullish(),
		paymentTerms: z.lazy(() => PaymentTermsInputSchema().nullish()),
		phone: z.string().nullish(),
		presentmentCurrencyCode: CurrencyCodeSchema.nullish(),
		purchasingEntity: z.lazy(() => PurchasingEntityInputSchema().nullish()),
		reserveInventoryUntil: definedNonNullAnySchema.nullish(),
		shippingAddress: z.lazy(() => MailingAddressInputSchema().nullish()),
		shippingLine: z.lazy(() => ShippingLineInputSchema().nullish()),
		sourceName: z.string().nullish(),
		tags: z.array(z.string()).nullish(),
		taxExempt: z.boolean().nullish(),
		useCustomerDefaultAddress: z.boolean().nullish(),
		visibleToCustomer: z.boolean().nullish(),
	});
}

export function DraftOrderLineItemInputSchema(): z.ZodObject<Properties<DraftOrderLineItemInput>> {
	return z.object({
		appliedDiscount: z.lazy(() => DraftOrderAppliedDiscountInputSchema().nullish()),
		customAttributes: z.array(z.lazy(() => AttributeInputSchema())).nullish(),
		originalUnitPrice: definedNonNullAnySchema.nullish(),
		quantity: z.number(),
		requiresShipping: z.boolean().nullish(),
		sku: z.string().nullish(),
		taxable: z.boolean().nullish(),
		title: z.string().nullish(),
		variantId: z.string().nullish(),
		weight: z.lazy(() => WeightInputSchema().nullish()),
	});
}

export function EmailInputSchema(): z.ZodObject<Properties<EmailInput>> {
	return z.object({
		bcc: z.array(z.string()).nullish(),
		body: z.string().nullish(),
		customMessage: z.string().nullish(),
		from: z.string().nullish(),
		subject: z.string().nullish(),
		to: z.string().nullish(),
	});
}

export function EventBridgeWebhookSubscriptionInputSchema(): z.ZodObject<
	Properties<EventBridgeWebhookSubscriptionInput>
> {
	return z.object({
		arn: definedNonNullAnySchema.nullish(),
		format: WebhookSubscriptionFormatSchema.nullish(),
		includeFields: z.array(z.string()).nullish(),
		metafieldNamespaces: z.array(z.string()).nullish(),
	});
}

export function FileCreateInputSchema(): z.ZodObject<Properties<FileCreateInput>> {
	return z.object({
		alt: z.string().nullish(),
		contentType: FileContentTypeSchema.nullish(),
		originalSource: z.string(),
	});
}

export function FileUpdateInputSchema(): z.ZodObject<Properties<FileUpdateInput>> {
	return z.object({
		alt: z.string().nullish(),
		id: z.string(),
		previewImageSource: z.string().nullish(),
	});
}

export function FulfillmentOrderHoldInputSchema(): z.ZodObject<Properties<FulfillmentOrderHoldInput>> {
	return z.object({
		notifyMerchant: z.boolean().nullish(),
		reason: FulfillmentHoldReasonSchema,
		reasonNotes: z.string().nullish(),
	});
}

export function FulfillmentOrderLineItemInputSchema(): z.ZodObject<Properties<FulfillmentOrderLineItemInput>> {
	return z.object({
		id: z.string(),
		quantity: z.number(),
	});
}

export function FulfillmentOrderLineItemsInputSchema(): z.ZodObject<Properties<FulfillmentOrderLineItemsInput>> {
	return z.object({
		fulfillmentOrderId: z.string(),
		fulfillmentOrderLineItems: z.array(z.lazy(() => FulfillmentOrderLineItemInputSchema())).nullish(),
	});
}

export function FulfillmentOriginAddressInputSchema(): z.ZodObject<Properties<FulfillmentOriginAddressInput>> {
	return z.object({
		address1: z.string().nullish(),
		address2: z.string().nullish(),
		city: z.string().nullish(),
		countryCode: z.string(),
		provinceCode: z.string().nullish(),
		zip: z.string().nullish(),
	});
}

export function FulfillmentTrackingInputSchema(): z.ZodObject<Properties<FulfillmentTrackingInput>> {
	return z.object({
		company: z.string().nullish(),
		number: z.string().nullish(),
		numbers: z.array(z.string()).nullish(),
		url: definedNonNullAnySchema.nullish(),
		urls: z.array(definedNonNullAnySchema).nullish(),
	});
}

export function FulfillmentV2InputSchema(): z.ZodObject<Properties<FulfillmentV2Input>> {
	return z.object({
		lineItemsByFulfillmentOrder: z.array(z.lazy(() => FulfillmentOrderLineItemsInputSchema())),
		notifyCustomer: z.boolean().nullish(),
		originAddress: z.lazy(() => FulfillmentOriginAddressInputSchema().nullish()),
		trackingInfo: z.lazy(() => FulfillmentTrackingInputSchema().nullish()),
	});
}

export function GiftCardCreateInputSchema(): z.ZodObject<Properties<GiftCardCreateInput>> {
	return z.object({
		code: z.string().nullish(),
		customerId: z.string().nullish(),
		expiresOn: definedNonNullAnySchema.nullish(),
		initialValue: definedNonNullAnySchema,
		note: z.string().nullish(),
		templateSuffix: z.string().nullish(),
	});
}

export function GiftCardUpdateInputSchema(): z.ZodObject<Properties<GiftCardUpdateInput>> {
	return z.object({
		customerId: z.string().nullish(),
		expiresOn: definedNonNullAnySchema.nullish(),
		note: z.string().nullish(),
		templateSuffix: z.string().nullish(),
	});
}

export function ImageInputSchema(): z.ZodObject<Properties<ImageInput>> {
	return z.object({
		altText: z.string().nullish(),
		id: z.string().nullish(),
		src: z.string().nullish(),
	});
}

export function ImageTransformInputSchema(): z.ZodObject<Properties<ImageTransformInput>> {
	return z.object({
		crop: CropRegionSchema.nullish(),
		maxHeight: z.number().nullish(),
		maxWidth: z.number().nullish(),
		preferredContentType: ImageContentTypeSchema.nullish(),
		scale: z.number().nullish(),
	});
}

export function IncomingRequestLineItemInputSchema(): z.ZodObject<Properties<IncomingRequestLineItemInput>> {
	return z.object({
		fulfillmentOrderLineItemId: z.string(),
		message: z.string().nullish(),
	});
}

export function InventoryAdjustItemInputSchema(): z.ZodObject<Properties<InventoryAdjustItemInput>> {
	return z.object({
		availableDelta: z.number(),
		inventoryItemId: z.string(),
	});
}

export function InventoryAdjustQuantityInputSchema(): z.ZodObject<Properties<InventoryAdjustQuantityInput>> {
	return z.object({
		availableDelta: z.number(),
		inventoryLevelId: z.string(),
	});
}

export function InventoryBulkToggleActivationInputSchema(): z.ZodObject<
	Properties<InventoryBulkToggleActivationInput>
> {
	return z.object({
		activate: z.boolean(),
		locationId: z.string(),
	});
}

export function InventoryItemInputSchema(): z.ZodObject<Properties<InventoryItemInput>> {
	return z.object({
		cost: definedNonNullAnySchema.nullish(),
		tracked: z.boolean().nullish(),
	});
}

export function InventoryItemUpdateInputSchema(): z.ZodObject<Properties<InventoryItemUpdateInput>> {
	return z.object({
		cost: definedNonNullAnySchema.nullish(),
		countryCodeOfOrigin: CountryCodeSchema.nullish(),
		countryHarmonizedSystemCodes: z.array(z.lazy(() => CountryHarmonizedSystemCodeInputSchema())).nullish(),
		harmonizedSystemCode: z.string().nullish(),
		provinceCodeOfOrigin: z.string().nullish(),
		tracked: z.boolean().nullish(),
	});
}

export function InventoryLevelInputSchema(): z.ZodObject<Properties<InventoryLevelInput>> {
	return z.object({
		availableQuantity: z.number(),
		locationId: z.string(),
	});
}

export function LocalizationExtensionInputSchema(): z.ZodObject<Properties<LocalizationExtensionInput>> {
	return z.object({
		key: LocalizationExtensionKeySchema,
		value: z.string(),
	});
}

export function LocationAddAddressInputSchema(): z.ZodObject<Properties<LocationAddAddressInput>> {
	return z.object({
		address1: z.string().nullish(),
		address2: z.string().nullish(),
		city: z.string().nullish(),
		countryCode: CountryCodeSchema,
		phone: z.string().nullish(),
		provinceCode: z.string().nullish(),
		zip: z.string().nullish(),
	});
}

export function LocationAddInputSchema(): z.ZodObject<Properties<LocationAddInput>> {
	return z.object({
		address: z.lazy(() => LocationAddAddressInputSchema()),
		fulfillsOnlineOrders: z.boolean().nullish(),
		name: z.string(),
	});
}

export function LocationEditAddressInputSchema(): z.ZodObject<Properties<LocationEditAddressInput>> {
	return z.object({
		address1: z.string().nullish(),
		address2: z.string().nullish(),
		city: z.string().nullish(),
		countryCode: CountryCodeSchema.nullish(),
		phone: z.string().nullish(),
		provinceCode: z.string().nullish(),
		zip: z.string().nullish(),
	});
}

export function LocationEditInputSchema(): z.ZodObject<Properties<LocationEditInput>> {
	return z.object({
		address: z.lazy(() => LocationEditAddressInputSchema().nullish()),
		fulfillsOnlineOrders: z.boolean().nullish(),
		name: z.string().nullish(),
	});
}

export function MailingAddressInputSchema(): z.ZodObject<Properties<MailingAddressInput>> {
	return z.object({
		address1: z.string().nullish(),
		address2: z.string().nullish(),
		city: z.string().nullish(),
		company: z.string().nullish(),
		countryCode: CountryCodeSchema.nullish(),
		firstName: z.string().nullish(),
		lastName: z.string().nullish(),
		phone: z.string().nullish(),
		provinceCode: z.string().nullish(),
		zip: z.string().nullish(),
	});
}

export function MarketCreateInputSchema(): z.ZodObject<Properties<MarketCreateInput>> {
	return z.object({
		enabled: z.boolean().nullish(),
		name: z.string(),
		regions: z.array(z.lazy(() => MarketRegionCreateInputSchema())),
	});
}

export function MarketCurrencySettingsUpdateInputSchema(): z.ZodObject<Properties<MarketCurrencySettingsUpdateInput>> {
	return z.object({
		baseCurrency: CurrencyCodeSchema.nullish(),
		localCurrencies: z.boolean().nullish(),
	});
}

export function MarketLocalizationRegisterInputSchema(): z.ZodObject<Properties<MarketLocalizationRegisterInput>> {
	return z.object({
		key: z.string(),
		marketId: z.string(),
		marketLocalizableContentDigest: z.string(),
		value: z.string(),
	});
}

export function MarketRegionCreateInputSchema(): z.ZodObject<Properties<MarketRegionCreateInput>> {
	return z.object({
		countryCode: CountryCodeSchema,
	});
}

export function MarketUpdateInputSchema(): z.ZodObject<Properties<MarketUpdateInput>> {
	return z.object({
		enabled: z.boolean().nullish(),
		name: z.string().nullish(),
	});
}

export function MarketWebPresenceCreateInputSchema(): z.ZodObject<Properties<MarketWebPresenceCreateInput>> {
	return z.object({
		alternateLocales: z.array(z.string()).nullish(),
		defaultLocale: z.string(),
		domainId: z.string().nullish(),
		subfolderSuffix: z.string().nullish(),
	});
}

export function MarketWebPresenceUpdateInputSchema(): z.ZodObject<Properties<MarketWebPresenceUpdateInput>> {
	return z.object({
		alternateLocales: z.array(z.string()).nullish(),
		defaultLocale: z.string().nullish(),
		domainId: z.string().nullish(),
		subfolderSuffix: z.string().nullish(),
	});
}

export function MarketingActivityBudgetInputSchema(): z.ZodObject<Properties<MarketingActivityBudgetInput>> {
	return z.object({
		budgetType: MarketingBudgetBudgetTypeSchema.nullish(),
		total: z.lazy(() => MoneyInputSchema().nullish()),
	});
}

export function MarketingActivityCreateExternalInputSchema(): z.ZodObject<
	Properties<MarketingActivityCreateExternalInput>
> {
	return z.object({
		adSpend: z.lazy(() => MoneyInputSchema().nullish()),
		budget: z.lazy(() => MarketingActivityBudgetInputSchema().nullish()),
		channel: MarketingChannelSchema,
		end: definedNonNullAnySchema.nullish(),
		referringDomain: z.string().nullish(),
		remoteId: z.string().nullish(),
		remotePreviewImageUrl: definedNonNullAnySchema.nullish(),
		remoteUrl: definedNonNullAnySchema,
		scheduledEnd: definedNonNullAnySchema.nullish(),
		scheduledStart: definedNonNullAnySchema.nullish(),
		start: definedNonNullAnySchema.nullish(),
		tactic: MarketingTacticSchema,
		title: z.string(),
		utm: z.lazy(() => UtmInputSchema()),
	});
}

export function MarketingActivityCreateInputSchema(): z.ZodObject<Properties<MarketingActivityCreateInput>> {
	return z.object({
		budget: z.lazy(() => MarketingActivityBudgetInputSchema().nullish()),
		context: z.string().nullish(),
		formData: z.string().nullish(),
		marketingActivityExtensionId: z.string(),
		marketingActivityTitle: z.string().nullish(),
		status: MarketingActivityStatusSchema,
		utm: z.lazy(() => UtmInputSchema().nullish()),
	});
}

export function MarketingActivityUpdateExternalInputSchema(): z.ZodObject<
	Properties<MarketingActivityUpdateExternalInput>
> {
	return z.object({
		adSpend: z.lazy(() => MoneyInputSchema().nullish()),
		budget: z.lazy(() => MarketingActivityBudgetInputSchema().nullish()),
		channel: MarketingChannelSchema.nullish(),
		end: definedNonNullAnySchema.nullish(),
		referringDomain: z.string().nullish(),
		remotePreviewImageUrl: definedNonNullAnySchema.nullish(),
		remoteUrl: definedNonNullAnySchema.nullish(),
		scheduledEnd: definedNonNullAnySchema.nullish(),
		scheduledStart: definedNonNullAnySchema.nullish(),
		start: definedNonNullAnySchema.nullish(),
		tactic: MarketingTacticSchema.nullish(),
		title: z.string().nullish(),
		utm: z.lazy(() => UtmInputSchema().nullish()),
	});
}

export function MarketingActivityUpdateInputSchema(): z.ZodObject<Properties<MarketingActivityUpdateInput>> {
	return z.object({
		budget: z.lazy(() => MarketingActivityBudgetInputSchema().nullish()),
		errors: definedNonNullAnySchema.nullish(),
		formData: z.string().nullish(),
		id: z.string(),
		marketedResources: z.array(z.string()).nullish(),
		marketingRecommendationId: z.string().nullish(),
		status: MarketingActivityStatusSchema.nullish(),
		targetStatus: MarketingActivityStatusSchema.nullish(),
		title: z.string().nullish(),
		utm: z.lazy(() => UtmInputSchema().nullish()),
	});
}

export function MarketingEngagementInputSchema(): z.ZodObject<Properties<MarketingEngagementInput>> {
	return z.object({
		adSpend: z.lazy(() => MoneyInputSchema().nullish()),
		clicksCount: z.number().nullish(),
		commentsCount: z.number().nullish(),
		complaintsCount: z.number().nullish(),
		failsCount: z.number().nullish(),
		favoritesCount: z.number().nullish(),
		fetchedAt: definedNonNullAnySchema.nullish(),
		impressionsCount: z.number().nullish(),
		isCumulative: z.boolean().nullish(),
		occurredOn: definedNonNullAnySchema,
		sendsCount: z.number().nullish(),
		sharesCount: z.number().nullish(),
		uniqueClicksCount: z.number().nullish(),
		uniqueViewsCount: z.number().nullish(),
		unsubscribesCount: z.number().nullish(),
		utcOffset: definedNonNullAnySchema.nullish(),
		viewsCount: z.number().nullish(),
	});
}

export function MetafieldDefinitionInputSchema(): z.ZodObject<Properties<MetafieldDefinitionInput>> {
	return z.object({
		description: z.string().nullish(),
		key: z.string(),
		name: z.string(),
		namespace: z.string(),
		ownerType: MetafieldOwnerTypeSchema,
		pin: z.boolean().nullish(),
		type: z.string(),
		validations: z.array(z.lazy(() => MetafieldDefinitionValidationInputSchema())).nullish(),
		visibleToStorefrontApi: z.boolean().nullish(),
	});
}

export function MetafieldDefinitionUpdateInputSchema(): z.ZodObject<Properties<MetafieldDefinitionUpdateInput>> {
	return z.object({
		description: z.string().nullish(),
		key: z.string(),
		name: z.string().nullish(),
		namespace: z.string(),
		ownerType: MetafieldOwnerTypeSchema,
		pin: z.boolean().nullish(),
		visibleToStorefrontApi: z.boolean().nullish(),
	});
}

export function MetafieldDefinitionValidationInputSchema(): z.ZodObject<
	Properties<MetafieldDefinitionValidationInput>
> {
	return z.object({
		name: z.string(),
		value: z.string(),
	});
}

export function MetafieldDeleteInputSchema(): z.ZodObject<Properties<MetafieldDeleteInput>> {
	return z.object({
		id: z.string(),
	});
}

export function MetafieldInputSchema(): z.ZodObject<Properties<MetafieldInput>> {
	return z.object({
		id: z.string().nullish(),
		key: z.string().nullish(),
		namespace: z.string().nullish(),
		type: z.string().nullish(),
		value: z.string().nullish(),
	});
}

export function MetafieldStorefrontVisibilityInputSchema(): z.ZodObject<
	Properties<MetafieldStorefrontVisibilityInput>
> {
	return z.object({
		key: z.string(),
		namespace: z.string(),
		ownerType: MetafieldOwnerTypeSchema,
	});
}

export function MetafieldsSetInputSchema(): z.ZodObject<Properties<MetafieldsSetInput>> {
	return z.object({
		key: z.string(),
		namespace: z.string(),
		ownerId: z.string(),
		type: z.string(),
		value: z.string(),
	});
}

export function MoneyInputSchema(): z.ZodObject<Properties<MoneyInput>> {
	return z.object({
		amount: definedNonNullAnySchema,
		currencyCode: CurrencyCodeSchema,
	});
}

export function MoveInputSchema(): z.ZodObject<Properties<MoveInput>> {
	return z.object({
		id: z.string(),
		newPosition: definedNonNullAnySchema,
	});
}

export function OrderCaptureInputSchema(): z.ZodObject<Properties<OrderCaptureInput>> {
	return z.object({
		amount: definedNonNullAnySchema,
		currency: CurrencyCodeSchema.nullish(),
		id: z.string(),
		parentTransactionId: z.string(),
	});
}

export function OrderCloseInputSchema(): z.ZodObject<Properties<OrderCloseInput>> {
	return z.object({
		id: z.string(),
	});
}

export function OrderEditAppliedDiscountInputSchema(): z.ZodObject<Properties<OrderEditAppliedDiscountInput>> {
	return z.object({
		description: z.string().nullish(),
		fixedValue: z.lazy(() => MoneyInputSchema().nullish()),
		percentValue: z.number().nullish(),
	});
}

export function OrderInputSchema(): z.ZodObject<Properties<OrderInput>> {
	return z.object({
		customAttributes: z.array(z.lazy(() => AttributeInputSchema())).nullish(),
		email: z.string().nullish(),
		id: z.string(),
		localizationExtensions: z.array(z.lazy(() => LocalizationExtensionInputSchema())).nullish(),
		metafields: z.array(z.lazy(() => MetafieldInputSchema())).nullish(),
		note: z.string().nullish(),
		shippingAddress: z.lazy(() => MailingAddressInputSchema().nullish()),
		tags: z.array(z.string()).nullish(),
	});
}

export function OrderMarkAsPaidInputSchema(): z.ZodObject<Properties<OrderMarkAsPaidInput>> {
	return z.object({
		id: z.string(),
	});
}

export function OrderOpenInputSchema(): z.ZodObject<Properties<OrderOpenInput>> {
	return z.object({
		id: z.string(),
	});
}

export function OrderTransactionInputSchema(): z.ZodObject<Properties<OrderTransactionInput>> {
	return z.object({
		amount: definedNonNullAnySchema,
		gateway: z.string(),
		kind: OrderTransactionKindSchema,
		orderId: z.string(),
		parentId: z.string().nullish(),
	});
}

export function PaymentScheduleInputSchema(): z.ZodObject<Properties<PaymentScheduleInput>> {
	return z.object({
		dueAt: definedNonNullAnySchema.nullish(),
		issuedAt: definedNonNullAnySchema.nullish(),
	});
}

export function PaymentTermsCreateInputSchema(): z.ZodObject<Properties<PaymentTermsCreateInput>> {
	return z.object({
		paymentSchedules: z.array(z.lazy(() => PaymentScheduleInputSchema())).nullish(),
		paymentTermsTemplateId: z.string(),
	});
}

export function PaymentTermsDeleteInputSchema(): z.ZodObject<Properties<PaymentTermsDeleteInput>> {
	return z.object({
		paymentTermsId: z.string(),
	});
}

export function PaymentTermsInputSchema(): z.ZodObject<Properties<PaymentTermsInput>> {
	return z.object({
		paymentSchedules: z.array(z.lazy(() => PaymentScheduleInputSchema())).nullish(),
		paymentTermsTemplateId: z.string().nullish(),
	});
}

export function PaymentTermsUpdateInputSchema(): z.ZodObject<Properties<PaymentTermsUpdateInput>> {
	return z.object({
		paymentTermsAttributes: z.lazy(() => PaymentTermsInputSchema()),
		paymentTermsId: z.string(),
	});
}

export function PriceListAdjustmentInputSchema(): z.ZodObject<Properties<PriceListAdjustmentInput>> {
	return z.object({
		type: PriceListAdjustmentTypeSchema,
		value: z.number(),
	});
}

export function PriceListContextSchema(): z.ZodObject<Properties<PriceListContext>> {
	return z.object({
		country: CountryCodeSchema.nullish(),
	});
}

export function PriceListContextRuleInputSchema(): z.ZodObject<Properties<PriceListContextRuleInput>> {
	return z.object({
		marketId: z.string().nullish(),
	});
}

export function PriceListCreateInputSchema(): z.ZodObject<Properties<PriceListCreateInput>> {
	return z.object({
		contextRule: z.lazy(() => PriceListContextRuleInputSchema().nullish()),
		currency: CurrencyCodeSchema,
		name: z.string(),
		parent: z.lazy(() => PriceListParentCreateInputSchema()),
	});
}

export function PriceListParentCreateInputSchema(): z.ZodObject<Properties<PriceListParentCreateInput>> {
	return z.object({
		adjustment: z.lazy(() => PriceListAdjustmentInputSchema()),
	});
}

export function PriceListParentUpdateInputSchema(): z.ZodObject<Properties<PriceListParentUpdateInput>> {
	return z.object({
		adjustment: z.lazy(() => PriceListAdjustmentInputSchema()),
	});
}

export function PriceListPriceInputSchema(): z.ZodObject<Properties<PriceListPriceInput>> {
	return z.object({
		compareAtPrice: z.lazy(() => MoneyInputSchema().nullish()),
		price: z.lazy(() => MoneyInputSchema()),
		variantId: z.string(),
	});
}

export function PriceListUpdateInputSchema(): z.ZodObject<Properties<PriceListUpdateInput>> {
	return z.object({
		contextRule: z.lazy(() => PriceListContextRuleInputSchema().nullish()),
		currency: CurrencyCodeSchema.nullish(),
		name: z.string().nullish(),
		parent: z.lazy(() => PriceListParentUpdateInputSchema().nullish()),
	});
}

export function PriceRuleCustomerSelectionInputSchema(): z.ZodObject<Properties<PriceRuleCustomerSelectionInput>> {
	return z.object({
		customerIdsToAdd: z.array(z.string()).nullish(),
		customerIdsToRemove: z.array(z.string()).nullish(),
		forAllCustomers: z.boolean().nullish(),
		segmentIds: z.array(z.string()).nullish(),
	});
}

export function PriceRuleDiscountCodeInputSchema(): z.ZodObject<Properties<PriceRuleDiscountCodeInput>> {
	return z.object({
		code: z.string().nullish(),
	});
}

export function PriceRuleEntitlementToPrerequisiteQuantityRatioInputSchema(): z.ZodObject<
	Properties<PriceRuleEntitlementToPrerequisiteQuantityRatioInput>
> {
	return z.object({
		entitlementQuantity: z.number().nullish(),
		prerequisiteQuantity: z.number().nullish(),
	});
}

export function PriceRuleInputSchema(): z.ZodObject<Properties<PriceRuleInput>> {
	return z.object({
		allocationLimit: z.number().nullish(),
		allocationMethod: PriceRuleAllocationMethodSchema.nullish(),
		combinesWith: z.lazy(() => DiscountCombinesWithInputSchema().nullish()),
		customerSelection: z.lazy(() => PriceRuleCustomerSelectionInputSchema().nullish()),
		itemEntitlements: z.lazy(() => PriceRuleItemEntitlementsInputSchema().nullish()),
		itemPrerequisites: z.lazy(() => PriceRuleItemPrerequisitesInputSchema().nullish()),
		oncePerCustomer: z.boolean().nullish(),
		prerequisiteQuantityRange: z.lazy(() => PriceRuleQuantityRangeInputSchema().nullish()),
		prerequisiteShippingPriceRange: z.lazy(() => PriceRuleMoneyRangeInputSchema().nullish()),
		prerequisiteSubtotalRange: z.lazy(() => PriceRuleMoneyRangeInputSchema().nullish()),
		prerequisiteToEntitlementQuantityRatio: z.lazy(() =>
			PriceRulePrerequisiteToEntitlementQuantityRatioInputSchema().nullish()
		),
		shippingEntitlements: z.lazy(() => PriceRuleShippingEntitlementsInputSchema().nullish()),
		target: PriceRuleTargetSchema.nullish(),
		title: z.string().nullish(),
		usageLimit: z.number().nullish(),
		validityPeriod: z.lazy(() => PriceRuleValidityPeriodInputSchema().nullish()),
		value: z.lazy(() => PriceRuleValueInputSchema().nullish()),
	});
}

export function PriceRuleItemEntitlementsInputSchema(): z.ZodObject<Properties<PriceRuleItemEntitlementsInput>> {
	return z.object({
		collectionIds: z.array(z.string()).nullish(),
		productIds: z.array(z.string()).nullish(),
		productVariantIds: z.array(z.string()).nullish(),
		targetAllLineItems: z.boolean().nullish(),
	});
}

export function PriceRuleItemPrerequisitesInputSchema(): z.ZodObject<Properties<PriceRuleItemPrerequisitesInput>> {
	return z.object({
		collectionIds: z.array(z.string()).nullish(),
		productIds: z.array(z.string()).nullish(),
		productVariantIds: z.array(z.string()).nullish(),
	});
}

export function PriceRuleMoneyRangeInputSchema(): z.ZodObject<Properties<PriceRuleMoneyRangeInput>> {
	return z.object({
		greaterThan: definedNonNullAnySchema.nullish(),
		greaterThanOrEqualTo: definedNonNullAnySchema.nullish(),
		lessThan: definedNonNullAnySchema.nullish(),
		lessThanOrEqualTo: definedNonNullAnySchema.nullish(),
	});
}

export function PriceRulePrerequisiteToEntitlementQuantityRatioInputSchema(): z.ZodObject<
	Properties<PriceRulePrerequisiteToEntitlementQuantityRatioInput>
> {
	return z.object({
		entitlementQuantity: z.number().nullish(),
		prerequisiteQuantity: z.number().nullish(),
	});
}

export function PriceRuleQuantityRangeInputSchema(): z.ZodObject<Properties<PriceRuleQuantityRangeInput>> {
	return z.object({
		greaterThan: z.number().nullish(),
		greaterThanOrEqualTo: z.number().nullish(),
		lessThan: z.number().nullish(),
		lessThanOrEqualTo: z.number().nullish(),
	});
}

export function PriceRuleShippingEntitlementsInputSchema(): z.ZodObject<
	Properties<PriceRuleShippingEntitlementsInput>
> {
	return z.object({
		countryCodes: z.array(CountryCodeSchema).nullish(),
		includeRestOfWorld: z.boolean().nullish(),
		targetAllShippingLines: z.boolean().nullish(),
	});
}

export function PriceRuleValidityPeriodInputSchema(): z.ZodObject<Properties<PriceRuleValidityPeriodInput>> {
	return z.object({
		end: definedNonNullAnySchema.nullish(),
		start: definedNonNullAnySchema,
	});
}

export function PriceRuleValueInputSchema(): z.ZodObject<Properties<PriceRuleValueInput>> {
	return z.object({
		fixedAmountValue: definedNonNullAnySchema.nullish(),
		percentageValue: z.number().nullish(),
	});
}

export function PrivateMetafieldDeleteInputSchema(): z.ZodObject<Properties<PrivateMetafieldDeleteInput>> {
	return z.object({
		key: z.string(),
		namespace: z.string(),
		owner: z.string().nullish(),
	});
}

export function PrivateMetafieldInputSchema(): z.ZodObject<Properties<PrivateMetafieldInput>> {
	return z.object({
		key: z.string(),
		namespace: z.string(),
		owner: z.string().nullish(),
		valueInput: z.lazy(() => PrivateMetafieldValueInputSchema()),
	});
}

export function PrivateMetafieldValueInputSchema(): z.ZodObject<Properties<PrivateMetafieldValueInput>> {
	return z.object({
		value: z.string(),
		valueType: PrivateMetafieldValueTypeSchema,
	});
}

export function ProductAppendImagesInputSchema(): z.ZodObject<Properties<ProductAppendImagesInput>> {
	return z.object({
		id: z.string(),
		images: z.array(z.lazy(() => ImageInputSchema())),
	});
}

export function ProductCategoryInputSchema(): z.ZodObject<Properties<ProductCategoryInput>> {
	return z.object({
		productTaxonomyNodeId: z.string(),
	});
}

export function ProductDeleteInputSchema(): z.ZodObject<Properties<ProductDeleteInput>> {
	return z.object({
		id: z.string(),
	});
}

export function ProductInputSchema(): z.ZodObject<Properties<ProductInput>> {
	return z.object({
		collectionsToJoin: z.array(z.string()).nullish(),
		collectionsToLeave: z.array(z.string()).nullish(),
		customProductType: z.string().nullish(),
		descriptionHtml: z.string().nullish(),
		giftCard: z.boolean().nullish(),
		giftCardTemplateSuffix: z.string().nullish(),
		handle: z.string().nullish(),
		id: z.string().nullish(),
		metafields: z.array(z.lazy(() => MetafieldInputSchema())).nullish(),
		options: z.array(z.string()).nullish(),
		productCategory: z.lazy(() => ProductCategoryInputSchema().nullish()),
		productType: z.string().nullish(),
		redirectNewHandle: z.boolean().nullish(),
		requiresSellingPlan: z.boolean().nullish(),
		seo: z.lazy(() => SeoInputSchema().nullish()),
		standardizedProductType: z.lazy(() => StandardizedProductTypeInputSchema().nullish()),
		status: ProductStatusSchema.nullish(),
		tags: z.array(z.string()).nullish(),
		templateSuffix: z.string().nullish(),
		title: z.string().nullish(),
		variants: z.array(z.lazy(() => ProductVariantInputSchema())).nullish(),
		vendor: z.string().nullish(),
	});
}

export function ProductPublicationInputSchema(): z.ZodObject<Properties<ProductPublicationInput>> {
	return z.object({
		publicationId: z.string().nullish(),
		publishDate: definedNonNullAnySchema.nullish(),
	});
}

export function ProductPublishInputSchema(): z.ZodObject<Properties<ProductPublishInput>> {
	return z.object({
		id: z.string(),
		productPublications: z.array(z.lazy(() => ProductPublicationInputSchema())),
	});
}

export function ProductResourceFeedbackInputSchema(): z.ZodObject<Properties<ProductResourceFeedbackInput>> {
	return z.object({
		feedbackGeneratedAt: definedNonNullAnySchema,
		messages: z.array(z.string()).nullish(),
		productId: z.string(),
		productUpdatedAt: definedNonNullAnySchema,
		state: ResourceFeedbackStateSchema,
	});
}

export function ProductUnpublishInputSchema(): z.ZodObject<Properties<ProductUnpublishInput>> {
	return z.object({
		id: z.string(),
		productPublications: z.array(z.lazy(() => ProductPublicationInputSchema())),
	});
}

export function ProductVariantAppendMediaInputSchema(): z.ZodObject<Properties<ProductVariantAppendMediaInput>> {
	return z.object({
		mediaIds: z.array(z.string()),
		variantId: z.string(),
	});
}

export function ProductVariantDetachMediaInputSchema(): z.ZodObject<Properties<ProductVariantDetachMediaInput>> {
	return z.object({
		mediaIds: z.array(z.string()),
		variantId: z.string(),
	});
}

export function ProductVariantInputSchema(): z.ZodObject<Properties<ProductVariantInput>> {
	return z.object({
		barcode: z.string().nullish(),
		compareAtPrice: definedNonNullAnySchema.nullish(),
		harmonizedSystemCode: z.string().nullish(),
		id: z.string().nullish(),
		inventoryItem: z.lazy(() => InventoryItemInputSchema().nullish()),
		inventoryPolicy: ProductVariantInventoryPolicySchema.nullish(),
		inventoryQuantities: z.array(z.lazy(() => InventoryLevelInputSchema())).nullish(),
		mediaSrc: z.array(z.string()).nullish(),
		metafields: z.array(z.lazy(() => MetafieldInputSchema())).nullish(),
		options: z.array(z.string()).nullish(),
		position: z.number().nullish(),
		price: definedNonNullAnySchema.nullish(),
		productId: z.string().nullish(),
		requiresShipping: z.boolean().nullish(),
		sku: z.string().nullish(),
		taxCode: z.string().nullish(),
		taxable: z.boolean().nullish(),
		weight: z.number().nullish(),
		weightUnit: WeightUnitSchema.nullish(),
	});
}

export function ProductVariantPositionInputSchema(): z.ZodObject<Properties<ProductVariantPositionInput>> {
	return z.object({
		id: z.string(),
		position: z.number(),
	});
}

export function ProductVariantsBulkInputSchema(): z.ZodObject<Properties<ProductVariantsBulkInput>> {
	return z.object({
		barcode: z.string().nullish(),
		compareAtPrice: definedNonNullAnySchema.nullish(),
		harmonizedSystemCode: z.string().nullish(),
		id: z.string().nullish(),
		inventoryItem: z.lazy(() => InventoryItemInputSchema().nullish()),
		inventoryPolicy: ProductVariantInventoryPolicySchema.nullish(),
		inventoryQuantities: z.array(z.lazy(() => InventoryLevelInputSchema())).nullish(),
		mediaSrc: z.array(z.string()).nullish(),
		metafields: z.array(z.lazy(() => MetafieldInputSchema())).nullish(),
		options: z.array(z.string()).nullish(),
		price: definedNonNullAnySchema.nullish(),
		requiresShipping: z.boolean().nullish(),
		sku: z.string().nullish(),
		taxCode: z.string().nullish(),
		taxable: z.boolean().nullish(),
		weight: z.number().nullish(),
		weightUnit: WeightUnitSchema.nullish(),
	});
}

export function PubSubWebhookSubscriptionInputSchema(): z.ZodObject<Properties<PubSubWebhookSubscriptionInput>> {
	return z.object({
		format: WebhookSubscriptionFormatSchema.nullish(),
		includeFields: z.array(z.string()).nullish(),
		metafieldNamespaces: z.array(z.string()).nullish(),
		pubSubProject: z.string(),
		pubSubTopic: z.string(),
	});
}

export function PublicationInputSchema(): z.ZodObject<Properties<PublicationInput>> {
	return z.object({
		publicationId: z.string().nullish(),
		publishDate: definedNonNullAnySchema.nullish(),
	});
}

export function PurchasingCompanyInputSchema(): z.ZodObject<Properties<PurchasingCompanyInput>> {
	return z.object({
		companyContactId: z.string(),
		companyId: z.string(),
		companyLocationId: z.string(),
	});
}

export function PurchasingEntityInputSchema(): z.ZodObject<Properties<PurchasingEntityInput>> {
	return z.object({
		customerId: z.string().nullish(),
		purchasingCompany: z.lazy(() => PurchasingCompanyInputSchema().nullish()),
	});
}

export function RefundDutyInputSchema(): z.ZodObject<Properties<RefundDutyInput>> {
	return z.object({
		dutyId: z.string(),
		refundType: RefundDutyRefundTypeSchema.nullish(),
	});
}

export function RefundInputSchema(): z.ZodObject<Properties<RefundInput>> {
	return z.object({
		currency: CurrencyCodeSchema.nullish(),
		note: z.string().nullish(),
		notify: z.boolean().nullish(),
		orderId: z.string(),
		refundDuties: z.array(z.lazy(() => RefundDutyInputSchema())).nullish(),
		refundLineItems: z.array(z.lazy(() => RefundLineItemInputSchema())).nullish(),
		shipping: z.lazy(() => ShippingRefundInputSchema().nullish()),
		transactions: z.array(z.lazy(() => OrderTransactionInputSchema())).nullish(),
	});
}

export function RefundLineItemInputSchema(): z.ZodObject<Properties<RefundLineItemInput>> {
	return z.object({
		lineItemId: z.string(),
		locationId: z.string().nullish(),
		quantity: z.number(),
		restockType: RefundLineItemRestockTypeSchema.nullish(),
	});
}

export function RemoteAuthorizeNetCustomerPaymentProfileInputSchema(): z.ZodObject<
	Properties<RemoteAuthorizeNetCustomerPaymentProfileInput>
> {
	return z.object({
		customerPaymentProfileId: z.string().nullish(),
		customerProfileId: z.string(),
	});
}

export function RemoteBraintreePaymentMethodInputSchema(): z.ZodObject<Properties<RemoteBraintreePaymentMethodInput>> {
	return z.object({
		customerId: z.string(),
		paymentMethodToken: z.string().nullish(),
	});
}

export function RemoteStripePaymentMethodInputSchema(): z.ZodObject<Properties<RemoteStripePaymentMethodInput>> {
	return z.object({
		customerId: z.string(),
		paymentMethodId: z.string().nullish(),
	});
}

export function SeoInputSchema(): z.ZodObject<Properties<SeoInput>> {
	return z.object({
		description: z.string().nullish(),
		title: z.string().nullish(),
	});
}

export function SavedSearchCreateInputSchema(): z.ZodObject<Properties<SavedSearchCreateInput>> {
	return z.object({
		name: z.string(),
		query: z.string(),
		resourceType: SearchResultTypeSchema,
	});
}

export function SavedSearchDeleteInputSchema(): z.ZodObject<Properties<SavedSearchDeleteInput>> {
	return z.object({
		id: z.string(),
	});
}

export function SavedSearchUpdateInputSchema(): z.ZodObject<Properties<SavedSearchUpdateInput>> {
	return z.object({
		id: z.string(),
		name: z.string().nullish(),
		query: z.string().nullish(),
	});
}

export function ScriptTagInputSchema(): z.ZodObject<Properties<ScriptTagInput>> {
	return z.object({
		cache: z.boolean().nullish(),
		displayScope: ScriptTagDisplayScopeSchema.nullish(),
		src: definedNonNullAnySchema.nullish(),
	});
}

export function SellingPlanAnchorInputSchema(): z.ZodObject<Properties<SellingPlanAnchorInput>> {
	return z.object({
		cutoffDay: z.number().nullish(),
		day: z.number().nullish(),
		month: z.number().nullish(),
		type: SellingPlanAnchorTypeSchema.nullish(),
	});
}

export function SellingPlanBillingPolicyInputSchema(): z.ZodObject<Properties<SellingPlanBillingPolicyInput>> {
	return z.object({
		fixed: z.lazy(() => SellingPlanFixedBillingPolicyInputSchema().nullish()),
		recurring: z.lazy(() => SellingPlanRecurringBillingPolicyInputSchema().nullish()),
	});
}

export function SellingPlanCheckoutChargeInputSchema(): z.ZodObject<Properties<SellingPlanCheckoutChargeInput>> {
	return z.object({
		type: SellingPlanCheckoutChargeTypeSchema.nullish(),
		value: z.lazy(() => SellingPlanCheckoutChargeValueInputSchema().nullish()),
	});
}

export function SellingPlanCheckoutChargeValueInputSchema(): z.ZodObject<
	Properties<SellingPlanCheckoutChargeValueInput>
> {
	return z.object({
		fixedValue: definedNonNullAnySchema.nullish(),
		percentage: z.number().nullish(),
	});
}

export function SellingPlanDeliveryPolicyInputSchema(): z.ZodObject<Properties<SellingPlanDeliveryPolicyInput>> {
	return z.object({
		fixed: z.lazy(() => SellingPlanFixedDeliveryPolicyInputSchema().nullish()),
		recurring: z.lazy(() => SellingPlanRecurringDeliveryPolicyInputSchema().nullish()),
	});
}

export function SellingPlanFixedBillingPolicyInputSchema(): z.ZodObject<
	Properties<SellingPlanFixedBillingPolicyInput>
> {
	return z.object({
		checkoutCharge: z.lazy(() => SellingPlanCheckoutChargeInputSchema().nullish()),
		remainingBalanceChargeExactTime: definedNonNullAnySchema.nullish(),
		remainingBalanceChargeTimeAfterCheckout: z.string().nullish(),
		remainingBalanceChargeTrigger: SellingPlanRemainingBalanceChargeTriggerSchema.nullish(),
	});
}

export function SellingPlanFixedDeliveryPolicyInputSchema(): z.ZodObject<
	Properties<SellingPlanFixedDeliveryPolicyInput>
> {
	return z.object({
		anchors: z.array(z.lazy(() => SellingPlanAnchorInputSchema())).nullish(),
		cutoff: z.number().nullish(),
		fulfillmentExactTime: definedNonNullAnySchema.nullish(),
		fulfillmentTrigger: SellingPlanFulfillmentTriggerSchema.nullish(),
		intent: SellingPlanFixedDeliveryPolicyIntentSchema.nullish(),
		preAnchorBehavior: SellingPlanFixedDeliveryPolicyPreAnchorBehaviorSchema.nullish(),
	});
}

export function SellingPlanFixedPricingPolicyInputSchema(): z.ZodObject<
	Properties<SellingPlanFixedPricingPolicyInput>
> {
	return z.object({
		adjustmentType: SellingPlanPricingPolicyAdjustmentTypeSchema.nullish(),
		adjustmentValue: z.lazy(() => SellingPlanPricingPolicyValueInputSchema().nullish()),
		id: z.string().nullish(),
	});
}

export function SellingPlanGroupInputSchema(): z.ZodObject<Properties<SellingPlanGroupInput>> {
	return z.object({
		appId: z.string().nullish(),
		description: z.string().nullish(),
		merchantCode: z.string().nullish(),
		name: z.string().nullish(),
		options: z.array(z.string()).nullish(),
		position: z.number().nullish(),
		sellingPlansToCreate: z.array(z.lazy(() => SellingPlanInputSchema())).nullish(),
		sellingPlansToDelete: z.array(z.string()).nullish(),
		sellingPlansToUpdate: z.array(z.lazy(() => SellingPlanInputSchema())).nullish(),
	});
}

export function SellingPlanGroupResourceInputSchema(): z.ZodObject<Properties<SellingPlanGroupResourceInput>> {
	return z.object({
		productIds: z.array(z.string()).nullish(),
		productVariantIds: z.array(z.string()).nullish(),
	});
}

export function SellingPlanInputSchema(): z.ZodObject<Properties<SellingPlanInput>> {
	return z.object({
		billingPolicy: z.lazy(() => SellingPlanBillingPolicyInputSchema().nullish()),
		category: SellingPlanCategorySchema.nullish(),
		deliveryPolicy: z.lazy(() => SellingPlanDeliveryPolicyInputSchema().nullish()),
		description: z.string().nullish(),
		id: z.string().nullish(),
		inventoryPolicy: z.lazy(() => SellingPlanInventoryPolicyInputSchema().nullish()),
		name: z.string().nullish(),
		options: z.array(z.string()).nullish(),
		position: z.number().nullish(),
		pricingPolicies: z.array(z.lazy(() => SellingPlanPricingPolicyInputSchema())).nullish(),
	});
}

export function SellingPlanInventoryPolicyInputSchema(): z.ZodObject<Properties<SellingPlanInventoryPolicyInput>> {
	return z.object({
		reserve: SellingPlanReserveSchema.nullish(),
	});
}

export function SellingPlanPricingPolicyInputSchema(): z.ZodObject<Properties<SellingPlanPricingPolicyInput>> {
	return z.object({
		fixed: z.lazy(() => SellingPlanFixedPricingPolicyInputSchema().nullish()),
		recurring: z.lazy(() => SellingPlanRecurringPricingPolicyInputSchema().nullish()),
	});
}

export function SellingPlanPricingPolicyValueInputSchema(): z.ZodObject<
	Properties<SellingPlanPricingPolicyValueInput>
> {
	return z.object({
		fixedValue: definedNonNullAnySchema.nullish(),
		percentage: z.number().nullish(),
	});
}

export function SellingPlanRecurringBillingPolicyInputSchema(): z.ZodObject<
	Properties<SellingPlanRecurringBillingPolicyInput>
> {
	return z.object({
		anchors: z.array(z.lazy(() => SellingPlanAnchorInputSchema())).nullish(),
		interval: SellingPlanIntervalSchema.nullish(),
		intervalCount: z.number().nullish(),
		maxCycles: z.number().nullish(),
		minCycles: z.number().nullish(),
	});
}

export function SellingPlanRecurringDeliveryPolicyInputSchema(): z.ZodObject<
	Properties<SellingPlanRecurringDeliveryPolicyInput>
> {
	return z.object({
		anchors: z.array(z.lazy(() => SellingPlanAnchorInputSchema())).nullish(),
		cutoff: z.number().nullish(),
		intent: SellingPlanRecurringDeliveryPolicyIntentSchema.nullish(),
		interval: SellingPlanIntervalSchema.nullish(),
		intervalCount: z.number().nullish(),
		preAnchorBehavior: SellingPlanRecurringDeliveryPolicyPreAnchorBehaviorSchema.nullish(),
	});
}

export function SellingPlanRecurringPricingPolicyInputSchema(): z.ZodObject<
	Properties<SellingPlanRecurringPricingPolicyInput>
> {
	return z.object({
		adjustmentType: SellingPlanPricingPolicyAdjustmentTypeSchema.nullish(),
		adjustmentValue: z.lazy(() => SellingPlanPricingPolicyValueInputSchema().nullish()),
		afterCycle: z.number(),
		id: z.string().nullish(),
	});
}

export function ShippingLineInputSchema(): z.ZodObject<Properties<ShippingLineInput>> {
	return z.object({
		price: definedNonNullAnySchema.nullish(),
		shippingRateHandle: z.string().nullish(),
		title: z.string().nullish(),
	});
}

export function ShippingRefundInputSchema(): z.ZodObject<Properties<ShippingRefundInput>> {
	return z.object({
		amount: definedNonNullAnySchema.nullish(),
		fullRefund: z.boolean().nullish(),
	});
}

export function ShopLocaleInputSchema(): z.ZodObject<Properties<ShopLocaleInput>> {
	return z.object({
		marketWebPresenceIds: z.array(z.string()).nullish(),
		published: z.boolean().nullish(),
	});
}

export function ShopPolicyInputSchema(): z.ZodObject<Properties<ShopPolicyInput>> {
	return z.object({
		body: z.string(),
		type: ShopPolicyTypeSchema,
	});
}

export function ShopifyPaymentsDisputeEvidenceUpdateInputSchema(): z.ZodObject<
	Properties<ShopifyPaymentsDisputeEvidenceUpdateInput>
> {
	return z.object({
		accessActivityLog: z.string().nullish(),
		cancellationPolicyDisclosure: z.string().nullish(),
		cancellationPolicyFile: z.lazy(() => ShopifyPaymentsDisputeFileUploadUpdateInputSchema().nullish()),
		cancellationRebuttal: z.string().nullish(),
		customerCommunicationFile: z.lazy(() => ShopifyPaymentsDisputeFileUploadUpdateInputSchema().nullish()),
		customerEmailAddress: z.string().nullish(),
		customerFirstName: z.string().nullish(),
		customerLastName: z.string().nullish(),
		refundPolicyDisclosure: z.string().nullish(),
		refundPolicyFile: z.lazy(() => ShopifyPaymentsDisputeFileUploadUpdateInputSchema().nullish()),
		refundRefusalExplanation: z.string().nullish(),
		serviceDocumentationFile: z.lazy(() => ShopifyPaymentsDisputeFileUploadUpdateInputSchema().nullish()),
		shippingAddress: z.lazy(() => MailingAddressInputSchema().nullish()),
		shippingDocumentationFile: z.lazy(() => ShopifyPaymentsDisputeFileUploadUpdateInputSchema().nullish()),
		submitEvidence: z.boolean().nullish(),
		uncategorizedFile: z.lazy(() => ShopifyPaymentsDisputeFileUploadUpdateInputSchema().nullish()),
		uncategorizedText: z.string().nullish(),
	});
}

export function ShopifyPaymentsDisputeFileUploadUpdateInputSchema(): z.ZodObject<
	Properties<ShopifyPaymentsDisputeFileUploadUpdateInput>
> {
	return z.object({
		destroy: z.boolean().nullish(),
		id: z.string(),
	});
}

export function StageImageInputSchema(): z.ZodObject<Properties<StageImageInput>> {
	return z.object({
		filename: z.string(),
		httpMethod: StagedUploadHttpMethodTypeSchema.nullish(),
		mimeType: z.string(),
		resource: StagedUploadTargetGenerateUploadResourceSchema,
	});
}

export function StagedUploadInputSchema(): z.ZodObject<Properties<StagedUploadInput>> {
	return z.object({
		fileSize: definedNonNullAnySchema.nullish(),
		filename: z.string(),
		httpMethod: StagedUploadHttpMethodTypeSchema.nullish(),
		mimeType: z.string(),
		resource: StagedUploadTargetGenerateUploadResourceSchema,
	});
}

export function StagedUploadTargetGenerateInputSchema(): z.ZodObject<Properties<StagedUploadTargetGenerateInput>> {
	return z.object({
		fileSize: definedNonNullAnySchema.nullish(),
		filename: z.string(),
		httpMethod: StagedUploadHttpMethodTypeSchema.nullish(),
		mimeType: z.string(),
		resource: StagedUploadTargetGenerateUploadResourceSchema,
	});
}

export function StandardizedProductTypeInputSchema(): z.ZodObject<Properties<StandardizedProductTypeInput>> {
	return z.object({
		productTaxonomyNodeId: z.string(),
	});
}

export function StorefrontAccessTokenDeleteInputSchema(): z.ZodObject<Properties<StorefrontAccessTokenDeleteInput>> {
	return z.object({
		id: z.string(),
	});
}

export function StorefrontAccessTokenInputSchema(): z.ZodObject<Properties<StorefrontAccessTokenInput>> {
	return z.object({
		title: z.string(),
	});
}

export function SubscriptionBillingAttemptInputSchema(): z.ZodObject<Properties<SubscriptionBillingAttemptInput>> {
	return z.object({
		billingCycleSelector: SubscriptionBillingCycleSelectorSchema().nullish(),
		idempotencyKey: z.string(),
		originTime: definedNonNullAnySchema.nullish(),
	});
}

export function SubscriptionBillingCycleInputSchema(): z.ZodObject<Properties<SubscriptionBillingCycleInput>> {
	return z.object({
		contractId: z.string(),
		selector: SubscriptionBillingCycleSelectorSchema(),
	});
}

export function SubscriptionBillingCycleScheduleEditInputSchema(): z.ZodObject<
	Properties<SubscriptionBillingCycleScheduleEditInput>
> {
	return z.object({
		billingDate: definedNonNullAnySchema.nullish(),
		reason: z.lazy(() => SubscriptionBillingCycleScheduleEditInputScheduleEditReasonSchema),
		skip: z.boolean().nullish(),
	});
}

export function SubscriptionBillingCycleSelectorSchema(): z.ZodObject<Properties<SubscriptionBillingCycleSelector>> {
	return z.object({
		date: definedNonNullAnySchema.nullish(),
		index: z.number().nullish(),
	});
}

export function SubscriptionBillingCyclesDateRangeSelectorSchema(): z.ZodObject<
	Properties<SubscriptionBillingCyclesDateRangeSelector>
> {
	return z.object({
		endDate: definedNonNullAnySchema,
		startDate: definedNonNullAnySchema,
	});
}

export function SubscriptionBillingCyclesIndexRangeSelectorSchema(): z.ZodObject<
	Properties<SubscriptionBillingCyclesIndexRangeSelector>
> {
	return z.object({
		endIndex: z.number(),
		startIndex: z.number(),
	});
}

export function SubscriptionBillingPolicyInputSchema(): z.ZodObject<Properties<SubscriptionBillingPolicyInput>> {
	return z.object({
		anchors: z.array(z.lazy(() => SellingPlanAnchorInputSchema())).nullish(),
		interval: SellingPlanIntervalSchema,
		intervalCount: z.number(),
		maxCycles: z.number().nullish(),
		minCycles: z.number().nullish(),
	});
}

export function SubscriptionContractCreateInputSchema(): z.ZodObject<Properties<SubscriptionContractCreateInput>> {
	return z.object({
		contract: z.lazy(() => SubscriptionDraftInputSchema()),
		currencyCode: CurrencyCodeSchema,
		customerId: z.string(),
		nextBillingDate: definedNonNullAnySchema,
	});
}

export function SubscriptionDeliveryMethodInputSchema(): z.ZodObject<Properties<SubscriptionDeliveryMethodInput>> {
	return z.object({
		localDelivery: z.lazy(() => SubscriptionDeliveryMethodLocalDeliveryInputSchema().nullish()),
		pickup: z.lazy(() => SubscriptionDeliveryMethodPickupInputSchema().nullish()),
		shipping: z.lazy(() => SubscriptionDeliveryMethodShippingInputSchema().nullish()),
	});
}

export function SubscriptionDeliveryMethodLocalDeliveryInputSchema(): z.ZodObject<
	Properties<SubscriptionDeliveryMethodLocalDeliveryInput>
> {
	return z.object({
		address: z.lazy(() => MailingAddressInputSchema().nullish()),
		localDeliveryOption: z.lazy(() => SubscriptionDeliveryMethodLocalDeliveryOptionInputSchema().nullish()),
	});
}

export function SubscriptionDeliveryMethodLocalDeliveryOptionInputSchema(): z.ZodObject<
	Properties<SubscriptionDeliveryMethodLocalDeliveryOptionInput>
> {
	return z.object({
		code: z.string().nullish(),
		description: z.string().nullish(),
		instructions: z.string().nullish(),
		phone: z.string(),
		presentmentTitle: z.string().nullish(),
		title: z.string().nullish(),
	});
}

export function SubscriptionDeliveryMethodPickupInputSchema(): z.ZodObject<
	Properties<SubscriptionDeliveryMethodPickupInput>
> {
	return z.object({
		pickupOption: z.lazy(() => SubscriptionDeliveryMethodPickupOptionInputSchema().nullish()),
	});
}

export function SubscriptionDeliveryMethodPickupOptionInputSchema(): z.ZodObject<
	Properties<SubscriptionDeliveryMethodPickupOptionInput>
> {
	return z.object({
		code: z.string().nullish(),
		description: z.string().nullish(),
		locationId: z.string(),
		presentmentTitle: z.string().nullish(),
		title: z.string().nullish(),
	});
}

export function SubscriptionDeliveryMethodShippingInputSchema(): z.ZodObject<
	Properties<SubscriptionDeliveryMethodShippingInput>
> {
	return z.object({
		address: z.lazy(() => MailingAddressInputSchema().nullish()),
		shippingOption: z.lazy(() => SubscriptionDeliveryMethodShippingOptionInputSchema().nullish()),
	});
}

export function SubscriptionDeliveryMethodShippingOptionInputSchema(): z.ZodObject<
	Properties<SubscriptionDeliveryMethodShippingOptionInput>
> {
	return z.object({
		carrierServiceId: z.string().nullish(),
		code: z.string().nullish(),
		description: z.string().nullish(),
		presentmentTitle: z.string().nullish(),
		title: z.string().nullish(),
	});
}

export function SubscriptionDeliveryPolicyInputSchema(): z.ZodObject<Properties<SubscriptionDeliveryPolicyInput>> {
	return z.object({
		anchors: z.array(z.lazy(() => SellingPlanAnchorInputSchema())).nullish(),
		interval: SellingPlanIntervalSchema,
		intervalCount: z.number(),
	});
}

export function SubscriptionDraftInputSchema(): z.ZodObject<Properties<SubscriptionDraftInput>> {
	return z.object({
		billingPolicy: z.lazy(() => SubscriptionBillingPolicyInputSchema().nullish()),
		customAttributes: z.array(z.lazy(() => AttributeInputSchema())).nullish(),
		deliveryMethod: z.lazy(() => SubscriptionDeliveryMethodInputSchema().nullish()),
		deliveryPolicy: z.lazy(() => SubscriptionDeliveryPolicyInputSchema().nullish()),
		deliveryPrice: definedNonNullAnySchema.nullish(),
		nextBillingDate: definedNonNullAnySchema.nullish(),
		note: z.string().nullish(),
		paymentMethodId: z.string().nullish(),
		status: SubscriptionContractSubscriptionStatusSchema.nullish(),
	});
}

export function SubscriptionFreeShippingDiscountInputSchema(): z.ZodObject<
	Properties<SubscriptionFreeShippingDiscountInput>
> {
	return z.object({
		recurringCycleLimit: z.number().nullish(),
		title: z.string().nullish(),
	});
}

export function SubscriptionLineInputSchema(): z.ZodObject<Properties<SubscriptionLineInput>> {
	return z.object({
		currentPrice: definedNonNullAnySchema,
		customAttributes: z.array(z.lazy(() => AttributeInputSchema())).nullish(),
		pricingPolicy: z.lazy(() => SubscriptionPricingPolicyInputSchema().nullish()),
		productVariantId: z.string(),
		quantity: z.number(),
		sellingPlanId: z.string().nullish(),
		sellingPlanName: z.string().nullish(),
	});
}

export function SubscriptionLineUpdateInputSchema(): z.ZodObject<Properties<SubscriptionLineUpdateInput>> {
	return z.object({
		currentPrice: definedNonNullAnySchema.nullish(),
		customAttributes: z.array(z.lazy(() => AttributeInputSchema())).nullish(),
		pricingPolicy: z.lazy(() => SubscriptionPricingPolicyInputSchema().nullish()),
		productVariantId: z.string().nullish(),
		quantity: z.number().nullish(),
		sellingPlanId: z.string().nullish(),
		sellingPlanName: z.string().nullish(),
	});
}

export function SubscriptionManualDiscountEntitledLinesInputSchema(): z.ZodObject<
	Properties<SubscriptionManualDiscountEntitledLinesInput>
> {
	return z.object({
		all: z.boolean().nullish(),
		lines: z.lazy(() => SubscriptionManualDiscountLinesInputSchema().nullish()),
	});
}

export function SubscriptionManualDiscountFixedAmountInputSchema(): z.ZodObject<
	Properties<SubscriptionManualDiscountFixedAmountInput>
> {
	return z.object({
		amount: z.number().nullish(),
		appliesOnEachItem: z.boolean().nullish(),
	});
}

export function SubscriptionManualDiscountInputSchema(): z.ZodObject<Properties<SubscriptionManualDiscountInput>> {
	return z.object({
		entitledLines: z.lazy(() => SubscriptionManualDiscountEntitledLinesInputSchema().nullish()),
		recurringCycleLimit: z.number().nullish(),
		title: z.string().nullish(),
		value: z.lazy(() => SubscriptionManualDiscountValueInputSchema().nullish()),
	});
}

export function SubscriptionManualDiscountLinesInputSchema(): z.ZodObject<
	Properties<SubscriptionManualDiscountLinesInput>
> {
	return z.object({
		add: z.array(z.string()).nullish(),
		remove: z.array(z.string()).nullish(),
	});
}

export function SubscriptionManualDiscountValueInputSchema(): z.ZodObject<
	Properties<SubscriptionManualDiscountValueInput>
> {
	return z.object({
		fixedAmount: z.lazy(() => SubscriptionManualDiscountFixedAmountInputSchema().nullish()),
		percentage: z.number().nullish(),
	});
}

export function SubscriptionPricingPolicyCycleDiscountsInputSchema(): z.ZodObject<
	Properties<SubscriptionPricingPolicyCycleDiscountsInput>
> {
	return z.object({
		adjustmentType: SellingPlanPricingPolicyAdjustmentTypeSchema,
		adjustmentValue: z.lazy(() => SellingPlanPricingPolicyValueInputSchema()),
		afterCycle: z.number(),
		computedPrice: definedNonNullAnySchema,
	});
}

export function SubscriptionPricingPolicyInputSchema(): z.ZodObject<Properties<SubscriptionPricingPolicyInput>> {
	return z.object({
		basePrice: definedNonNullAnySchema,
		cycleDiscounts: z.array(z.lazy(() => SubscriptionPricingPolicyCycleDiscountsInputSchema())),
	});
}

export function TranslationInputSchema(): z.ZodObject<Properties<TranslationInput>> {
	return z.object({
		key: z.string(),
		locale: z.string(),
		marketId: z.string().nullish(),
		translatableContentDigest: z.string(),
		value: z.string(),
	});
}

export function UtmInputSchema(): z.ZodObject<Properties<UtmInput>> {
	return z.object({
		campaign: z.string(),
		medium: z.string(),
		source: z.string(),
	});
}

export function UpdateMediaInputSchema(): z.ZodObject<Properties<UpdateMediaInput>> {
	return z.object({
		alt: z.string().nullish(),
		id: z.string(),
		previewImageSource: z.string().nullish(),
	});
}

export function UrlRedirectInputSchema(): z.ZodObject<Properties<UrlRedirectInput>> {
	return z.object({
		path: z.string().nullish(),
		target: z.string().nullish(),
	});
}

export function WebPixelInputSchema(): z.ZodObject<Properties<WebPixelInput>> {
	return z.object({
		settings: definedNonNullAnySchema,
	});
}

export function WebhookSubscriptionInputSchema(): z.ZodObject<Properties<WebhookSubscriptionInput>> {
	return z.object({
		callbackUrl: definedNonNullAnySchema.nullish(),
		format: WebhookSubscriptionFormatSchema.nullish(),
		includeFields: z.array(z.string()).nullish(),
		metafieldNamespaces: z.array(z.string()).nullish(),
	});
}

export function WeightInputSchema(): z.ZodObject<Properties<WeightInput>> {
	return z.object({
		unit: WeightUnitSchema,
		value: z.number(),
	});
}
