import type { PostgrestClientType } from '@voyage-lab/db';

import { NotificationData } from './notification';

export class ApplicationData {
	#dbClient: PostgrestClientType;
	notification: NotificationData;

	constructor(dbClient: PostgrestClientType) {
		this.#dbClient = dbClient;
		this.notification = new NotificationData(this.#dbClient);
	}
}
