import { z } from 'zod';

export const AttributionRuleSchema = z.object({
	value: z.number(),
	days: z.number(),
	operator: z.string(),
	path: z.string(),
});

export const BrandAttributionJsonSchema = z.object({
	link: z.array(AttributionRuleSchema),
	code: z.array(AttributionRuleSchema),
});
