interface BrowserCookieStorageReturn<Keys> {
	set: <Key extends keyof Keys>(key: Key, value: Keys[Key], days: number) => void | Error;
	get: <Key extends keyof Keys>(key: Key) => ReturnData<Keys[Key]>;
}

interface ReturnDataError {
	ok: false;
	error: string;
	data?: never;
}

interface ReturnDataSuccess<Data> {
	ok: true;
	error?: never;
	data: string;
}

export type ReturnData<Data> = ReturnDataError | ReturnDataSuccess<Data>;

export function browserCookieStorage<Keys>(): BrowserCookieStorageReturn<Keys> {
	function getItem<Key extends keyof Keys>(key: Key): ReturnData<Keys[Key]> {
		try {
			const value = getCookie(String(key));

			if (value === null || value === undefined) {
				return {
					ok: false,
					error: 'Failed to get key',
				};
			}
			return {
				ok: true,
				data: String(value),
			};
		} catch (err) {
			return {
				ok: false,
				error: 'Failed to get key',
			};
		}
	}
	function setItem<Key extends keyof Keys>(key: Key, value: Keys[Key], days: number): void | Error {
		try {
			setCookie(String(key), String(value), days);
			return;
		} catch (err) {
			return new Error('Failed to set key');
		}
	}

	return {
		set: setItem,
		get: getItem,
	};
}

export type { BrowserCookieStorageReturn };

const getCookie = (name: string) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop()?.split(';').shift();
	return null;
};

const setCookie = (name: string, value: string, days: number) => {
	const date = new Date();
	date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
	// eslint-disable-next-line
	document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
};
