export const admin = {
	auth: {
		root: '/auth',
		login: (csrf?: boolean) => `${admin.auth.root}/login${csrf ? '?csrf=true' : ''}` as const,
		setup: (csrf?: boolean) => `${admin.auth.root}/setup` as const,
		recovery: {
			root: () => `${admin.auth.root}/recovery` as const,
			password: () => `${admin.auth.recovery.root()}/password` as const,
		},
	},
	dashboard: {
		root: '/admin/dashboard',
	},
	workflow: {
		root: '/admin/workflow',
		single: (id: string) => `${admin.workflow.root}/${id}` as const,
		edit: (id: string) => `${admin.workflow.root}/${id}/edit` as const,
		create: () => `${admin.workflow.root}/create` as const,
	},
	brand: {
		root: '/admin/brand',
		single: (id: string) => `${admin.brand.root}/${id}` as const,
		integration: (id: string) => `${admin.brand.single(id)}/integration`,
		attribution: (id: string) => `${admin.brand.single(id)}/attribution`,
	},
	conversation: {
		root: '/admin/conversation',
	},
	feedback: {
		root: '/admin/feedback',
	},
	cart: {
		root: '/admin/cart',
	},
	dailyStats: {
		root: '/admin/daily-stats',
	},
	brandAttributes: {
		root: '/admin/attributes',
	},
	knowledgebase: {
		root: '/admin/daily-stats',
	},
	settings: {
		root: '/admin/settings',
		members: () => `${admin.settings.root}/members` as const,
		account: () => `${admin.settings.root}/account` as const,
	},
	front: {
		root: '/front',
		home: {
			root: `/front/home`,
		},
		conversation: {
			root: `/front/conversation`,
		},
		workflow: {
			root: `/front/workflow`,
		},
		knowledgebase: {
			root: `/front/knowledgebase`,
			create: () => `${admin.front.knowledgebase.root}/create` as const,
		},
		queue: {
			root: `/front/queue`,
			cart: () => `${admin.front.queue.root}?workflow-type=cart` as const,
			checkout: () => `${admin.front.queue.root}?workflow-type=checkout` as const,
		},
		escalate: {
			root: `/front/escalate`,
		},
	},
} as const;
