import { gql } from '@voyage-lab/cube';

export const MEMBERS_LIST = gql`
	query GetMemberList($limit: Int, $offset: Int) {
		cube(
			limit: $limit
			offset: $offset
			where: { users: { is_staff: { equals: "true" } } }
			orderBy: { users: { created_at: asc } }
		) {
			users {
				id
				given_name
				family_name
				email
				role
				state
				created_at {
					value
				}
			}
		}
		meta: cube(where: { users: { is_staff: { equals: "true" } } }) {
			users {
				count
			}
		}
	}
`;
