// Generated by ts-to-zod
import { z } from 'zod';

export const leadJsonSchema = z.object({
	extra_data: z
		.object({
			plan_id: z.string().optional(),
			discount: z.string().optional(),
			password: z.string().optional().nullable(),
			finalized_onboarding: z.boolean().optional(),
			shopify_domain: z.string().optional(),
			bigcommerce: z.unknown().optional(),
			installation_source: z
				.union([z.literal('shopify'), z.literal('bigcommerce'), z.literal('direct')])
				.optional(),
			current_step: z
				.union([
					z.literal('email'),
					z.literal('password_setup'),
					z.literal('about_us'),
					z.literal('name'),
					z.literal('support_contact'),
					z.literal('store_url'),
					z.literal('connect_shopify'),
					z.literal('connect_platform'),
					z.literal('billing_email'),
					z.literal('select_plan'),
					z.literal('payment_method'),
					z.literal('welcome_info'),
					z.literal('discount'),
					z.literal('shopify_checkout'),
					z.literal('connect_knowledgebase'),
				])
				.optional(),
		})
		.optional(),
});
