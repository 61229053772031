import * as jwt from 'jsonwebtoken';

export class JWT {
	#token: string;

	constructor(token: string) {
		this.#token = token;
	}

	/**
	 *
	 * @param payload
	 * @param expire "2 days", "10h", "7d"
	 * @returns string
	 */
	encode<T>(payload: T, expire?: string) {
		if (!this.#token) throw new Error('Invalid JWT secret');
		return jwt.sign(payload as object, this.#token, {
			expiresIn: expire || '7d',
		});
	}

	decode<T>(token: string) {
		if (!this.#token) throw new Error('Invalid JWT secret');
		try {
			return jwt.verify(token, this.#token) as T;
		} catch (error) {
			return null;
		}
	}
}

export type JwtTypes = {
	token: {
		type: 'recovery' | 'setup';
		email: string;
	};
};
