const gql = String.raw;

export const SHOP = gql`
	query Shop {
		shop {
			id
			name
			email
			plan {
				displayName
			}
			myshopifyDomain
			shipsToCountries
			currencyCode
		}
	}
`;
