import type { DatabaseEnum } from '@voyage-lab/db';

import { FlowProcessorCart } from './cart';
import { FlowProcessorCheckout } from './checkout';
import { FlowProcessorOrder } from './order';
import type { FlowProcessor, FlowProcessorArg } from './processor';

export class FlowProcessorFactory {
	#processors = [FlowProcessorOrder, FlowProcessorCheckout, FlowProcessorCart];

	#processor: FlowProcessor;

	constructor({ type, ...rest }: { type: DatabaseEnum['t_workflows_type'] } & FlowProcessorArg) {
		const FlowProcessorClass = this.#processors.find(({ type: processorType }) => processorType === type);

		if (!FlowProcessorClass) throw new Error('FlowProcessorFactory: unknown flow');

		const flowProcessor = new FlowProcessorClass(rest);

		this.#processor = flowProcessor;
	}

	get processor() {
		return this.#processor;
	}
}
