import { parse } from 'papaparse';

/**
 * ### Parse comma separated string to array based on RFC 4180
 * @param values Comma separated string of values
 * @returns Array of the values
 * @see https://tools.ietf.org/html/rfc4180
 */
export function stringToArray(value: string): string[] {
	if (!value) return [];

	const result = parse<string[]>(value);
	return result.data?.[0] || [];
}
