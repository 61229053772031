import type { DatabaseEnum } from '@voyage-lab/db';
import { Constants, type PostgrestClientType } from '@voyage-lab/db';

export class MessagingData {
	#dbClient: PostgrestClientType;

	constructor(dbClient: PostgrestClientType) {
		this.#dbClient = dbClient;
	}

	async getBrandsWithIntegrations(props: { brandIds: string[] }) {
		const { data, error } = await this.#dbClient
			.from('brands')
			.select('id, name, brand_integrations(id, integration_id, settings, brand_id, is_enabled)')
			.in('brand_integrations.integration_id', [
				Constants.Integration.InfobipIntegrationId,
				Constants.Integration.TwilioIntegrationId,
				Constants.Integration.FrontIntegrationId,
			])
			.in('id', props.brandIds)
			.eq('brand_integrations.is_enabled', true)
			.eq('brand_integrations.status', 'connected' as DatabaseEnum['t_brand_integrations_status']);

		if (error) throw error;

		return data;
	}

	async getAllConversationMessages() {
		const { data, error } = await this.#dbClient
			.from('conversation_messages')
			.select(
				'body,conversation_id,status,id, ...conversations(brand_id, checkout_id, ...contact_channels(username))'
			)
			.in('status', ['agent_queued'] satisfies DatabaseEnum['t_conversation_messages_status'][])
			.eq('direction', 'outgoing')
			.lte('scheduled_for', new Date().toISOString())
			.limit(100);

		if (error) throw error;

		return data;
	}

	async updateConversationMessageStatus(props: { status: string; messageId: string }) {
		const { data, error } = await this.#dbClient
			.from('conversation_messages')
			.update({
				status:
					props.status === 'agent_queued'
						? 'sent'
						: ('draft_created' satisfies DatabaseEnum['t_conversation_messages_status']),
			})
			.eq('id', props.messageId);

		if (error) throw error;

		return data;
	}
}
