import { z } from 'zod';

import type { PostgrestClientType } from '@voyage-lab/db';
import { Constants } from '@voyage-lab/db';
import { Schema } from '@voyage-lab/schema';

type ContactChannelIdentifyArgs = {
	contactChannelPhone?: string;
	brandIntegrationPhone?: string;
	brandId?: string;
	frontConversationId?: string;
};

export type ContactChannelIdentifierResult = z.infer<typeof contactChannelIdentitySchema>;

export class ContactChannelIdentifier {
	#dbClient: PostgrestClientType;

	constructor(dbClient: PostgrestClientType) {
		this.#dbClient = dbClient;
	}

	async identify(args: ContactChannelIdentifyArgs) {
		console.info(
			`-> identify: start - c${args.contactChannelPhone} / b${args.brandId || args.brandIntegrationPhone || ''} / f${args.frontConversationId || ''}`
		);

		let identity: ContactChannelIdentifierResult | undefined | void = undefined;
		if (args.frontConversationId)
			identity = this.#prepareIdentity(await this.identifyByFrontConversationId(args.frontConversationId));

		// TODO: Uncomment this when we are sure that this doesn't break anything
		// 	identity = await this.identifyByBrandId(args.contactChannelPhone, args.brandId);

		// TODO: Uncomment this when we have a dedicated inbound flow and properly tested
		// if (!identity && args.contactChannelPhone && args.brandIntegrationPhone)
		// 	identity = await this.identifyByBrandPhone(args.contactChannelPhone, args.brandIntegrationPhone);

		if (!identity && args.contactChannelPhone)
			identity = this.#prepareIdentity(await this.identifyByChannelPhoneNumber(args.contactChannelPhone));

		if (!identity) {
			console.warn(
				`-> identify: not found - c${args.contactChannelPhone} / b${args.brandId || args.brandIntegrationPhone || ''} / f${args.frontConversationId || ''}`
			);
			return null;
		}

		console.info(
			`-> identify: found by ${identity.matched_by} - c${args.contactChannelPhone} / b${args.brandId || args.brandIntegrationPhone || ''} / f${args.frontConversationId || ''}`
		);

		if (!identity) return null;

		identity = contactChannelIdentitySchema.parse(identity);

		return identity;
	}

	async identifyByFrontConversationId(frontConversationId: string) {
		console.info(`-> identify -> front: ${frontConversationId}`);

		const { data: conversation } = await this.#dbClient
			.from('conversations')
			.select(
				'id, extra_data, checkout_id, brand_id, brands(id,name,brand_integrations(id,lookup_id, integration_id, settings)), contact_channels(id,username,compliance_type,engagement_status,contact_id)'
			)
			.eq('extra_data->>front_id', frontConversationId)
			.in('brands.brand_integrations.integration_id', [...Constants.IntegrationIdsCategory.Sms])
			.eq('brands.brand_integrations.status', 'connected')
			.eq('brands.brand_integrations.is_enabled', true)
			.limit(1)
			.maybeSingle();

		// Validation + Logging
		if (!conversation) {
			console.info(`-> identify -> front: no conversation - ${frontConversationId}`);
			return;
		}

		if (!conversation.contact_channels) {
			console.error(`-> identify -> front: no contact channel - ${frontConversationId}`);
			return;
		}

		if (!conversation.brands) {
			console.error(`-> identify -> front: no brand - ${frontConversationId}`);
			return;
		}

		console.info(`-> identify -> front: complete - ${frontConversationId}`);
		return Object.assign(conversation, {
			matched_by: 'front_conversation_id' as ContactChannelIdentifierResult['matched_by'],
		});
	}

	async identifyByChannelPhoneNumber(channelPhoneNumber: string) {
		console.info(`-> identify -> channel: ${channelPhoneNumber}`);

		const { data: conversation } = await this.#dbClient
			.from('conversations')
			.select(
				'id, extra_data, checkout_id, brand_id, brands(id,name, brand_integrations(id,lookup_id,integration_id,settings)), contact_channels!inner(id,username,compliance_type,engagement_status,contact_id)'
			)
			.eq('contact_channels.username', `${formatPhoneNumber(channelPhoneNumber)}`)
			.in('brands.brand_integrations.integration_id', [...Constants.IntegrationIdsCategory.Sms])
			.eq('brands.brand_integrations.is_enabled', true)
			.order('updated_at', { ascending: false })
			.limit(1)
			.maybeSingle();

		if (!conversation) {
			console.info(`-> identify -> channel: no conversation - c${channelPhoneNumber}`);
			return;
		}

		if (!conversation.contact_channels) {
			console.error(`-> identify -> channel: no contact channel - c${channelPhoneNumber}`);
			return;
		}

		if (!conversation.brands) {
			console.error(`-> identify -> channel: no brand - c${channelPhoneNumber}`);
			return;
		}

		return Object.assign(conversation, {
			matched_by: 'channel_phone_number' as ContactChannelIdentifierResult['matched_by'],
		});
	}

	async identifyByBrandPhone(channelPhoneNumber: string, brandPhoneNumber: string) {
		console.info(`-> identify -> brand_phone: c${channelPhoneNumber} / b${brandPhoneNumber}`);

		const { data: brandIntegrations, error } = await this.#dbClient
			.from('brand_integrations')
			.select(
				`id,lookup_id,integration_id,settings, 
				brands(id,name),
				contact_channels(id,username,compliance_type,engagement_status,contact_id,
					conversations(id, extra_data, checkout_id, brand_id)
				)`
			)
			.in('integration_id', [...Constants.IntegrationIdsCategory.Sms])
			.eq('is_enabled', true)
			.eq('status', 'connected')
			.eq('lookup_id', `${formatPhoneNumber(brandPhoneNumber, true)}`)
			.eq('contact_channels.username', `${formatPhoneNumber(channelPhoneNumber)}`)
			// .order('contact_channels.conversations.updated_at', { ascending: false })
			.limit(1, { referencedTable: 'contact_channels' })
			.limit(1, { referencedTable: 'contact_channels.conversations' });

		if (error) {
			console.error(`-> identify -> brand_phone: c${channelPhoneNumber} / b${brandPhoneNumber}`);
			return;
		}

		const smsIntegration = brandIntegrations?.at?.(0);
		const brand = smsIntegration?.brands;
		const channel = smsIntegration?.contact_channels?.at?.(0) || null;
		const conversation = channel?.conversations?.at?.(0) || null;
		if (!brand) {
			console.info(`-> identify -> brand_phone: no brand - c${channelPhoneNumber} / b${brandPhoneNumber}`);
			return;
		}

		const identity: ContactChannelIdentifierResult = {
			brand,
			contact_channel: channel,
			conversations: conversation,
			sms_brand_integration: smsIntegration,
			front_conversation_id: conversation?.extra_data?.front_id,
			matched_by: 'brand_phone_number',
		};

		console.info(`-> identify -> brand_phone: complete - c${channelPhoneNumber} / b${brandPhoneNumber}`);
		return identity;
	}

	async identifyByBrandId(channelPhoneNumber: string, brandId: string) {
		console.info(`-> identify -> brand_id: c${channelPhoneNumber} / b${brandId}`);

		const { data: brandIntegrations, error } = await this.#dbClient
			.from('brand_integrations')
			.select(
				`id,lookup_id,integration_id,settings,is_enabled,
				brands(id,name),
				contact_channels!inner(id,username,compliance_type,engagement_status,contact_id,engagement_status,
					conversations(id, extra_data, checkout_id, brand_id)
				)`
			)
			.in('integration_id', [...Constants.IntegrationIdsCategory.Sms])
			.eq('status', 'connected')
			.eq('brand_id', brandId)
			.eq('contact_channels.username', `${formatPhoneNumber(channelPhoneNumber)}`)
			// .order('contact_channels.conversations.updated_at', { ascending: false })
			.limit(1, { referencedTable: 'contact_channels' })
			.limit(1, { referencedTable: 'contact_channels.conversations' });

		if (error) {
			console.error(`-> identify -> brand_id: c${channelPhoneNumber} / b${brandId}`);
			return;
		}

		const smsIntegration = brandIntegrations?.filter((i) => i.is_enabled)?.[0];
		const brand = smsIntegration?.brands;
		const channel = smsIntegration?.contact_channels?.at?.(0) || null;
		const conversation = channel?.conversations?.at?.(0) || null;
		if (!brand) {
			console.info(`-> identify -> brand_id: c${channelPhoneNumber} / b${brandId}`);
			return;
		}

		const identity: ContactChannelIdentifierResult = {
			brand,
			contact_channel: channel,
			conversations: conversation,
			sms_brand_integration: smsIntegration,
			front_conversation_id: conversation?.extra_data?.front_id,
			matched_by: 'brand_id',
		};

		console.info(`-> identify -> brand_id: complete - c${channelPhoneNumber} / b${brandId}`);
		return identity;
	}

	#prepareIdentity(
		result: Awaited<
			ReturnType<typeof this.identifyByFrontConversationId | typeof this.identifyByChannelPhoneNumber>
		>
	) {
		if (!result) return;
		if (!result.brands) return;

		const smsIntegration = result?.brands?.brand_integrations?.find((integration) =>
			Constants.IntegrationIdsCategory.Sms.some((id) => id == integration.integration_id)
		);

		const identity: ContactChannelIdentifierResult = {
			brand: result.brands,
			contact_channel: result.contact_channels || null,
			conversations: result,
			sms_brand_integration: smsIntegration,
			front_conversation_id: result.extra_data?.front_id,
			matched_by: result.matched_by,
		};

		return identity;
	}
}

export const formatPhoneNumber = (phoneNumber: string, noPlusSign = false) => {
	const digitOnly = phoneNumber.replace(/\D/g, '');
	// const excludingCountryCode = digitOnly.slice(-10);
	if (noPlusSign) return digitOnly;
	return `+${digitOnly}`;
};

export const contactChannelIdentitySchema = z
	.object({
		brand: Schema.brandsSchema.partial(),
		contact_channel: Schema.contactChannelsSchema.partial().optional().nullable(),
		conversations: Schema.conversationsSchema.partial().optional().nullable(),
		sms_brand_integration: Schema.brandIntegrationsSchema.partial().optional().nullable(),
		front_conversation_id: z.string().optional(),
		matched_by: z.enum(['front_conversation_id', 'brand_id', 'channel_phone_number', 'brand_phone_number']),
	})
	.partial();
