'use client';

import { PostgrestClient } from '@supabase/postgrest-js';
import { useRouter } from 'next/navigation';
import { createContext, useContext, useMemo } from 'react';

import { useAuth } from '../auth/hook';
import { DataClient } from '../data/data';
import { admin as routes } from '../routes/admin';
import { createGqlClient } from '../utils/cube-graph-client';

const DataClientContext = createContext<DataClient>(null!);

type DataProviderProps = {
	apiUrl: string;
	graphQlUrl: string;
	children: React.ReactNode;
};

export const DataProvider = ({ children, apiUrl, graphQlUrl }: DataProviderProps) => {
	const router = useRouter();
	const { data: session } = useAuth({ optional: true });

	const dataClient = useMemo<DataClient>(
		() =>
			new DataClient({
				session: session,
				dbClient: getClients(session?.token).rest,
				graphqlClient: session?.token ? getClients(session?.token).gql : null,
			}),
		[session?.token]
	);

	return <DataClientContext.Provider value={dataClient}>{children}</DataClientContext.Provider>;

	function gqlErrorInterceptor(req: Request, res: Response) {
		if (res.status === 403) {
			router.replace(routes.auth.login());
		}
	}
	function getClients(token?: string) {
		return {
			rest: new PostgrestClient(apiUrl, {
				headers: token ? { Authorization: `Bearer ${token}` } : undefined,
			}),
			gql: createGqlClient(graphQlUrl, token, gqlErrorInterceptor),
		};
	}
};

export const useDataClient = () => {
	const dataClient = useContext(DataClientContext);

	if (!dataClient) throw new Error('`useDataClient` must be used within a `DataProvider` and when session loaded.');

	return dataClient;
};
