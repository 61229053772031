import { z } from 'zod';

export const ForgetPassword = z.object({
	email: z.string().email(),
	host: z.string(),
});

export const ResetPassword = z.object({
	token: z.string(),
	password: z.string(),
	confirmPassword: z.string(),
	callbackUrl: z.string(),
});

export const SetupAccount = z.object({
	token: z.string(),
	password: z.string(),
	givenName: z.string(),
	familyName: z.string(),
	confirmPassword: z.string(),
	callbackUrl: z.string(),
});
