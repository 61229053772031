import type { DatabaseEntity, PostgrestClientType } from '@voyage-lab/db';

import type { NotificationFilter } from '../types';

export class NotificationData {
	#dbClient: PostgrestClientType;

	constructor(dbClient: PostgrestClientType) {
		this.#dbClient = dbClient;
	}

	/**
	 * Get a single notification by ID
	 * @param id The notification ID
	 * @returns The notification or null if not found
	 */
	async get(id: string) {
		const { data, error } = await this.#dbClient.from('notifications').select('*').eq('id', id).single();

		if (error) throw error;
		return data;
	}

	/**
	 * Get all notifications for a user with pagination
	 * @param props.userId The user ID to get notifications for
	 * @param props.offset The number of items to skip
	 * @param props.limit The maximum number of items to return
	 * @returns Paginated list of notifications
	 */
	async getAll(props: {
		userId: string;
		tenantId: string;
		offset?: number;
		limit?: number;
		filter?: NotificationFilter;
	}) {
		const { userId, tenantId, offset = 0, limit = 10 } = props;

		const query = this.#dbClient
			.from('notifications')
			.select('*, user_notifications(read, user_id)')
			.eq('user_notifications.user_id', userId)
			.or(`user_id.eq.${userId},tenant_id.eq.${tenantId},and(user_id.is.null,tenant_id.is.null)`)
			.order('created_at', { ascending: false });

		if (props.limit !== undefined && props.offset !== undefined) {
			query.range(offset, offset + limit - 1);
		}

		switch (props.filter) {
			case 'unread':
				query.eq('read', false);
				break;
			case 'announcement':
				query.eq('type', 'announcement');
				break;
			case 'system':
				query.in('type', ['system', 'reminder', 'security']);
				break;
			default:
				break;
		}

		const { data, error } = await query;

		if (error) throw error;

		// eslint-disable-next-line @typescript-eslint/naming-convention
		return data.map(({ user_notifications, ...n }) => ({
			...n,
			read: user_notifications.some((un) => un.read),
		}));
	}

	/**
	 * Create a new notification
	 * @param notification The notification data to create
	 * @returns The created notification
	 */
	async create(notification: DatabaseEntity<'insert'>['notifications']) {
		const { data, error } = await this.#dbClient.from('notifications').insert(notification).select().single();

		if (error) throw error;
		return data;
	}

	/**
	 * Update an existing notification
	 * @param id The notification ID to update
	 * @param updates The fields to update
	 * @returns The updated notification
	 */
	async update(id: string, notification: Omit<DatabaseEntity<'insert'>['notifications'], 'id'>) {
		const { data, error } = await this.#dbClient
			.from('notifications')
			.update(notification)
			.eq('id', id)
			.select()
			.single();

		if (error) throw error;
		return data;
	}

	/**
	 * Mark notifications as read
	 * @param ids The notification IDs to mark as read
	 * @returns The updated notifications
	 */
	async markAsRead(ids: string[], userId: string) {
		const { data, error } = await this.#dbClient
			.from('user_notifications')
			.upsert(
				ids.map((id) => ({
					notification_id: id,
					user_id: userId,
					read: true,
				})),
				{ onConflict: 'notification_id, user_id' }
			)
			.select();

		if (error) throw error;
		return data;
	}

	/**
	 * Mark all notifications as read for a user
	 * @param userId The ID of the user whose notifications to mark as read
	 * @returns The updated notifications
	 */
	async markAllAsRead(userId: string, tenantId: string) {
		// Get all unread notification IDs for user
		const { data: notifications, error: notifError } = await this.#dbClient
			.from('notifications')
			.select('id')
			.or(`user_id.eq.${userId},tenant_id.eq.${tenantId},and(user_id.is.null,tenant_id.is.null)`);

		if (notifError) throw notifError;

		// Create user_notifications entries marking them as read
		const { data, error } = await this.#dbClient
			.from('user_notifications')
			.upsert(
				notifications.map((n) => ({
					notification_id: n.id,
					user_id: userId,
					read: true,
				})),
				{ onConflict: 'notification_id, user_id' }
			)
			.select();

		if (error) throw error;
		return data;
	}

	/**
	 * Get the total number of unread notifications for a user
	 * @param userId The ID of the user whose unread notifications to count
	 * @returns The total number of unread notifications
	 */
	async unreadCount(userId: string, tenantId: string) {
		// Get all notifications for user
		const { count } = await this.#dbClient
			.from('notifications')
			.select('*', { count: 'exact' })
			.or(`user_id.eq.${userId},tenant_id.eq.${tenantId},and(user_id.is.null,tenant_id.is.null)`);

		// Get read notification IDs
		const { count: readCount } = await this.#dbClient
			.from('user_notifications')
			.select('*', { count: 'exact' })
			.eq('user_id', userId)
			.eq('read', true);

		// Calculate unread count by subtracting read notifications
		const unreadCount = (count ?? 0) - (readCount ?? 0);

		return unreadCount;
	}
}
