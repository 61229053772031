/**
 * Coerces all numbers in the given object to numbers.
 *
 * @param obj - The object to coerce numbers in.
 * @param keys - Optional array of keys to limit the coercion to.
 * @returns void
 */
export const coerceNumbers = <ObjT extends object>(obj: ObjT, keys?: (keyof ObjT)[]) => {
	// coerce all numbers to numbers
	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			const value = obj[key];
			if (
				typeof value === 'string' &&
				value.trim() !== '' &&
				!isNaN(Number(value)) &&
				(keys === undefined || keys.includes(key))
			) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				obj[key] = Number(value);
			}
		}
	}

	return obj;
};
