export function addArticle(word: string) {
	const vowels = ['a', 'e', 'i', 'o', 'u'];
	const specialCases = ['hour', 'honor', 'herb'];
	const firstLetter = word.charAt(0).toLowerCase();
	const firstSound = word.toLowerCase();

	if (specialCases.includes(firstSound)) {
		return `an ${word}`;
	}

	if (['m', 'n', 'f', 'l', 'r', 's', 'x'].includes(firstLetter)) {
		if (word.length === 3 && word.toUpperCase() === word) {
			return `an ${word}`;
		}
	}

	if (vowels.includes(firstLetter)) {
		return `an ${word}`;
	} else {
		return `a ${word}`;
	}
}
