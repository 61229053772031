import { print } from 'graphql';

import type { GraphQlClientType } from '@voyage-lab/cube';

export const createCubeGraphClient = async (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	request: any,
	options: {
		token?: string;
		signal?: AbortSignal;
		apiUrl?: string;
		middleware?: (req: Request, res: Response) => void;
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
	const apiUrl = `${options.apiUrl}/cubejs-api/graphql`;
	const res = await fetch(apiUrl, {
		method: 'POST',
		body: JSON.stringify(request),
		signal: options.signal,
		// eslint-disable-next-line
		// @ts-ignore
		headers: {
			'Content-Type': 'application/json',
			Authorization: options.token ? options.token : undefined,
		},
	});

	if (options.middleware) {
		options.middleware(request, res);
	}

	const json = await res.json();
	json.errors = json.errors?.filter(
		(e: Error) =>
			e?.message !== 'Cannot return null for non-nullable field TimeDimension.value.' &&
			e?.message !== 'Cannot return null for non-nullable field TimeDimension.month.'
	);
	if (JSON.stringify(json.errors)?.includes('Continue wait')) {
		// Wait 1 second and try again
		await new Promise((resolve) => setTimeout(resolve, 1000));
		return await createCubeGraphClient(request, options);
	} else if (json.errors?.length) console.error(json.errors);

	return json;
};

/** GraphQL client */
export const createGqlClient = (
	apiUrl: string,
	jwtToken?: string,
	middleware?: (req: Request, res: Response) => void
): GraphQlClientType => {
	return async (props) =>
		await createCubeGraphClient(
			{
				query: typeof props.operation === 'string' ? props.operation : print(props.operation),
				variables: props.variables,
			},
			{ token: jwtToken, apiUrl, signal: props.signal, middleware }
		);
};
