import { Phone } from './phone';
import { String } from './string';
import { Time } from './time';

export const Constants = {
	Phone,
	String,
	Time,
	tableActionIconSize: 20,
};
