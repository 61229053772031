const idMapper = {
	order: 'Order',
	app_subscription: 'AppSubscription',
	line_item: 'LineItem',
} as const;

export const toShopifyId = (id: string | number, type: keyof typeof idMapper) => {
	return `gid://shopify/${idMapper[type]}/${id}`;
};

export const fromShopifyId = (id: string) => {
	return id.replace(/\D/g, '');
};
