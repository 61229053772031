import { nanoid } from 'nanoid';

import type { DatabaseEntity, DatabaseEnum } from '@voyage-lab/db';
import type { TypesT } from '@voyage-lab/db';

const tapToTextInitialMessage: TypesT.WorkflowMessage[] = [
	{
		id: 'nWL1_C9Fz1BMDr_GfMULR',
		data: {},
		type: 'paragraph',
		children: [
			{
				text: 'Hi ',
			},
			{
				type: 'store_name',
				id: 'eWa_DR_giTMgMBPP6TeiJ',
				data: {},
				children: [
					{
						text: '',
					},
				],
			},
			{
				text: ', I have a query.',
			},
		],
	},
];

const greetingMessage: TypesT.WorkflowMessage[] = [
	{
		id: 'nWL1_C9Fz1BMDr_GfMULR',
		data: {},
		type: 'paragraph',
		children: [
			{
				text: 'Hey ',
			},
			{
				id: 'Vko2rza5W_RjMOxI7keGC',
				data: {
					fallback: 'there',
					nameOption: 'firstNameOnly',
				},
				type: 'contact_name',
				children: [
					{
						text: '',
					},
				],
			},
			{
				text: ', thanks for reaching out to ',
			},
			{
				id: 'XfIaZIVl3uFaLPF3LJDTZ',
				data: {},
				type: 'store_name',
				children: [
					{
						text: '',
					},
				],
			},
			{
				text: ' support. How can I assist you today?',
			},
		],
	},
];

const draftOrder: TypesT.WorkflowAction['draft_order'] = {
	type: 'previous_order',
	rules: [],
};

const baseWorkflow: Partial<DatabaseEntity['workflows']> = {
	rules: {
		condition: 'AND',
		rules: [],
	},
	delay_minutes: 30,
	message: [
		{
			id: 'nWL1_C9Fz1BMDr_GfMULR',
			data: {},
			type: 'paragraph',
			children: [
				{
					text: 'Hey ',
				},
				{
					id: 'Vko2rza5W_RjMOxI7keGC',
					data: {
						fallback: 'there',
						nameOption: 'firstNameOnly',
					},
					type: 'contact_name',
					children: [
						{
							text: '',
						},
					],
				},
				{
					text: ', this is ',
				},
				{
					id: 'VXDknuGHOgY0gR-B_VpDa',
					data: {
						fallback: '',
						nameOption: 'firstNameOnly',
					},
					type: 'agent_name',
					children: [
						{
							text: '',
						},
					],
				},
				{
					text: ' from ',
				},
				{
					id: 'XfIaZIVl3uFaLPF3LJDTZ',
					data: {},
					type: 'store_name',
					children: [
						{
							text: '',
						},
					],
				},
				{
					text: '. I saw you were checking out our ',
				},
				{
					id: '2UmLnXtvkXMJVMNZHCV_P',
					data: {},
					type: 'product',
					children: [
						{
							text: '',
						},
					],
				},
				{
					text: '. Good choice! Want me to check if I can get you a discount?',
				},
			],
		},
		{
			id: 'nWL1_C9Fz1BMDr_GfMULR',
			data: {},
			type: 'paragraph',
			children: [
				{
					text: '',
				},
			],
		},
		{
			id: 'nWL1_C9Fz1BMDr_GfMULR',
			data: {},
			type: 'paragraph',
			children: [
				{
					text: "Feel free to not reply or send STOP to opt-out and you won't hear from us again :)",
				},
			],
		},
	],
	action: {
		type: 'discount',
		discount: {
			type: 'percentage',
			value: 15,
			duration: 10,
			config: {
				id: '',
				type: 'dynamic',
				code_prefix: '',
				minimum: 'none',
				category: 'order',
				basicCodeDiscount: {
					appliesOncePerCustomer: true,
					combinesWith: {
						orderDiscounts: false,
						productDiscounts: false,
						shippingDiscounts: false,
					},
					minimumRequirement: {},
					customerSelection: {
						all: true,
					},
					customerGets: {
						// These fields are only settable when subscription feature is enabled on Shopify
						// appliesOnOneTimePurchase: true,
						// appliesOnSubscription: true,
						items: {
							all: true,
						},
						value: {
							// Only one of these can only exist
							// discountAmount: null,
							// discountOnQuantity: null,
							percentage: 0.1,
						},
					},
				},
			},
		},
		agents_response: {
			contact_support: false,
			reply_with_message: true,
			support_email: null,
			reply_message: [
				{
					id: nanoid(),
					type: 'paragraph',
					data: {},
					children: [
						{
							text: `Awesome! I just made you a discount code, it's `,
						},
						{
							type: 'discount_code',
							id: nanoid(),
							data: {},
							children: [
								{
									text: '',
								},
							],
						},
						{
							text: '. Just make sure to apply it to get your discount :) ',
						},
						{
							type: 'checkout_url',
							id: nanoid(),
							data: {},
							children: [
								{
									text: '',
								},
							],
						},
					],
				},
			],
		},
	},
	timezone: 'America/Los_Angles',
	starts_at: null,
	ends_at: null,
	is_paused: false,
	type: 'checkout',
};

export const WORKFLOW_TEMPLATE: {
	[x in DatabaseEnum['t_workflows_type']]: Partial<DatabaseEntity['workflows']>;
} = {
	order: {
		...baseWorkflow,
		action: {
			...baseWorkflow.action,
			type: 'discount',
			draft_order: draftOrder,
		},
		type: 'order',
	},
	checkout: {
		...baseWorkflow,
		type: 'checkout',
	},
	cart: {
		...baseWorkflow,
		type: 'cart',
	},
	schedule: {
		...baseWorkflow,
		type: 'schedule',
	},
	tap_to_text: {
		...baseWorkflow,
		message: tapToTextInitialMessage,
		action: {
			...baseWorkflow.action,
			type: 'discount',
			greeting_message: greetingMessage,
		},
		type: 'tap_to_text',
	},
};
