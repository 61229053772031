import { Engine } from 'json-rules-engine';
import { v4 as uuid } from 'uuid';

import { Constants, type DatabaseEntity, type DatabaseEnum } from '@voyage-lab/db';
import type { TypesT } from '@voyage-lab/schema';
import { Helpers } from '@voyage-lab/util';

import type {
	Flow,
	FlowProcessorArg,
	FlowProcessorCandidate,
	FlowProcessorExecuteCandidate,
	FlowProcessorProcessResult,
} from './processor';
import { FlowProcessor } from './processor';

export class FlowProcessorOrder extends FlowProcessor {
	static override type: DatabaseEnum['t_workflows_type'] = 'order';

	constructor(props: FlowProcessorArg) {
		super(props);
	}

	override async process({
		flows,
		data,
	}: {
		flows: Flow[];
		data: FlowProcessorCandidate;
	}): Promise<FlowProcessorProcessResult> {
		let matchedFlow: Flow | null = null;
		const { data: contact } = await this.dbClient
			.from('contacts')
			.select('*,contact_channels(*)')
			.eq('id', data.contact_id)
			.maybeSingle();
		const contactChannel = contact?.contact_channels?.find((c) => c?.username?.startsWith('+1'));

		const flowEvent: DatabaseEntity['workflow_goal_state_change_events'] = {
			id: uuid(),
			order_id: data.event.id,
			state: 'filtered_out',
			workflow_id: '',
			extra_data: {},
			created_at: new Date().toISOString(),
			checkout_id: null,
			conversation_id: null,
			cart_id: null,
		};

		if (!contactChannel) flowEvent.state = 'channel_missing';

		for await (const flow of flows) {
			if (!contactChannel || !contact) continue;
			const evaluationResult = await this.filterEvaluator(flow, {
				contact,
				channel: contactChannel,
				flows,
				event: data.event,
			});

			if (evaluationResult) {
				flowEvent.state === 'started';
				flowEvent.workflow_id = flow.id;

				if (flow.action.type === 'skip') {
					flowEvent.state === 'filtered_out';
				}

				matchedFlow = flow;
			}
		}

		if (flowEvent.workflow_id)
			await this.flowData.createEvent({
				data: flowEvent,
			});

		if (!matchedFlow || !contactChannel || !contact) return null;

		return {
			...data,
			flow: matchedFlow,
			flowEvents: [flowEvent],
			channel: contactChannel,
			contact,
			flow_id: matchedFlow.id,
		};
	}

	async execute({ flow, data }: { flow: Flow; data: FlowProcessorExecuteCandidate }) {
		// Validations
		if (!flow) throw new Error('Now flow has been determined');

		// Assignments
		let discount = null;
		let discountRule = null;

		const messageHasDiscount = flow.message?.some((m) => m.type === 'discount_code');
		if (flow?.discount_rule_id && messageHasDiscount) {
			const discountRuleRes = await this.flowData.getSingleDiscountRule({ id: flow.discount_rule_id });
			if (discountRuleRes.data) {
				if (flow.brand_integrations.integration_id === Constants.Integration.ShopifyIntegrationId) {
					discount = await this.integrationProvider.generateDiscount(
						discountRuleRes.data,
						flow.brand_integrations.settings
					);
					discountRule = discountRuleRes.data;
				}
			}
		}

		const hydMsg = this.hydratedMessage.prepare(flow.message || [], {
			discount_code: discount?.code,
			workflow: {
				...flow,
				discount_rule: discountRule,
				integration_settings: flow.brand_integrations.settings as unknown as TypesT.BrandIntegrationJson,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} as any,
		});

		const message = await hydMsg.render({
			checkout: data.event,
			channel: data.channel,
			contact: data.contact,
			brand: flow.brand_integrations.brands,
		});
		const timezone = (data.channel.extra_data || {}).timezone;
		let province = '';
		const eventPayload = data.event?.cleaned_raw_data?.detail?.payload;
		if (eventPayload && 'shipping_address' in eventPayload) {
			province = eventPayload.shipping_address?.province || '';
		}
		const scheduledFor = Helpers.Time.getZonedTime({
			timezone,
			province,
			time: new Date(),
		});

		let scheduledForIso = scheduledFor.toISOString();
		const isTestingEnv = true;
		if (isTestingEnv) scheduledForIso = new Date().toISOString();

		const initiatedConversation = await this.conversationData.initiate({
			data: {
				conversation: {
					brand_id: flow.brand_integrations.brand_id,
					channel_id: data.channel.id,
					workflow_id: flow.id,
					cart_id: null,
					checkout_id: data.checkout_id || null,
				},
				message: {
					body: message,
					scheduled_for: scheduledForIso,
				},
			},
		});

		if (discount) {
			await this.dbClient.from('discount_codes').insert({
				id: uuid(),
				created_at: new Date().toISOString(),
				updated_at: new Date().toISOString(),
				brand_id: flow.brand_integrations.brand_id,
				conversation_id: initiatedConversation.conversation.id,
				code: discount.code,
				external_id: discount.external_id,
			});
		}

		await hydMsg.commitShortenedUrl({
			// oid: candidate.data.id,
			wfid: flow.id,
			cvid: initiatedConversation.conversation.id,
			mid: initiatedConversation.message.id,
		});

		return flow;
	}

	override async executeAction({ flow, data }: { flow: Flow; data: FlowProcessorExecuteCandidate }) {
		// Validations
		let draftOrder = null;

		if (flow?.action?.draft_order && flow.action.draft_order?.type !== 'url') {
			// Assignments
			let productIds: ProductId[] = data.event?.cleaned_raw_data.detail?.payload?.line_items
				.map((item) => item.product_id)
				.filter(Boolean) as ProductId[];

			if (flow.action.draft_order.type === 'specific_products') {
				productIds = [];
			}

			const ruleEngine = new Engine(flow.action.draft_order.rules);

			ruleEngine.addOperator('containsAny', (facts: unknown[], values: unknown[]) => {
				if (!Array.isArray(facts) || !Array.isArray(values))
					throw new Error('containsAny operator requires values and facts to be arrays');
				return values.some((value) => facts.includes(value));
			});

			// Make all numeric operators work with strings by converting them to numbers

			// Processing
			ruleEngine.on('success', (rawEvent) => {
				const event = rawEvent as Event;
				if (event.type === 'add_product') productIds.push(...event.params.product_ids);

				if (event.type === 'remove_product')
					productIds = productIds.filter((id) => !event.params.product_ids.includes(id));

				if (event.type === 'replace_product')
					productIds = productIds.map((id) =>
						id == event.params.product_id ? event.params.replace_with_product_id : id
					);

				if (event.type === 'remove_all_products') productIds = [];
			});

			const ruleResult = await ruleEngine.run({ order: data.event?.cleaned_raw_data.detail?.payload });

			draftOrder = {
				productIds,
			};
		}

		if (flow?.action?.draft_order?.type === 'url') {
			draftOrder = {
				url: flow.action.draft_order.params?.url,
			};
		}

		const actionResult = {
			draftOrder,
			agentReply: flow.action.agents_response,
		};

		return actionResult;
	}

	override async getCandidates(): Promise<FlowProcessorCandidate[]> {
		const query = `
WITH candidates AS (
	SELECT
		w.id workflow_id,
		null as checkout_id,
		o.contact_id,
		o.id order_id,
		o.cleaned_raw_data as data
	FROM
		orders o
		JOIN brand_integrations bi ON bi.id = o.brand_integration_id
		JOIN workflows w ON w.brand_integration_id = bi.id
			AND w.type = 'order'
			AND o.updated_at + w.delay_minutes * interval '1 min' < now()
			AND w.is_paused IS false
		LEFT JOIN workflow_goal_state_change_events we ON we.order_id = o.id AND w.id = we.workflow_id AND we.state = 'triggered'
	WHERE
			o.state = 'delivered'
		AND w.id IS NOT NULL
		AND we.id IS NULL
	LIMIT 10000
), flow_events AS (
	INSERT INTO workflow_goal_state_change_events (id, order_id, workflow_id, state)
	SELECT gen_random_uuid() as id, order_id, workflow_id, 'triggered' as state FROM candidates
	RETURNING *
)
SELECT * FROM candidates;
	`;
		const candidates = await this.pgClient.query<FlowProcessorCandidate>(query);
		return candidates?.rows ?? [];
	}
}

type EventReplaceProduct = {
	type: 'replace_product';
	params: {
		product_id: ProductId;
		replace_with_product_id: ProductId;
	};
};

type EventAddProduct = {
	type: 'add_product';
	params: {
		product_ids: ProductId[];
	};
};

type EventRemoveProduct = {
	type: 'remove_product';
	params: {
		product_ids: ProductId[];
	};
};

type EventRemoveAllProducts = {
	type: 'remove_all_products';
};

type Event = EventReplaceProduct | EventAddProduct | EventRemoveProduct | EventRemoveAllProducts;

type ProductId = string | number;
