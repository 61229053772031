import { addDays } from 'date-fns';
import { v4 as uuid } from 'uuid';

import { Constants, type DatabaseEntity, type DatabaseEnum, type PostgrestClientType } from '@voyage-lab/db';

import { ACTIVE_SUB_STATUS } from '../constant';

export class Subscription {
	#dbClient: PostgrestClientType;

	constructor(dbClient: PostgrestClientType) {
		this.#dbClient = dbClient;
	}

	generateEmail(props: { email: string; returnUrl: string; trialDays?: number; subscriptionName: string }) {
		return {
			content: `Congratulations! You've been invited to ${props.subscriptionName} subscription${props.trialDays ? ` with ${props.trialDays}days of trial` : ''}! Please confirm the checkout here. <br /> <a href="${props.returnUrl}">Set up account</a>`,
			subject: 'Set up your LiveRecover account',
			recipient: props.email,
		};
	}

	async getBrandById(brandId: string) {
		const brand = await this.#dbClient.from('brands').select('*').eq('id', brandId).limit(1).maybeSingle();
		return brand.data;
	}

	async getShopifyIntegration(props: { brandId: string }) {
		const brandIntegration = await this.#dbClient
			.from('brand_integrations')
			.select('*')
			.eq('brand_id', props.brandId)
			.eq('integration_id', Constants.Integration.ShopifyIntegrationId)
			.limit(1)
			.maybeSingle();
		return brandIntegration.data;
	}

	async getPlanById(planId: string) {
		const plan = await this.#dbClient.from('plans').select('*').eq('id', planId).maybeSingle();
		return plan.data;
	}

	async getSubscriptionById(subscriptionId: string) {
		const subscription = await this.#dbClient
			.from('subscriptions')
			.select('*')
			.eq('id', subscriptionId)
			.limit(1)
			.maybeSingle();
		return subscription?.data;
	}

	async getSubscriptionDetailsById(subscriptionId: string) {
		const subscription = await this.#dbClient
			.from('subscriptions')
			.select('*, plans(*, plan_gateways(*)), brands(*), subscription_payment_source(*)')
			.eq('id', subscriptionId)
			.limit(1)
			.maybeSingle();

		return subscription.data;
	}

	async getActiveSubscriptionByBrandId(brandId: string) {
		const res = await this.#dbClient
			.from('subscriptions')
			.select('*')
			.eq('brand_id', brandId)
			.in('status', ACTIVE_SUB_STATUS)
			.limit(1)
			.maybeSingle();
		return res.data;
	}

	async getPendingSetupSubscription(identifier: { brandId?: string; subscriptionId?: string }) {
		const query = this.#dbClient
			.from('subscriptions')
			.select('*, subscription_payment_source(gateway)')
			.eq('status', 'pending_setup')
			.order('created_at', { ascending: false })
			.limit(1);

		if (identifier.brandId) {
			query.eq('brand_id', identifier.brandId);
		} else if (identifier.subscriptionId) {
			query.eq('id', identifier.subscriptionId);
		}

		const res = await query.maybeSingle();
		return res.data;
	}

	async getPublishedPlans(activePlanId?: string) {
		const query = this.#dbClient.from('plans').select('*');
		if (activePlanId) {
			query.or(`status.eq.published,and(status.eq.custom,id.eq.${activePlanId})`);
		} else {
			query.eq('status', 'published');
		}
		const plans = await query;
		return plans.data;
	}

	async getPaymentSourceById(id: string) {
		const paymentSource = await this.#dbClient
			.from('subscription_payment_source')
			.select('*')
			.eq('id', id)
			.limit(1)
			.maybeSingle();

		return paymentSource.data;
	}

	async getRemotePriceId(planId: string) {
		const planGateWay = await this.#dbClient
			.from('plan_gateways')
			.select('external_id')
			.eq('plan_id', planId)
			.eq('gateway', 'stripe')
			.maybeSingle();

		return planGateWay.data?.external_id;
	}

	async getLastBilledAt(brandId: string) {
		const subscriptions = await this.#dbClient
			.from('subscriptions')
			.select('last_billed_at')
			.eq('brand_id', brandId)
			.neq('last_billed_at', null)
			.order('last_billed_at', { ascending: false })
			.limit(1)
			.maybeSingle();
		return subscriptions?.data?.last_billed_at;
	}

	async createPlan(plan: DatabaseEntity<'insert'>['plans']) {
		return this.#dbClient.from('plans').insert(plan).select('*').single();
	}

	async createPlanGateway(data: DatabaseEntity<'insert'>['plan_gateways']) {
		return this.#dbClient.from('plan_gateways').insert(data).select('*').single();
	}

	async createSubscriptionPaymentSource(props: DatabaseEntity<'insert'>['subscription_payment_source']) {
		return this.#dbClient.from('subscription_payment_source').insert(props).select('*').single();
	}

	async getSubscriptionPaymentSource(externalId: string) {
		return await this.#dbClient
			.from('subscription_payment_source')
			.select('*')
			.eq('external_id', externalId)
			.limit(1)
			.maybeSingle();
	}

	async createSubscription(props: DatabaseEntity<'insert'>['subscriptions']) {
		return this.#dbClient.from('subscriptions').insert(props).select('*').single();
	}

	async updateSubscription(id: string, props: DatabaseEntity<'update'>['subscriptions']) {
		return this.#dbClient.from('subscriptions').update(props).eq('id', id).select('*').single();
	}

	async createSubscriptionCharges(props: DatabaseEntity<'insert'>['subscription_charges']) {
		return this.#dbClient.from('subscription_charges').insert(props).select('*').single();
	}

	async createSubscriptionDb(props: {
		brandId: string;
		billingEmail: string;
		planId: string;
		customerId: string;
		subscriptionId: string;
		paymentSource: 'stripe' | 'shopify';
		price: number;
		trialDays?: number;
	}) {
		let paymentSource;
		const existingSource = await this.getSubscriptionPaymentSource(props.customerId);
		if (existingSource.data) {
			paymentSource = existingSource;
		} else {
			paymentSource = await this.createSubscriptionPaymentSource({
				id: uuid(),
				brand_id: props.brandId,
				created_at: new Date().toISOString(),
				external_id: props.customerId,
				gateway: props.paymentSource,
				gateway_account_id: '',
				updated_at: new Date().toISOString(),
				legacy_id: '',
			});
		}

		if (!paymentSource.data) {
			throw new Error('Payment source creation failed');
		}

		// get last billed at
		const lastBilledAt = await this.getLastBilledAt(props.brandId);

		// create subscription
		const newSubscriptions = await this.createSubscription({
			id: uuid(),
			brand_id: props.brandId,
			plan_id: props.planId,
			created_at: new Date().toISOString(),
			updated_at: new Date().toISOString(),
			payment_source_id: paymentSource.data?.id,
			status: 'pending_setup',
			external_id: props.subscriptionId,
			last_billed_at: lastBilledAt,
			billing_email: props.billingEmail,
			started_at: new Date().toISOString(),
			...(props.trialDays
				? {
						trial_start_at: new Date().toISOString(),
						trial_end_at: addDays(new Date(), props.trialDays).toISOString(),
					}
				: {}),
		});

		if (!newSubscriptions.data) {
			throw new Error('Subscriptions creation failed');
		}

		// create subscription charges
		await this.createSubscriptionCharges({
			id: uuid(),
			created_at: new Date().toISOString(),
			updated_at: new Date().toISOString(),
			amount: props.price,
			brand_id: props.brandId,
			plan_id: props.planId,
			external_id: props.subscriptionId,
			subscription_id: newSubscriptions.data?.id,
			status: 'pending',
			type: 'recurring',
		});

		return newSubscriptions.data;
	}

	async activateSubscription(id: string, status: DatabaseEnum['t_subscription_status']) {
		const updatedSubscription = await this.#dbClient
			.from('subscriptions')
			.update({
				status: status,
				activated_at: new Date().toISOString(),
			})
			.eq('id', id)
			.select('*')
			.single();

		await this.#dbClient
			.from('subscription_charges')
			.update({
				status: 'complete',
				charge_collected_at: new Date().toISOString(),
			})
			.eq('status', 'pending')
			.eq('type', 'recurring')
			.eq('subscription_id', id);

		return updatedSubscription.data;
	}

	async getSubscriptionChargesByBrandId(brandId: string) {
		const res = await this.#dbClient
			.from('subscription_charges')
			.select('*')
			.eq('brand_id', brandId)
			.order('created_at', { ascending: false });

		return res.data;
	}

	async getPlanGatewayByPlanId(planId: string) {
		const res = await this.#dbClient.from('plan_gateways').select('*').eq('plan_id', planId).maybeSingle();

		return res.data;
	}
}
