import { Schema } from '@voyage-lab/schema';
import { z } from 'zod';

export const AddSubscription = z
	.object({
		brandId: z.string().min(1, 'Brand ID is required'),
		planId: z.string().min(1, 'Plan ID is required'),
		duplicatePlanId: z.string().optional(),
		planRate: z.string().optional(),
		planPrice: z.string().optional(),
		billingEmail: z.string().min(1, 'Billing Email is required'),
		userName: z.string().optional(),
		userPhone: z.string().optional(),
		trialDays: z.string().optional(),
		returnUrl: z.string().optional(),
		paymentSource: Schema.tPaymentTypesSchema,
	})
	.superRefine((data, ctx) => {
		if (data.trialDays === '') {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'Minimum 1 day trial required',
				path: ['trialDays'],
			});
		}
		if (data.planId === 'custom') {
			if (!data.duplicatePlanId) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Select a plan',
					path: ['duplicatePlanId'],
				});
			}
			if (!data.planRate) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Rate Required',
					path: ['planRate'],
				});
			}
			if (!data.planPrice) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: 'Price Required',
					path: ['planPrice'],
				});
			}
		}
	});
