const gql = String.raw;

export const CREATE_DRAFT_ORDER = gql`
	mutation draftOrderCreate($input: DraftOrderInput!) {
		draftOrderCreate(input: $input) {
			draftOrder {
				id
			}
		}
	}
`;

export const GET_ORDER = gql`
	query getOrder($id: ID!) {
		order(id: $id) {
			createdAt
		}
	}
`;
