import { Schema } from '@voyage-lab/schema';
import * as z from 'zod';
import { v4 as uuid } from 'uuid';

/**
 * @description JSON schema with required data to invite member
 */
export const InviteMemberSchema = Schema.usersInputSchema.required().pick({}).extend({
	login_page_link: z.string().optional(),
	email: z.string().email(),
});

export const inviteMemberSchema = Schema.usersInputSchema
	.pick({
		email: true,
		role: true,
		tenant_id: true,
		is_staff: true,
	})
	.extend({
		login_page_link: z.string().optional(),
	});

export const ReinviteMemberSchema = InviteMemberSchema.required().pick({
	email: true,
	login_page_link: true,
});

export const UpdateMemberSchema = Schema.usersInputSchema
	.pick({
		email: true,
		role: true,
		state: true,
	})
	.extend({
		user_id: z.string().uuid(),
	});

/**
 * @description JSON Schema with the required data of first step
 */
export const SignUpSchemaStepOne = Schema.leadsInputSchema
	.required()
	.pick({
		first_name: true,
		last_name: true,
		email: true,
		company_name: true,
		source_name: true,
		annual_revenue: true,
		// company_website: true,
		extra_data: true,
		/** Fill company_website from this field */
		// store_url: true,
	})
	.required()
	.extend({
		id: z
			.string()
			.uuid()
			.default(() => uuid()),
		store_url: z
			.string()
			.regex(new RegExp(/.*\..*/), { message: 'Invalid url' })
			.transform((te) => {
				if (te.startsWith('http//') || te.startsWith('https://')) return te;
				else return 'https://' + te;
			}),
		created_at: z.string().default(() => new Date().toISOString()),
		updated_at: z.string().default(() => new Date().toISOString()),
	});

/**
 * @description JSON Schema with the required data of first step and the required data of second step
 */
export const SignUpSchemaStepTwo = SignUpSchemaStepOne.extend({
	extra_data: Schema.leadJsonSchema.shape.extra_data.unwrap().required({
		password: true,
		installation_source: true,
	}),
});

export const SignUpSchemaFinal = Schema.leadsInputSchema.merge(SignUpSchemaStepTwo);

/**
 * @description JSON Schema with the required data of first step and the required data of second step
 */
export const AccountInfoUpdateSchema = Schema.usersSchema.pick({
	given_name: true,
	family_name: true,
	email: true,
});
