import { v4 as uuid } from 'uuid';

import { Constants } from '@voyage-lab/db';
import { Helpers } from '@voyage-lab/util';

import type { CommonArgs, ConstructorArgs, EventArgs, IdentifyArgs, IdentifyReturn, ResourceReturn } from './provider';
import { IntegrationProvider } from './provider';

export abstract class BaseIntegrationProvider extends IntegrationProvider {
	static override type = 'base';
	constructor(arg: ConstructorArgs) {
		super(arg);
	}

	override async auth(props: CommonArgs) {
		// Validation
		if (!props.data?.brand?.lookupId) throw new Error('No data provided');

		const providerUserRes = await this.accountData.getProviderUser({
			lookupId: props.data.brand.lookupId,
			integrationId: props.data.brand.integrationId,
		});
		const brandIntegration = providerUserRes.data?.tenants.brands?.at?.(0)?.brand_integrations?.at?.(0);

		// If the data is not found we can create a lead and start onboarding
		if (!providerUserRes.data?.id) {
			if (!props.data.lead) throw new Error('No lead data provided');

			if (props.data.lead.id) {
				console.debug('Lead already exists, deep-merge, update and return');
				const lead = await this.accountData.getSingle({ leadId: props.data.lead.id });
				if (!lead) throw new Error(`Failed to fetch lead leads.id -> ${props.data.lead.id}`);

				const updatedLead = Helpers.Object.deepMerge(props.data.lead, lead);
				const updatedLeadRes = await this.accountData.update({
					data: updatedLead,
				});

				if (!updatedLeadRes.data) throw new Error(`Failed to update lead leads.id -> ${props.data.lead.id}`);
				return { data: { lead: updatedLeadRes.data } };
			}

			const leadRes = await this.accountData.create({
				data: { ...props.data.lead, id: uuid() },
				allowDuplicateEmail: true,
			});

			if (!leadRes.data) throw new Error(`Failed to create lead: ${leadRes.error.message}`);

			return {
				data: { lead: leadRes.data },
			};
		}

		// TODO: Refactor and remove shopify related logic by introducing Provider.reauth()
		if (brandIntegration?.id && props.data.lead?.extra_data?.shopify_access_token) {
			console.debug('User already exists and has new session, we can reauth');
			await this.integrationData.patch({
				data: {
					id: brandIntegration.id,
					integration_id: Constants.Integration.ShopifyIntegrationId,
					status: 'connected',
					is_enabled: true,
					settings: {
						credentials: {
							access_token: props.data.lead.extra_data.shopify_access_token,
							scopes: props.data.lead.extra_data.shopify?.session?.scope?.split(','),
						},
					},
				},
			});
		}

		return this.open({
			params: props.params,
			data: {
				user: providerUserRes.data,
				brand: {
					integrationId: props.data.brand.integrationId,
					lookupId: props.data.brand.lookupId,
				},
			},
		});
	}

	override async open(props: CommonArgs) {
		// if (props.rawResponse)
		// 	return {
		// 		rawResponse: props.rawResponse,
		// 		data: props.data,
		// 	};

		// Validation
		if (!props.data?.brand?.lookupId || !props.data.brand.integrationId)
			throw new Error('Your account is not connected to a brand, please contact support');
		// if (!props.data.user?.auth?.provider || !props.data.user?.auth?.[props.data.user.auth.provider]?.user_id)
		// 	throw new Error('Your account is not connected to a user, please contact support');

		if (!props.data.user) throw new Error('No user data provided');
		// const integrationRes = await this.integrationData.getSingleBi({
		// 	lookupId: props.data.brand.lookupId,
		// });

		// const userRes = await this.accountData.getProviderUser({
		// 	provider: props.data.user.auth.provider,
		// 	userId: props.data.user.auth?.[props.data.user.auth.provider]?.user_id as string,
		// });

		// This is a new user, but from authorized provider so we can create a new user
		// if (!userRes.data && integrationRes.data) {
		// 	const bi = integrationRes.data.brand_integrations?.at?.(0);
		// 	if (!bi) throw new Error('Your account is not connected to a brand, please contact support');
		// 	const userCreateRes = await this.accountData.createUser({
		// 		data: {
		// 			...props.data.user,
		// 			tenant_id: bi.brands.tenant_id,
		// 		},
		// 	});

		// 	if (!userCreateRes.data) throw new Error('There was an error creating your user, please contact support');
		// 	props.data.user = userCreateRes.data;
		// }

		const authRes = await this.authData.login({
			id: props.data.user.id,
			provider: props.data.user.auth?.provider,
		});

		return {
			redirect: authRes.refreshToken
				? `/auth/redirect?${new URLSearchParams({ token: Helpers.String.toBase64(authRes) }).toString()}`
				: undefined,
			data: {
				user: props.data.user,
			},
		};
	}

	override async identify(args: IdentifyArgs): Promise<IdentifyReturn> {
		if (!args.data) throw new Error('No data provided');

		return await this.contactData.initiate(args.data);
	}

	override async handleEvent(props: EventArgs) {
		return { data: { success: true } };
	}

	override uninstall(): Promise<void> {
		return Promise.resolve();
	}

	protected createFetch({ headers, url }: { headers: Record<string, string>; url: string }) {
		const fetcher: ResourceReturn['fetch'] = async (input, init) => {
			const response = await fetch(`${url}${input}`, {
				headers: {
					'User-Agent': 'vyg.ai/1.0',
					Accept: 'application/json',
					...headers,
					...init?.headers,
				},
				body: init ? JSON.stringify(init.data) : undefined,
				...init,
			});

			const clonedResponse = await response.clone();
			return Object.assign(response, { data: await clonedResponse.json() });
		};

		return fetcher;
	}
}
