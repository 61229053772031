import { Schema } from '@voyage-lab/schema';
import * as z from 'zod';

export const workflowCreate = Schema.workflowsInputSchema.extend({
	id: z.string().optional(),
	created_at: z.string().optional(),
	name: z.string({ required_error: 'Name is required' }),
	updated_at: z.string().optional(),
	delay_minutes: z.number(),
	message: z.any(),
	rules: z.any(),
	status: z.enum(['draft', 'active']).optional(),
	action: Schema.workflowActionSchema,
});
