import { gql } from '@voyage-lab/cube';

export const PLAN_LIST = gql`
	query GetPlanList {
		cube {
			plans {
				id
				name
				status
				price
				usage_charge_percentage
				description
			}
		}
	}
`;

export const ACTIVE_PLANS = gql`
	query GetActivePlansList {
		cube {
			plans(where: { status: { equals: "published" } }, orderBy: { price: asc }) {
				id
				name
				status
				price
				usage_charge_percentage
				description
			}
		}
	}
`;
