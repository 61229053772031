export const Permission = {
	// ======== Wildcard ========
	WildcardCreateId: '913bc8c8-6e7f-4608-8e47-8d0eaaeb15c9',
	WildcardUpdateId: '33f78aae-401e-42af-aecb-dd3b677ffda2',
	WildcardDeleteId: '1c147bde-785e-4275-928c-b10f27ff80a8',
	WildcardReadId: 'eda34b42-5edd-4407-8e73-1e7f7c6ee900',
	WildcardListId: '93403582-f66a-4dea-9cf7-a28b82d21c8b',

	// ======== Workflow ========
	WorkflowCreateId: '5621cb61-09da-485d-a6fc-fae6c84b6a44',
	WorkflowUpdateId: 'ec40b29f-cc8d-4f65-b94b-93e2c35c49c3',
	WorkflowDeleteId: '04c6f70f-aa2f-4b4d-8296-a6c367f682f1',
	WorkflowReadId: '26377194-5e94-461a-9e3a-c9333d7a9cb6',
	WorkflowListId: '45bb9429-b1be-49f5-9a16-848b96f2755c',

	// ======== Workflow Attributes ========
	WorkflowDiscountUpdateId: '9310ed2c-4b70-4786-bcb8-45a7142020aa',
	WorkflowMessageUpdateId: 'f283e5dd-1a4c-4f5d-b8b2-77c9bf7650ef',
	WorkflowStartUpdateId: '8f1b49ac-8325-4053-ae38-7d395c525d84',
	WorkflowEndUpdateId: '2f485d72-9dff-47c6-a1f8-c76a3456148d',
	WorkflowDelayUpdateId: '55423398-7753-4cfc-bfd5-ed865b0a814d',

	WorkflowDiscountCreateId: '1e309000-e5f2-426a-8784-c9fd8da5de30',
	WorkflowMessageCreateId: '09dc6de2-95c2-4e63-8828-431a09fcbd5e',
	WorkflowStartCreateId: '09dc6de2-95c2-4e63-8828-431a09fcbd4e',
	WorkflowEndCreateId: '09dc6de2-95c2-4e63-8828-431a09fcbd3e',
	WorkflowDelayCreateId: '09dc6de2-95c2-4e63-8828-431a09fcbd2e',

	// ======== Discount Rule ========
	DiscountRuleCreateId: 'e3155f5a-03e6-45c9-80f8-900c2e9fcc63',
	DiscountRuleUpdateId: '9f4d18e3-650b-4426-8e40-f756e6486a55',
	DiscountRuleDeleteId: 'dec307cd-d373-4f72-b5a2-239230478f7d',
	DiscountRuleReadId: 'a7cf4843-336a-4156-a267-c7126814d72e',
	DiscountRuleListId: 'fe820763-ea60-48cb-8cce-8765940d5433',
} as const;
