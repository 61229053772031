import { add, format, set } from 'date-fns';
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { z } from 'zod';

function allowedTime(province: string, timezone?: string): [number, number] {
	// If we don't have a timezone we use a stricter time window to prevent sending in quiet hours
	if (!timezone) {
		return [10, 19];
	}

	// TCPA quiet hours are 9pm-8am local but Florida pass regulation (CS/SB 1120
	// Section 2) which makes their quiet hours 8pm-8am local.
	return province === 'Florida' ? [8, 20] : [8, 21];
}

export const schemaGetZonedTimeArg = z.object({
	timezone: z.string().optional(),
	province: z.string(),
	time: z.coerce.date().optional(),
});

export function getZonedTime({
	timezone,
	province,
	time: originalTime = new Date(),
}: {
	timezone?: string;
	province: string;
	time?: Date;
}): Date {
	let time = new Date(originalTime);

	let start, end, localHour, localDate;
	if (!timezone) {
		//Schedule between 1900 and 2300 utc if timezone is unknown
		[start, end] = [19, 23];

		//Get utc hour and date
		localHour = parseInt(formatInTimeZone(time, 'UTC', 'HH'));
		localDate = parseInt(formatInTimeZone(time, 'UTC', 'dd'));
	} else {
		//Schedule for provided timezone
		[start, end] = allowedTime(province, timezone);

		localHour = parseInt(formatInTimeZone(time, timezone, 'HH'));
		localDate = parseInt(formatInTimeZone(time, timezone, 'dd'));
	}

	const utcDate = time.getUTCDate();

	if (localHour < start) {
		// schedule for start of the day
		time = set(time, { hours: start, minutes: 0, seconds: 0, milliseconds: 0 });
		const tztime = zonedTimeToUtc(time, timezone || 'UTC');
		return tztime;
	}
	if (localHour >= end) {
		// add a day if we're past the end of the day and day was not added by timezone offset
		if (localDate < utcDate) {
		} else {
			time = add(time, { days: 1 });
		}

		time = set(time, { hours: start, minutes: 0, seconds: 0, milliseconds: 0 });
		const tztime = zonedTimeToUtc(time, timezone || 'UTC');

		// check if time and tztime are 2 days apart then we need to subtract a day
		if (Math.abs(tztime.getUTCDate() - utcDate) > 1) {
			return add(tztime, { days: -1 });
		}

		return tztime;
	}
	return time;
}

export function formatTimeByZone(timezone: string | undefined, date?: string) {
	let currentDate = new Date();

	if (date) {
		currentDate = new Date(date);
	}

	return timezone
		? currentDate.toLocaleString('en-US', {
				timeZone: timezone,
				hour12: true,
				hour: 'numeric',
				minute: 'numeric',
				timeZoneName: 'short',
			})
		: 'Unknown';
}

export function getTimezone() {
	return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 *
 * @param date
 * @example April 29th, 1453
 */
export function formatDatePPP(date: string | undefined) {
	return String(date) !== String(undefined) && date ? format(new Date(date), 'PPP') : 'Unknown';
}

export function formatMonthYear(dateStr: string): string {
	if (!dateStr || typeof dateStr !== 'string') return 'Invalid Date';
	const [year, month] = dateStr.split('-');
	if (!year || !month) return 'Pending';
	return new Date(`${year}-${month}-01`).toLocaleString('default', {
		month: 'long',
		year: 'numeric',
	});
}

export const toUtc = (date: Date) => {
	const utcString = date.toUTCString();
	return new Date(utcString);
};

export { formatInTimeZone, utcToZonedTime, zonedTimeToUtc };

export const HTML5_FMT = {
	DATETIME_LOCAL_MS: 'yyyy-MM-dd HH:mm:ss.SSS',
};
