import mqtt from 'mqtt';

export function createRealtimeClient(url: string) {
	// Initialization
	const uri = new URL(url);
	const clientId = uri.searchParams.get('clientId');
	const token = uri.searchParams.get('token');
	uri.searchParams.delete('token');
	uri.searchParams.delete('clientId');

	// Validation
	if (!token) throw new Error('token is required');
	if (!clientId) throw new Error('clientId is required');

	return mqtt.connect(url, {
		protocolVersion: 5,
		manualConnect: true,
		username: '', // Must be empty for the authorizer
		password: token, // Passed as the token to the authorizer
		clientId: clientId,
	});
}
