const gql = String.raw;

export const App = gql`
	query App {
		app {
			availableAccessScopes {
				description
				handle
			}
		}
	}
`;
