import { gql } from '@voyage-lab/cube';

export const INTEGRATION_CORE_STATS = gql`
	query GetIntegrationCoreStats($limit: Int, $offset: Int) {
		cube(limit: $limit, offset: $offset) {
			integrations {
				is_published
			}
		}
	}
`;
