import type { NextAuthOptions } from 'next-auth';

export const commonAutOptions: Partial<NextAuthOptions> = {
	callbacks: {
		session: (props) => {
			const token = props?.token || {};
			const session = props?.session || {};

			return { ...session, ...token };
		},
	},
	pages: {
		signIn: '/auth/login',
		error: '/auth/login',
	},
	session: {
		strategy: 'jwt',
	},
	// cookies: {
	// 	callbackUrl: {
	// 		name: 'auth.callback-url',
	// 		options: {
	// 			secure: true,
	// 			sameSite: 'none',
	// 		},
	// 	},
	// 	nonce: {
	// 		name: 'auth.nonce',
	// 		options: {
	// 			secure: true,
	// 			sameSite: 'none',
	// 		},
	// 	},
	// 	csrfToken: {
	// 		name: 'auth.csrf-token',
	// 		options: {
	// 			secure: true,
	// 			sameSite: 'none',
	// 		},
	// 	},
	// 	pkceCodeVerifier: {
	// 		name: 'auth.pkce-code-verifier',
	// 		options: {
	// 			secure: true,
	// 			sameSite: 'none',
	// 		},
	// 	},
	// 	state: {
	// 		name: 'auth.state',
	// 		options: {
	// 			secure: true,
	// 			sameSite: 'none',
	// 		},
	// 	},
	// 	sessionToken: {
	// 		name: 'auth.session-token',
	// 		options: {
	// 			secure: true,
	// 			sameSite: 'none',
	// 			path: '/',
	// 		},
	// 	},
	// },
};
