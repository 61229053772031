import type { DatabaseEntity, PostgrestClientType } from '@voyage-lab/db';
import { Constants } from '@voyage-lab/db';
import { Schema } from '@voyage-lab/schema';

export class BrandData {
	#dbClient: PostgrestClientType;

	constructor(dbClient: PostgrestClientType) {
		this.#dbClient = dbClient;
	}

	async getSingle(props: { brandId: string }) {
		const { data, error } = await this.#dbClient
			.from('brands')
			.select(
				`
            id,
            name,
			support_email,
			tos_url,
			privacy_policy_url,
			support_phone,
			escalation_email,
            subscriptions(
                plans(
                    name,
                    price,
                    usage_charge_percentage
                ),
				billing_email
            ),
			attribution_rules
        `
			)
			.eq('id', props.brandId)
			.maybeSingle();

		return data;
	}

	async getBrandIntegration(props: { brandId?: string; brandIntegrationId?: string; integrationId?: string }) {
		const query = this.#dbClient.from('brands').select(
			`
				id,
				name,
				brand_integrations(*)
			`
		);

		if (props.brandIntegrationId) query.eq('brand_integrations.id', props.brandIntegrationId);
		if (props.integrationId) query.eq('brand_integrations.integration_id', props.integrationId);
		if (props.brandId) query.eq('id', props.brandId);

		const { data, error } = await query.maybeSingle();

		return data;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	async updateBrandIndustry({ brandId, industry }: { brandId: string; industry: any }) {
		const { data, error } = await this.#dbClient.from('brands').update({ industry: industry }).eq('id', brandId);

		if (error) {
			console.log('Update industry error', error);
			return null;
		}
		console.log('Updated: ', error);
		return brandId;
	}

	async updateBrand({ brandId, data }: { brandId: string; data: Partial<DatabaseEntity['brands']> }) {
		const validData = Schema.brandsInputSchema.omit({ id: true, created_at: true }).partial().parse(data);
		const { data: updatedData, error } = await this.#dbClient
			.from('brands')
			.update(validData as unknown as DatabaseEntity['brands'])
			.eq('id', brandId)
			.select('*')
			.maybeSingle();
		return {
			data: updatedData,
			error,
		};
	}
	async getDefaultPaymentProvider(brandId: string) {
		const { data } = await this.#dbClient.from('brands').select('extra_data').eq('id', brandId).maybeSingle();
		return data?.extra_data?.is_direct ? 'stripe' : 'shopify';
	}

	async listActive(props: { limit: number; offset: number; search?: string }) {
		const query = this.#dbClient
			.from('brands')
			.select('id, brand_integrations!inner(), subscriptions!inner()')
			.eq('brand_integrations.integration_id', Constants.Integration.ShopifyIntegrationId)
			.eq('brand_integrations.status', 'connected')
			.eq('subscriptions.status', 'active');

		if (props.search) query.ilike('name', `%${props.search}%`);

		return await query
			.order('created_at', { ascending: false })
			.range(props.offset, props.offset + props.limit - 1);
	}

	async listAll(props: { limit: number; offset: number; search?: string }) {
		const query = this.#dbClient.from('brands').select('id, name, domain');
		if (props.search) query.ilike('name', `%${props.search}%`);
		return await query
			.order('created_at', { ascending: false })
			.range(props.offset, props.offset + props.limit - 1);
	}
}

export type BrandDetailsT = Awaited<ReturnType<BrandData['getSingle']>>;
