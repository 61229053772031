import { AccountData } from '@voyage-lab/core-account';
import { AuthData } from '@voyage-lab/core-auth';
import { ContactData } from '@voyage-lab/core-messaging';
import type { DatabaseEntity, PostgrestClientType } from '@voyage-lab/db';
import { Constants } from '@voyage-lab/db';
import type { PartialExcept } from '@voyage-lab/util';

import { IntegrationCoreData } from '../data/data';
import { BigCommerce } from './platform/bigcommerce';
import { Klaviyo } from './platform/klaviyo';
import { Shopify } from './platform/shopify';
import type { ConstructorArgs } from './provider';

/**
 * The integration provider factory.
 * This class is used to resolve the integration provider based on the integration ID.
 */
export class IntegrationFactory {
	/**
	 * The list of integration providers.
	 * Add new providers here with the `IntegrationProvider` class.
	 */
	#providers = [BigCommerce, Shopify, Klaviyo];

	#dbClient: PostgrestClientType;
	#jwtSecret: string;
	#credentials: Record<string, ConstructorArgs['credentials']>;
	#appUrl: string;
	#integrationCoreData: IntegrationCoreData;

	constructor({
		dbClient,
		jwtSecret,
		credentials,
		appUrl,
	}: {
		dbClient: PostgrestClientType;
		jwtSecret: string;
		credentials: Record<string, ConstructorArgs['credentials']>;
		appUrl: string;
	}) {
		this.#dbClient = dbClient;
		this.#jwtSecret = jwtSecret;
		this.#credentials = credentials;
		this.#appUrl = appUrl;
		this.#integrationCoreData = new IntegrationCoreData(dbClient);
	}

	/**
	 * Resolves the integration provider based on the integration ID.
	 * @param integrationId - The ID of the integration.
	 * @returns The integration provider.
	 */
	resolve(integrationId: DatabaseEntity['integrations']['id']) {
		const Provider = this.#providers.find(
			(provider) =>
				provider.id === integrationId ||
				String(provider.type).toLowerCase() === String(integrationId).toLowerCase()
		);
		if (!Provider) throw new Error(`Integration provider not found for integrations.id: ${integrationId}`);

		const providerCredentials = this.#credentials[Provider.type.toLowerCase()];
		if (!providerCredentials) throw new Error(`Provider credentials not found for integration: ${Provider.type}`);
		if (!providerCredentials.authCallbackUrl)
			providerCredentials.authCallbackUrl = `${this.#appUrl}/api/integration/${Provider.id}/auth`;

		const provider = new Provider({
			integrationData: this.#integrationCoreData,
			accountData: new AccountData({
				dbClient: this.#dbClient,
				jwtSecret: this.#jwtSecret,
			}),
			authData: new AuthData({
				dbClient: this.#dbClient,
				jwtSecret: this.#jwtSecret,
			}),
			dataClient: this.#dbClient,
			credentials: providerCredentials,
			contactData: new ContactData({
				dbClient: this.#dbClient,
				integrationData: this.#integrationCoreData,
			}),
		});

		return provider;
	}

	static resolveEnv(env: Record<string, string | undefined>) {
		return Object.keys(env)
			.filter((key) => key.startsWith('INTEGRATIONS_'))
			.reduce(
				(acc, key) => {
					const [provider, prop] = key.split('_').slice(1);
					acc[provider.toLowerCase()] = {
						clientId: env[`INTEGRATIONS_${provider}_CLIENT_ID`]!,
						clientSecret: env[`INTEGRATIONS_${provider}_CLIENT_SECRET`]!,
						authCallbackUrl: env[`INTEGRATIONS_${provider}_AUTH_CALLBACK_URL`],
					};
					return acc;
				},
				{} as Record<string, ConstructorArgs['credentials']>
			);
	}

	/**
	 * Resolves the integrations based on the event data.
	 * @param eventData - The event data.
	 * @returns The integrations.
	 */
	// eslint-disable-next-line
	async resolveIntegrations(eventData: Record<string, any> = {}) {
		let integrations: PartialExcept<DatabaseEntity['brand_integrations'], 'integration_id' | 'lookup_id'>[] = [];
		const shopifyWebhookLookupId = eventData['detail']?.['metadata']?.['X-Shopify-Shop-Domain'];
		const lookupId = shopifyWebhookLookupId || eventData['lookup_id'];
		const integration = shopifyWebhookLookupId ? 'shopify' : eventData['integration'];

		switch (integration) {
			case 'bigcommerce':
				integrations.push({
					integration_id: Constants.Integration.BigCommerceIntegrationId,
					lookup_id: eventData['klaviyo_id'],
				});
				break;
			case 'shopify':
				integrations.push({
					integration_id: Constants.Integration.ShopifyIntegrationId,
					lookup_id: lookupId,
				});
				if (eventData['klaviyo_id'])
					integrations.push({
						integration_id: Constants.Integration.KlaviyoIntegrationId,
						lookup_id: eventData['klaviyo_id'],
					});
				break;
		}

		const primaryIntegrationQueries = integrations.filter((iq) => iq.lookup_id);
		const auxIntegrationQueries = integrations.filter((iq) => !iq.lookup_id);

		const primaryIntegrationRes = await this.#integrationCoreData.getBi({
			lookupIds: primaryIntegrationQueries.map<string>((q) => q.lookup_id!),
			integrationIds: primaryIntegrationQueries.map((query) => query.integration_id),
		});

		integrations = integrations.map((iq) => {
			const bi = primaryIntegrationRes?.data
				?.find((bi) => bi.id === iq.integration_id)
				?.brand_integrations?.at(0);
			if (bi) return bi;
			return iq;
		});

		const brandId = integrations.find((i) => i.brand_id)?.brand_id;
		if (brandId && auxIntegrationQueries.length) {
			const auxIntegrationRes = await this.#integrationCoreData.getBi({
				integrationIds: auxIntegrationQueries.map((query) => query.integration_id),
				brandId,
			});

			integrations = integrations.map((iq) => {
				const bi = auxIntegrationRes?.data
					?.find((bi) => bi.id === iq.integration_id)
					?.brand_integrations?.at(0);
				if (bi) return bi;
				return iq;
			});
		}

		return integrations as DatabaseEntity['brand_integrations'][];
	}
}
