import { createHash, randomBytes } from 'crypto';
import { v4 as uuid } from 'uuid';

import { Constants } from '@voyage-lab/db';
import type { BrandIntegrationKlaviyoSettings, DatabaseEntity } from '@voyage-lab/db/src/types';

import { BaseIntegrationProvider } from '../../base';
import type {
	CommonArgs,
	ConstructorArgs,
	EventArgs,
	IdentifyArgs,
	IdentifyReturn,
	ResourceArgs,
	ResourceReturn,
} from '../../provider';

export class Klaviyo extends BaseIntegrationProvider {
	static override id = Constants.Integration.KlaviyoIntegrationId;
	static override type = 'klaviyo' as const;

	AUTH_URL = 'https://klaviyo.com/oauth/authorize';
	OAUTH_TOKEN_URL = 'https://a.klaviyo.com/oauth/token';
	API_URL = 'https://a.klaviyo.com/api';
	KLAVIYO_REVISION = '2024-07-15';
	DEFINED_SCOPES = ['accounts:read'];

	constructor(arg: ConstructorArgs) {
		super(arg);
	}

	override async open(params: CommonArgs) {
		const brandId = params.data?.brand?.bid;
		if (!brandId) {
			throw new Error('Brand data is required');
		}

		const { codeVerifier, codeChallenge } = this.generateCode();
		const state = uuid();

		// Create the brand integration with a pending_setup status
		// to store the code verifier and state. As it will be
		// used to generate credentials later.
		const brandIntegration = await this.integrationData.create({
			data: {
				id: uuid(),
				integration_id: Klaviyo.id,
				brand_id: brandId,
				lookup_id: state,
				settings: {
					credentials: {
						code_verifier: codeVerifier,
						code_challenge: codeChallenge,
						state,
					},
				},
				created_at: new Date().toISOString(),
				updated_at: new Date().toISOString(),
				status: 'pending_setup',
				is_enabled: false,
			},
		});

		if (!brandIntegration.data) {
			throw new Error('Brand integration not created');
		}

		const redirectUrl =
			`${this.AUTH_URL}?` +
			`client_id=${this.credentials.clientId}&` +
			`response_type=code&` +
			`redirect_uri=${this.credentials.authCallbackUrl}&` +
			`scope=${this.DEFINED_SCOPES.join(' ')}&` +
			`state=${brandIntegration.data.id}&` +
			`code_challenge_method=S256&` +
			`code_challenge=${codeChallenge}`;

		return super.open({
			params: {
				...params,
				redirect: redirectUrl,
			},
			data: {
				brand: {
					integrationId: Klaviyo.id,
					lookupId: state,
				},
			},
		});
	}

	override async auth(props: KlaviyoAuthProps) {
		const { code, state } = props.params;
		const integrationData = await this.integrationData.getSingleBi({ lookupId: state });
		if (!integrationData.data) {
			throw new Error('Brand integration not found');
		}
		const brandIntegration = integrationData.data.brand_integrations[0];

		// Exchange the authorization code for tokens & update the brand integration settings
		const tokenResponse = await this.exchangeAuthorizationCode(code, state);
		const brandIntegrationSettings = brandIntegration.settings as BrandIntegrationKlaviyoSettings;
		brandIntegrationSettings.credentials.access_token = tokenResponse.access_token;
		brandIntegrationSettings.credentials.refresh_token = tokenResponse.refresh_token;
		brandIntegrationSettings.credentials.expires_at = new Date(
			Date.now() + tokenResponse.expires_in * 1000
		).toISOString();

		const updatedBrandIntegration = await this.integrationData.update({
			data: {
				id: brandIntegration.id,
				settings: brandIntegrationSettings,
			},
		});
		if (!updatedBrandIntegration.data) {
			throw new Error('Brand integration not found');
		}

		const klaviyoAccountResponse = await fetch(`https://a.klaviyo.com/api/accounts`, {
			headers: {
				Authorization: `Bearer ${brandIntegrationSettings.credentials.access_token}`,
				...this.getKlaviyoHeaders(),
			},
		});
		const klaviyoAccount: KlaviyoAccount = await klaviyoAccountResponse.json();
		const fullName = klaviyoAccount.attributes.contact_information.default_sender_name;
		const firstSpaceIndex = fullName.indexOf(' ');
		const firstName = fullName.slice(0, firstSpaceIndex);
		const lastName = fullName.slice(firstSpaceIndex + 1);

		return await super.auth({
			data: {
				brand: {
					integrationId: Klaviyo.id,
					lookupId: klaviyoAccount.id,
				},
				lead: {
					email: klaviyoAccount.attributes.contact_information.default_sender_email,
					first_name: firstName,
					last_name: lastName,
					id: uuid(),
					annual_revenue: null,
					source_name: 'klaviyo_store',
					source_type: Klaviyo.type,
					tenant_id: null,
					updated_at: new Date().toISOString(),
					user_id: null,
					store_type: Klaviyo.type,
					store_url: klaviyoAccount.attributes.contact_information.website_url,
					support_email: klaviyoAccount.attributes.contact_information.default_sender_email,
					support_phone: null,
					company_name: klaviyoAccount.attributes.contact_information.organization_name,
					company_website: klaviyoAccount.attributes.contact_information.website_url,
					billing_email: klaviyoAccount.attributes.contact_information.default_sender_email,
					brand_id: brandIntegration.brand_id,
					created_at: new Date().toISOString(),
					extra_data: {
						installation_source: 'direct', //TODO: 'klaviyo'?
					},
				},
			},
		});
	}

	override resource(args: ResourceArgs): ResourceReturn {
		if (args.integration.integration_id !== Constants.Integration.KlaviyoIntegrationId) {
			throw new Error(`Invalid integration id, got ${args.integration.integration_id} expected ${Klaviyo.id}`);
		}

		const brandIntegrationSettings = args.integration.settings as BrandIntegrationKlaviyoSettings;

		// If the access token is expired, refresh it
		if (
			brandIntegrationSettings.credentials.expires_at &&
			new Date(brandIntegrationSettings.credentials.expires_at) < new Date() &&
			brandIntegrationSettings.credentials.refresh_token
		) {
			this.refreshAccessToken(brandIntegrationSettings.credentials.refresh_token)
				.then((klaviyoTokenResponse) => {
					brandIntegrationSettings.credentials.access_token = klaviyoTokenResponse.access_token;
					brandIntegrationSettings.credentials.refresh_token = klaviyoTokenResponse.refresh_token;
					brandIntegrationSettings.credentials.expires_at = new Date(
						Date.now() + klaviyoTokenResponse.expires_in * 1000
					).toISOString();

					return brandIntegrationSettings;
				})
				.then((brandIntegrationSettings) => {
					this.integrationData.update({
						data: {
							id: args.integration.id,
							settings: brandIntegrationSettings,
						},
					});

					return brandIntegrationSettings;
				})
				.then(() => {
					return {
						fetch: this.createFetch({
							url: this.API_URL,
							headers: {
								Authorization: `Bearer ${brandIntegrationSettings.credentials.access_token}`,
								...this.getKlaviyoHeaders(),
							},
						}),
					};
				})
				.catch((error) => {
					throw new Error(`Failed to refresh access token ${error}`);
				});
		}

		return {
			fetch: this.createFetch({
				url: this.API_URL,
				headers: {
					Authorization: `Bearer ${brandIntegrationSettings.credentials.access_token}`,
					...this.getKlaviyoHeaders(),
				},
			}),
		};
	}

	override async identify(args: IdentifyArgs): Promise<IdentifyReturn> {
		const id = args.identity?.id;
		if (!id) return null;

		const profileResponse = await this.resource({
			integration: args.integration,
		}).fetch(`/profiles/${id}` as '/profiles');
		const profile = profileResponse.data;

		if (!profile || !profile.data.attributes.phone_number) {
			return null;
		}

		const profileAttributes = profile.data.attributes;
		const channels: Partial<DatabaseEntity['contact_channels']>[] = [];
		const contact = {
			id: uuid(),
			external_id: profile.data.id,
			buyer_accepts_sms_marketing: profileAttributes.subscriptions?.sms?.marketing?.can_receive_sms_marketing,
			family_name: profileAttributes.last_name,
			given_name: profileAttributes.first_name,
			brand_id: args.integration.brand_id,
			source: 'klaviyo',
			extra_data: JSON.stringify({
				email: profileAttributes.email,
				phone_number: profileAttributes.phone_number,
			}),
		} as DatabaseEntity['contacts'];

		if (profileAttributes.phone_number) {
			channels.push({
				contact_id: contact.id,
				username: profileAttributes.phone_number,
				external_id: profile.data.id,
				compliance_type: 'p2p',
				status: profileAttributes.subscriptions?.sms?.marketing?.can_receive_sms_marketing
					? 'subscribed'
					: 'unsubscribed',
				extra_data: {
					timezone: profileAttributes?.location?.timezone || '',
					source: 'klaviyo',
				},
			});
		}

		if (profileAttributes.email) {
			channels.push({
				contact_id: contact.id,
				username: profileAttributes.email,
				external_id: profile.data.id,
				compliance_type: 'p2p',
				status: profileAttributes.subscriptions?.email?.marketing?.can_receive_email_marketing
					? 'subscribed'
					: 'unsubscribed',
				extra_data: {
					timezone: profileAttributes?.location?.timezone || '',
					source: 'klaviyo',
				},
			});
		}

		return super.identify({
			...args,
			data: {
				contact,
				channels,
			},
		});
	}

	override async handleEvent(props: EventArgs) {
		console.log({ props });
		return super.handleEvent(props);
	}

	async refreshAccessToken(refreshToken: string): Promise<KlaviyoTokenResponse> {
		try {
			return await this.getTokensFromKlaviyo('refresh_token', { refreshToken });
		} catch (error) {
			if (error instanceof KlaviyoInvalidGrantError) {
				throw error;
				// TODO: Disconnect the brand integration
				// Possibly inactive for 90 days or uninstalled
				// https://developers.klaviyo.com/en/docs/set_up_oauth#refresh-token-considerations
			}
			throw new Error('Failed to refresh access token');
		}
	}

	private async getTokensFromKlaviyo(
		grantType: 'authorization_code' | 'refresh_token',
		params: { code?: string; refreshToken?: string; state?: string; codeVerifier?: string }
	): Promise<KlaviyoTokenResponse> {
		const body: Record<string, string> = {
			grant_type: grantType,
		};

		if (grantType === 'authorization_code') {
			if (!params.code || !params.state || !params.codeVerifier) {
				throw new Error('Code, state, and codeVerifier are required for authorization_code grant type');
			}
			body['code'] = params.code;
			body['code_verifier'] = params.codeVerifier;
			body['redirect_uri'] = this.credentials.authCallbackUrl!;
		} else if (grantType === 'refresh_token') {
			if (!params.refreshToken) {
				throw new Error('Refresh token is required for refresh_token grant type');
			}
			body['refresh_token'] = params.refreshToken;
		}

		const request: RequestInit = {
			method: 'POST',
			headers: {
				Authorization: `Basic ${Buffer.from(
					`${this.credentials.clientId}:${this.credentials.clientSecret}`
				).toString('base64')}`,
				...this.getKlaviyoHeaders(),
			},
			body: JSON.stringify(body),
		};

		const response = await fetch(this.OAUTH_TOKEN_URL, request);
		const tokenResponse: KlaviyoTokenResponse = await response.json();
		if (!response.ok || tokenResponse.error === 'invalid_grant') {
			throw new KlaviyoInvalidGrantError(`Refresh token is expired`);
		}
		return tokenResponse;
	}

	private async exchangeAuthorizationCode(code: string, state: string): Promise<KlaviyoTokenResponse> {
		try {
			return await this.getTokensFromKlaviyo('authorization_code', { code, state });
		} catch (error) {
			throw new Error('Failed to exchange authorization code');
		}
	}

	private getKlaviyoHeaders() {
		return {
			revision: this.KLAVIYO_REVISION,
			accept: 'application/vnd.api+json',
			'User-Agent': 'vyg.ai/1.0',
			'Content-Type': 'application/x-www-form-urlencoded',
		};
	}

	/**
	 * Generate a code verifier and code challenge for the Klaviyo OAuth flow
	 * Klaviyo requires PKCE, https://developers.klaviyo.com/en/docs/set_up_oauth#pkce-and-code-challenges
	 * @returns An object containing the code verifier and code challenge
	 */
	generateCode() {
		const base64URLEncode = (buffer: Buffer): string => {
			return buffer.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
		};

		const verifier = base64URLEncode(randomBytes(32));
		const challenge = base64URLEncode(createHash('sha256').update(verifier).digest());
		return {
			codeVerifier: verifier,
			codeChallenge: challenge,
		};
	}
}

interface KlaviyoAuthProps extends CommonArgs {
	params: {
		code: string;
		state: string;
	};
}

interface KlaviyoTokenResponse {
	access_token: string;
	refresh_token: string;
	token_type: string;
	expires_in: number;
	scope: string;
	error?: string;
}

interface KlaviyoAccount {
	type: string;
	id: string;
	attributes: {
		test_account: boolean;
		contact_information: {
			default_sender_name: string;
			default_sender_email: string;
			website_url: string;
			organization_name: string;
			street_address: {
				address1: string;
				address2: string;
				city: string;
				region: string;
				country: string;
				zip: string;
			};
		};
		industry: string | null;
		timezone: string;
		preferred_currency: string;
		public_api_key: string;
		locale: string;
	};
}

class KlaviyoInvalidGrantError extends Error {
	constructor(message: string) {
		super(message);
		this.name = 'KlaviyoInvalidGrantError';
	}
}
