// !OVERWRITE
// Moved from app/web-client. This should be auto generated.
import { z } from 'zod';

export const knowledgebaseSchema = z.object({
	brandId: z.string({ message: 'No Brand found.' }),
	title: z.string({ message: 'Title is required.' })?.min(1, { message: 'Title is required' }),
	content: z.string({ message: 'Content is required.' }).min(1, { message: 'Content is required' }),
	authorId: z.string({ message: 'No user found.' }),
	source: z.enum(['web', 'flow']),
	status: z.enum(['draft', 'published', 'unpublished']),
	flowId: z.string().optional(),
});

export const updateKnowledgebaseSchema = z.object({
	title: z.string({ message: 'Title is required.' })?.min(1, { message: 'Title is required' }),
	content: z.string({ message: 'Content is required.' }).min(1, { message: 'Content is required' }),
	id: z.string({ message: 'No Knowledgebase entry found.' }),
});

export const updateKnowledgebaseStatusSchema = z.object({
	id: z.string({ message: 'No Knowledgebase entry found.' }),
	status: z.enum(['draft', 'published', 'unpublished']),
});

export const knowledgebaseJsonSchema = z.object({
	extra_data: z
		.object({
			weight: z.number({ message: 'Weight is required.' }),
		})
		.optional(),
});
