const gql = String.raw;

export const KB_CARD_WITH_BRAND = `
    *,brands(*)
`;
export const KB_CARD_STATS = gql`
	query KBCardStats {
		cube {
			kb_cards {
				created_at {
					day
				}
				count
			}
		}
	}
`;

export const KB_LIST = gql`
	query KBList {
		cube(limit: 100, orderBy: { kb_cards: { weight: asc } }) {
			kb_cards {
				id
				title
				content
				source
				status
				weight
				created_at {
					value
				}
				count
			}
		}
	}
`;

export const KB_ITEM = gql`
	query KBItem($id: String) {
		cube(limit: 1, where: { kb_cards: { id: { equals: $id } } }) {
			kb_cards {
				id
				title
				content
				source
				status
				created_at {
					value
				}
			}
		}
	}
`;
