import { v4 as uuid } from 'uuid';

import type { DatabaseEntity, DatabaseEnum, PostgrestClientType } from '@voyage-lab/db';
import { Schema, type TypesT } from '@voyage-lab/schema';

import * as WorkflowSchema from '../schema';
import { WORKFLOW_TEMPLATE } from './default';

export class WorkflowData {
	#dbClient: PostgrestClientType;

	constructor(dbClient: PostgrestClientType) {
		this.#dbClient = dbClient;
	}

	async getSingle(props: { workflowId: string }) {
		const { data, error } = await this.#dbClient.from('workflows').select('*').eq('id', props.workflowId).single();

		return data as typeof data & TypesT.WorkflowJson;
	}

	/** Get flows using query */
	async get(props?: { ids?: string[]; brandId?: string }) {
		const query = this.#dbClient.from('workflows').select('*');

		if (props?.ids) query.in('id', props?.ids);
		return query;
	}

	/** Get Single flow with brand_integrations */
	async getWithBrandIntegration(props: { ids?: string[] }) {
		const query = this.#dbClient
			.from('workflows')
			.select('*,brand_integrations(*,brands(*))')
			.eq('is_paused', false)
			.eq('type', 'checkout')
			.or('starts_at.is.null,starts_at.lt.now()')
			.or('ends_at.is.null,ends_at.gt.now()');

		if (props?.ids) query.in('id', props?.ids);

		return await query;
	}

	/** Get flows using query */
	async getDiscountRule(props?: { ids?: string[] }) {
		const query = this.#dbClient.from('discount_rules').select('*');

		if (props?.ids) query.in('id', props?.ids);
		return query;
	}

	/** Get Single Discount Rule */
	async getSingleDiscountRule(props: { id: string }) {
		return this.#dbClient.from('discount_rules').select('*').eq('id', props.id).single();
	}

	async getList(props: { brandId: string; limit: number; offset: number; isPaused?: boolean }) {
		const query = this.#dbClient
			.from('workflows')
			.select('id, name, is_paused, status, brand_integrations(brand_id)')
			.eq('brand_integrations.brand_id', props?.brandId)
			.not('brand_integrations', 'is', null);

		if (props.isPaused !== undefined) query.eq('is_paused', props.isPaused);

		return query.order('created_at', { ascending: false }).range(props.offset, props.offset + props.limit - 1);
	}

	async getTotalActiveWorkflow(props: { brandId: string }) {
		const query = await this.#dbClient
			.from('workflows')
			.select('id, is_paused, brand_integrations(brand_id)', { count: 'exact', head: true })
			.eq('brand_integrations.brand_id', props?.brandId)
			.not('brand_integrations', 'is', null)
			.eq('is_paused', false);

		return query;
	}

	async getSingleWorkflowEvent(props: { workflowId: string }) {
		const { data } = await this.#dbClient
			.from('workflow_goal_state_change_events')
			.select('*')
			.eq('workflow_id', props.workflowId)
			.order('created_at', { ascending: false })
			.single();

		return data;
	}

	async create(props: { data: DatabaseEntity['workflows'] }) {
		// Validation
		const validData = WorkflowSchema.workflowCreate.omit({ legacy_migrated_at: true }).parse(props.data);

		const discountRule = validData?.action?.discount;

		if (!discountRule) throw new Error('No discount rule provided');

		const { data: brandIntegration } = await this.#dbClient
			.from('brand_integrations')
			.select('*')
			.eq('id', validData.brand_integration_id)
			.single();

		if (!brandIntegration?.settings) throw new Error('Brand integration not found');

		const { data: createdDiscountRule } = await this.#dbClient
			.from('discount_rules')
			.insert({
				id: uuid(),
				type: 'percentage',
				brand_id: brandIntegration.brand_id,
				legacy_discount_code: '',
				extra_data: discountRule.config,
				value: discountRule.value || 0,
				duration: 10,
				created_at: new Date().toISOString(),
				updated_at: new Date().toISOString(),
			})
			.select('*')
			.single();

		if (!createdDiscountRule) throw new Error('Failed to create discount rule');

		validData.discount_rule_id = createdDiscountRule.id;

		return this.#dbClient
			.from('workflows')
			.insert(validData as DatabaseEntity['workflows'])
			.select('*')
			.single();
	}

	update(props: { data: Partial<DatabaseEntity['workflows']> & Required<Pick<DatabaseEntity['workflows'], 'id'>> }) {
		return this.#dbClient.from('workflows').update(props.data).eq('id', props.data.id).select('*').single();
	}

	async createDefault(props: { brandIntegrationId: string; discountPercentage: number }) {
		const defaultWorkflow = await this.getTemplate({
			brandIntegrationId: props.brandIntegrationId,
			discountPercentage: props.discountPercentage,
		});

		console.debug('Creating default workflow', defaultWorkflow);

		return this.create({ data: defaultWorkflow });
	}

	async createEvent(props: { data: DatabaseEntity<'insert'>['workflow_goal_state_change_events'] }) {
		// Validation
		// const validData = Schema.workflowGoalStateChangeEventsInputSchema.parse(props.data);
		const validData = props.data; // TODO: Removing validation due to Zod erroring out on Date

		return this.#dbClient
			.from('workflow_goal_state_change_events')
			.insert(validData as unknown as DatabaseEntity['workflow_goal_state_change_events'])
			.select('*')
			.single();
	}

	async getDefaultDiscountPercentage() {
		let discountPercentage = WorkflowData.DEFAULT_DISCOUNT_PERCENTAGE;
		try {
			const defaultTemplate = await this.getTemplate({ brandIntegrationId: '' });

			const discountRule = defaultTemplate?.action?.discount;
			discountPercentage = discountRule?.value || WorkflowData.DEFAULT_DISCOUNT_PERCENTAGE;
		} catch (error) {
			console.error('Failed to get default discount percentage', error);
		}

		return discountPercentage;
	}

	async getDraftWorkflow(props: { brandIntegrationId: string }) {
		return this.#dbClient
			.from('workflows')
			.select('*')
			.eq('brand_integration_id', props.brandIntegrationId)
			.eq('status', 'draft')
			.order('created_at', { ascending: false })
			.limit(1)
			.maybeSingle();
	}

	async generateIncrementalName(props: { name: string; brandIntegrationId: string }) {
		const cleanedName = props.name.replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s\d+$/, '');

		const existingWorkflows = await this.#dbClient
			.from('workflows')
			.select('*', { count: 'exact', head: true })
			.eq('brand_integration_id', props.brandIntegrationId)
			.ilike('name', `${cleanedName.toLowerCase()}%`);

		const suffix = existingWorkflows.count ? existingWorkflows.count + 1 : 1;
		return `${cleanedName} ${suffix}`;
	}

	async getTemplate(props: {
		brandIntegrationId: string;
		discountPercentage?: number;
		type?: DatabaseEnum['t_workflows_type'];
	}) {
		const templateWorkflowRes = await this.#dbClient
			.from('workflows')
			.select('*,brand_integrations!inner(brands!inner(tenants!inner(id,type)))')
			.eq('brand_integrations.brands.tenants.type', 'template')
			.eq('type', props.type || 'checkout')
			.limit(1)
			.maybeSingle();

		let templateWorkflow = templateWorkflowRes.data as DatabaseEntity['workflows'];
		const validData = WorkflowSchema.workflowCreate.omit({ legacy_migrated_at: true }).safeParse(templateWorkflow);

		// Template tenant not found, use default static workflow
		if (!templateWorkflow || !validData.success) {
			console.error('No template workflow found, using default', templateWorkflowRes);
			const defaultWorkflow = structuredClone(WorkflowData.WORKFLOW_TEMPLATE[props.type || 'checkout']);
			templateWorkflow = defaultWorkflow as DatabaseEntity['workflows'];
		}

		// Generate dynamic values
		templateWorkflow.id = uuid();
		templateWorkflow.type = props.type || templateWorkflow.type;
		templateWorkflow.created_at = new Date().toISOString();
		templateWorkflow.updated_at = new Date().toISOString();
		templateWorkflow.brand_integration_id = props.brandIntegrationId;
		templateWorkflow.name = templateWorkflow.name || WorkflowData.DEFAULT_NAME[templateWorkflow.type];
		templateWorkflow.is_paused = true;

		if (templateWorkflow?.action?.discount && props.discountPercentage !== undefined) {
			templateWorkflow.action.discount.value = props.discountPercentage;
		}

		if (!templateWorkflow) throw new Error('No template workflow found');

		return templateWorkflow;
	}

	public static WORKFLOW_TEMPLATE = WORKFLOW_TEMPLATE;
	public static DEFAULT_DISCOUNT_PERCENTAGE = 15;
	public static DEFAULT_NAME: {
		[key in DatabaseEnum['t_workflows_type']]: string;
	} = {
		checkout: 'Abandoned Checkout',
		order: 'Post Purchase',
		cart: 'Cart Flow',
		schedule: 'Scheduled Flow',
		tap_to_text: 'Tap to Text',
	};
}

export type BrandDetailsT = Awaited<ReturnType<WorkflowData['getSingle']>>;
export type WorkflowT = Awaited<ReturnType<WorkflowData['getSingle']>>;
