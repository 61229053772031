import { ZodError } from 'zod';

export class ValidationError extends Error {
	#zodError?: ZodError;

	/** Create a new ValidationError instance */
	constructor(error: string | ZodError | ValidationError) {
		const message = typeof error === 'string' ? error : error.message;
		super(message);

		if (error instanceof ZodError) {
			this.#zodError = error;
		} else if (error instanceof ValidationError) {
			return error;
		}

		this.name = 'ValidationError';
	}

	/** Parse field errors from searchParams */
	get params() {
		const param = new URLSearchParams();
		const errors = this.#zodError?.flatten().fieldErrors || {};
		param.set('validation-errors', JSON.stringify(errors));
		return param;
	}

	/** Static method to check if the error is a validation error */
	static [Symbol.hasInstance](instance: unknown): instance is ValidationError {
		return instance instanceof ZodError;
	}

	/** Create a new ValidationError instance */
	static create(error: ZodError | string | ValidationError) {
		return new ValidationError(error);
	}

	/** Create query params in format ?validation-errors={...} */
	static createParams(error: ZodError | string | ValidationError) {
		return ValidationError.create(error).params.toString();
	}

	/** Create the query string directly including ? */
	static createQueryStr(error: ZodError | Error) {
		if (error instanceof ZodError) {
			console.log('zod error instance');
			return '?' + ValidationError.createParams(error);
		} else {
			console.log('normal error instance');
			return '?' + new URLSearchParams({ error: error.message }).toString();
		}
	}
}

export type AppErrorArea = 'conversation' | 'account';
export type AppErrorName =
	| 'AppError'
	| 'AppValidationError'
	| 'UnauthorizedError'
	| 'UnauthenticatedError'
	| 'ClientError';

export class AppError extends Error {
	area?: AppErrorArea;
	source?: string;
	override name: AppErrorName;

	constructor(
		message: string,
		name: AppErrorName = 'AppError',
		{ area, source }: { area?: AppErrorArea; source?: string } = {}
	) {
		super(message);
		this.name = name;
		this.area = area;
		this.source = source;
	}
}
