import { getPhoneObject } from './utils';

/**
 * Pretty formats a phone number to US format.
 * @param phoneNumber The phone number to format
 * @returns `+1 (NXX) NXX-XXXX`
 * @returns `null` if phone number is invalid
 * @example `prettyFormat(1234567890) // +1 (123) 456-7890`
 * @example `prettyFormat('+1 123 456 7890') // +1 (123) 456-7890`
 */
export function prettyFormat(phoneNumber: string | number) {
	const phoneObj = getPhoneObject(phoneNumber);

	if (!phoneObj) return null;

	const { countryCode, areaCode, officeCode, lineNumber } = phoneObj;

	return `${countryCode} (${areaCode}) ${officeCode}-${lineNumber}`;
}

/**
 * Returns a phone number in E.164 format.
 * @param phoneNumber The phone number to format
 * @returns `+1NXXXXXXXXX`
 * @returns `null` if phone number is invalid
 * @example `e164Format(1234567890) // +11234567890`
 * @example `e164Format('+1 123 456 7890') // +11234567890`
 * @example `e164Format('invalid phone') // null`
 */
export function e164Format(phoneNumber: string | number | undefined) {
	if (!phoneNumber) return null;
	const phoneObj = getPhoneObject(phoneNumber);

	if (!phoneObj) return null;

	const { countryCode, areaCode, officeCode, lineNumber } = phoneObj;

	return `${countryCode}${areaCode}${officeCode}${lineNumber}`;
}
