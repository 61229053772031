import type { Client } from 'pg';

import type { ConversationData } from '@voyage-lab/core-conversation';
import type { DatabaseEntity, DatabaseEnum, PostgrestClientType } from '@voyage-lab/db';
import type { TypesT } from '@voyage-lab/schema';

import type { WorkflowData } from '../data';

// Simple type definition for HydratedMessage
type SimpleHydratedMessage = {
	prepare: (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		messages: any[],
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		data: any
	) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		render: (renderData: any) => Promise<string>;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		commitShortenedUrl: (urlData: any) => Promise<void>;
	};
};

// New interface for integrationProvider
interface IntegrationProvider {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	generateDiscount: (discountRule: any, settings: any) => Promise<any>;
}

export type FlowProcessorCandidate = {
	/* ID of either a cart, order, checkout */
	id: string;
	flow_ids: string[];
	cart_id?: string | null;
	order_id?: string | null;
	checkout_id?: string | null;
	contact_id: string;
	event: DatabaseEntity['orders'] | DatabaseEntity['checkouts'] | DatabaseEntity['carts'];
};

export type FlowProcessorExecuteCandidate = FlowProcessorCandidate & {
	contact: DatabaseEntity['contacts'];
	channel: DatabaseEntity['contact_channels'];
};

export type FlowProcessorProcessResult =
	| (FlowProcessorExecuteCandidate & {
			flow: Flow;
			flow_id: string;
			flowEvents: DatabaseEntity['workflow_goal_state_change_events'][];
	  })
	| null;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FlowProcessorExecuteResult = any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FlowProcessorExecuteActionResult = any;

export abstract class FlowProcessor {
	static type: DatabaseEnum['t_workflows_type'];

	dbClient: PostgrestClientType;
	pgClient: Client;
	flowData: WorkflowData;
	conversationData: ConversationData;
	/** If true then there won't be any timezone check, message will be sent immediately */
	isTestingEnv: boolean;
	protected hydratedMessage: SimpleHydratedMessage;
	protected integrationProvider: IntegrationProvider;

	// Legacy implementations
	filterEvaluator: FlowProcessorArg['filterEvaluator'];

	/** Constructor */
	constructor({
		pgClient,
		dbClient,
		filterEvaluator,
		flowData,
		hydratedMessage,
		integrationProvider,
		isTestingEnv,
		conversationData,
	}: FlowProcessorArg) {
		this.dbClient = dbClient;
		this.pgClient = pgClient;
		this.flowData = flowData;
		this.filterEvaluator = filterEvaluator;
		this.hydratedMessage = hydratedMessage;
		this.integrationProvider = integrationProvider;
		this.isTestingEnv = !!isTestingEnv;
		this.conversationData = conversationData;
	}

	abstract process(props: { flows: Flow[]; data: FlowProcessorCandidate }): Promise<FlowProcessorProcessResult>;
	abstract execute(props: { flow: Flow; data: FlowProcessorExecuteCandidate }): Promise<FlowProcessorExecuteResult>;
	abstract executeAction(props: {
		flow: Flow;
		data: FlowProcessorExecuteCandidate;
	}): Promise<FlowProcessorExecuteActionResult>;

	abstract getCandidates(): Promise<FlowProcessorCandidate[]>;

	hasEngagedRecently(channel: DatabaseEntity['contact_channels'], reEngagePeriodDays: number) {
		if (!channel.last_engaged_at) return false;

		const lastEngagedAt = new Date(channel.last_engaged_at);
		if (isNaN(lastEngagedAt.getTime())) return false;

		const now = new Date();

		const diff = now.getTime() - lastEngagedAt.getTime();
		const diffInMinutes = diff / 1000 / 60;
		const diffInDays = diffInMinutes / 60 / 24;

		return diffInDays < reEngagePeriodDays;
	}
}

export type Flow = DatabaseEntity['workflows'] & {
	brand_integrations: DatabaseEntity['brand_integrations'] & {
		brands: DatabaseEntity['brands'];
	};
};
export type FlowProcessorArg = {
	pgClient: Client;
	dbClient: PostgrestClientType;
	flowData: WorkflowData;
	conversationData: ConversationData;
	isTestingEnv?: boolean;

	// Legacy implementations
	filterEvaluator: (
		flow: Flow,
		data: {
			contact: DatabaseEntity['contacts'];
			channel: DatabaseEntity['contact_channels'];
			event: FlowProcessorCandidate['event'];
			flows: Flow[];
		}
	) => Promise<boolean>;
	hydratedMessage: SimpleHydratedMessage;
	integrationProvider: IntegrationProvider;
};
