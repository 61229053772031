import { gql } from '@voyage-lab/cube';

export const CONVO_LIST = gql`
	query GetConvoList($limit: Int, $offset: Int, $timezone: String) {
		cube(timezone: $timezone, limit: $limit, offset: $offset) {
			conversations {
				id
			}
		}
	}
`;

export const CONVERSATION_COUNT = gql`
	query GetConvoCount {
		cube {
			conversations {
				count
			}
		}
	}
`;

export const CONVERSATION_FEEDBACKS = gql`
	query GetConversationFeedbacks(
		$limit: Int
		$offset: Int
		$timezone: String
		$orderBy: RootOrderByInput
		$where: RootWhereInput
	) {
		cube(timezone: $timezone, limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
			brands {
				id
				name
				domain
			}
			conversation_feedback {
				id
				message_id
				created_at {
					value
				}
				rating
				comment
			}
			conversations {
				id
				front_id
			}
			contacts {
				full_name
			}
			contact_channels {
				username
			}
		}
	}
`;

export const SINGLE_CONVERSATION_THREAD = gql`
	query getConvoThread($id: String) {
		messages: cube(where: { conversation_messages: { conversation_id: { equals: $id } } }) {
			conversation_messages {
				id
				body
				conversation_id
				created_at {
					value
				}
				sent_at {
					value
				}
				scheduled_for {
					value
				}
				direction
			}
			conversation_feedback {
				id
			}
		}

		events: cube(where: { workflow_goal_state_change_events: { conversation_id: { equals: $id } } }) {
			workflow_goal_state_change_events {
				state
				state_display
				created_at {
					value
				}
			}
		}
	}
`;

export const ALL_CONVERSATIONS = gql`
	query GetAllConvo($brandId: String) {
		cube(limit: 10) {
			conversations(where: { brand_id: { equals: $brandId } }) {
				id
				created_at {
					value
				}
			}
			contact_channels {
				username
			}
			contacts {
				full_name
			}
			brands {
				name
			}
			workflow_goal_state_change_events(orderBy: { created_at: desc }) {
				state_display
			}
		}
	}
`;

export const SINGLE_CONVERSATION = gql`
	query GetConvoSingle($id: String) {
		cube(limit: 1) {
			conversations(where: { id: { equals: $id } }) {
				id
				created_at {
					value
				}
			}
			contact_channels {
				username
			}
			contacts {
				full_name
			}
			brands {
				name
			}
			workflow_goal_state_change_events(orderBy: { created_at: desc }) {
				state_display
			}
		}
	}
`;

export const LATEST_SINGLE_CONVERSATION = gql`
	query GetConvoLatest {
		cube(limit: 1) {
			conversations(orderBy: { created_at: desc }) {
				id
				created_at {
					value
				}
			}
			contact_channels {
				username
			}
			contacts {
				full_name
			}
			brands {
				name
			}
			workflow_goal_state_change_events(orderBy: { created_at: desc }) {
				state_display
			}
		}
	}
`;

export const NEXT_AND_PREV_CONVERSATION = gql`
	query getPrevAndNext($datetime: String, $id: String) {
		prev: cube(
			where: {
				conversations: { AND: [{ created_at: { beforeOrOnDate: $datetime } }, { id: { notEquals: $id } }] }
			}
			limit: 1
			orderBy: { conversations: { created_at: asc } }
		) {
			conversations(orderBy: { created_at: asc }) {
				id
				created_at {
					value
				}
			}
		}

		next: cube(
			where: {
				conversations: { AND: [{ created_at: { afterOrOnDate: $datetime } }, { id: { notEquals: $id } }] }
			}
			limit: 1
			orderBy: { conversations: { created_at: desc } }
		) {
			conversations(orderBy: { created_at: desc }) {
				id
				created_at {
					value
				}
			}
		}
	}
`;

export const CONVERSATION_LIST = gql`
	query GetConversationList($where: RootWhereInput, $limit: Int = 100) {
		cube(limit: $limit, where: $where, orderBy: { conversations: { updated_at: desc } }) {
			contacts {
				full_name
			}
			conversations {
				id
				created_at {
					value
				}
				updated_at {
					value
				}
			}
			checkouts {
				state
			}
			contact_channels {
				username
			}
		}
	}
`;

export const CONVERSATION_MESSAGE_COUNT = gql`
	query GetConversationMessageCount($conversationIds: [String]) {
		cube(where: { conversation_messages: { conversation_id: { in: $conversationIds } } }) {
			conversation_messages {
				conversation_id
				count
			}
		}
	}
`;

export const CONVERSATION_ID_LIST = gql`
	query GetConversationIDList($limit: Int = 10) {
		cube(limit: $limit, orderBy: { conversations: { updated_at: desc } }) {
			conversations {
				id
			}
			checkouts {
				state
			}
			contact_channels {
				username
			}
		}
	}
`;

// export const CHECKOUT_INFO = gql`
// 	query getCheckoutInfo($id: String) {
// 		orders: cube(where: { conversations: { id: { equals: $id } } }) {
// 			orders {
// 				total
// 			}
// 		}

// 		checkout: cube(where: { conversations: { id: { equals: $id } } }) {
// 			checkout_details
// 		}

// 		customer: cube(where: { conversations: { id: { equals: $id } } }) {
// 			contacts{
// 				created_at{
// 					value
// 				}
// 			}
// 		}
// 	}
// `;
