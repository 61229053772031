import type { DatabaseEntity } from '@voyage-lab/db';

export abstract class Payment {
	abstract createCustomPlan(props: {
		planId: string;
		rate: string;
		price: string;
	}): Promise<DatabaseEntity['plans'] | null>;

	abstract resetSubscriptionCheckout(subscriptionId: string): void;

	abstract updatePendingSetupSubscription(
		subscription: DatabaseEntity['subscriptions']
	): Promise<DatabaseEntity['subscriptions'] | null>;

	abstract subscribe(props: {
		brandId: string;
		planId: string;
		billingEmail: string;
		userName?: string;
		userPhone?: string;
		trialDays?: number;
	}): Promise<{ url?: string | null }>;

	abstract updateSubscription(props: { subscriptionId: string; planId: string }): Promise<null>;

	abstract cancelSubscription(props: { subscriptionId: string }): Promise<null>;

	abstract editBillingInfo(props: { brandId: string; returnUrl: string }): Promise<string>;
}
