// Generated by ts-to-zod
import { z } from 'zod';

export const jsonSchema = z.unknown();

export const tBrandAuditLogIssueTypeSchema = z.union([
	z.literal('optional_phone_label_not_compliant'),
	z.literal('phone_label_not_compliant'),
]);

export const tBrandAuditLogStatusSchema = z.union([
	z.literal('high'),
	z.literal('low'),
	z.literal('medium'),
	z.literal('none'),
	z.literal('not_enforced'),
	z.literal('pass'),
]);

export const tBrandIntegrationsStatusSchema = z.union([
	z.literal('admin_disabled'),
	z.literal('audit_failure'),
	z.literal('auth_error'),
	z.literal('connected'),
	z.literal('disconnected'),
	z.literal('pending_setup'),
]);

export const tBrandsAttributionTypeSchema = z.union([z.literal('code_and_link'), z.literal('code_only')]);

export const tCartsStateSchema = z.union([z.literal('complete'), z.literal('pending')]);

export const tCheckoutStateSchema = z.union([
	z.literal('abandoned'),
	z.literal('abandoned_processing'),
	z.literal('discount_sent'),
	z.literal('errored'),
	z.literal('initial'),
	z.literal('message_queued'),
	z.literal('messaged'),
	z.literal('recovered'),
	z.literal('skipped'),
]);

export const tContactChannelsComplianceSchema = z.union([
	z.literal('a2p'),
	z.literal('na'),
	z.literal('p2p'),
	z.literal('tcpa'),
]);

export const tContactChannelsStatusSchema = z.union([
	z.literal('incapable'),
	z.literal('retry_threshold_met'),
	z.literal('subscribed'),
	z.literal('unsubscribed'),
]);

export const tContactsSourceSchema = z.union([z.literal('csv'), z.literal('manual'), z.literal('shopify')]);

export const tConversationMessagesDirectionSchema = z.union([z.literal('incoming'), z.literal('outgoing')]);

export const tConversationMessagesStatusSchema = z.union([
	z.literal('accepted'),
	z.literal('agent_queued'),
	z.literal('agent_skipped'),
	z.literal('auto_queued'),
	z.literal('delivered'),
	z.literal('draft_created'),
	z.literal('failed'),
	z.literal('received'),
	z.literal('sent'),
	z.literal('undelivered'),
]);

export const tBrandsStatusSchema = z.union([
	z.literal('active'),
	z.literal('archived'),
	z.literal('inactive'),
	z.literal('uninstalled'),
]);

export const tCountryCodesSchema = z.union([
	z.literal('AD'),
	z.literal('AE'),
	z.literal('AF'),
	z.literal('AG'),
	z.literal('AI'),
	z.literal('AL'),
	z.literal('AM'),
	z.literal('AO'),
	z.literal('AQ'),
	z.literal('AR'),
	z.literal('AS'),
	z.literal('AT'),
	z.literal('AU'),
	z.literal('AW'),
	z.literal('AX'),
	z.literal('AZ'),
	z.literal('BA'),
	z.literal('BB'),
	z.literal('BD'),
	z.literal('BE'),
	z.literal('BF'),
	z.literal('BG'),
	z.literal('BH'),
	z.literal('BI'),
	z.literal('BJ'),
	z.literal('BL'),
	z.literal('BM'),
	z.literal('BN'),
	z.literal('BO'),
	z.literal('BQ'),
	z.literal('BR'),
	z.literal('BS'),
	z.literal('BT'),
	z.literal('BV'),
	z.literal('BW'),
	z.literal('BY'),
	z.literal('BZ'),
	z.literal('CA'),
	z.literal('CC'),
	z.literal('CD'),
	z.literal('CF'),
	z.literal('CG'),
	z.literal('CH'),
	z.literal('CI'),
	z.literal('CK'),
	z.literal('CL'),
	z.literal('CM'),
	z.literal('CN'),
	z.literal('CO'),
	z.literal('CR'),
	z.literal('CU'),
	z.literal('CV'),
	z.literal('CW'),
	z.literal('CX'),
	z.literal('CY'),
	z.literal('CZ'),
	z.literal('DE'),
	z.literal('DJ'),
	z.literal('DK'),
	z.literal('DM'),
	z.literal('DO'),
	z.literal('DZ'),
	z.literal('EC'),
	z.literal('EE'),
	z.literal('EG'),
	z.literal('EH'),
	z.literal('ER'),
	z.literal('ES'),
	z.literal('ET'),
	z.literal('EU'),
	z.literal('FI'),
	z.literal('FJ'),
	z.literal('FK'),
	z.literal('FM'),
	z.literal('FO'),
	z.literal('FR'),
	z.literal('GA'),
	z.literal('GB'),
	z.literal('GD'),
	z.literal('GE'),
	z.literal('GF'),
	z.literal('GG'),
	z.literal('GH'),
	z.literal('GI'),
	z.literal('GL'),
	z.literal('GM'),
	z.literal('GN'),
	z.literal('GP'),
	z.literal('GQ'),
	z.literal('GR'),
	z.literal('GS'),
	z.literal('GT'),
	z.literal('GU'),
	z.literal('GW'),
	z.literal('GY'),
	z.literal('HK'),
	z.literal('HM'),
	z.literal('HN'),
	z.literal('HR'),
	z.literal('HT'),
	z.literal('HU'),
	z.literal('ID'),
	z.literal('IE'),
	z.literal('IL'),
	z.literal('IM'),
	z.literal('IN'),
	z.literal('IO'),
	z.literal('IQ'),
	z.literal('IR'),
	z.literal('IS'),
	z.literal('IT'),
	z.literal('JE'),
	z.literal('JM'),
	z.literal('JO'),
	z.literal('JP'),
	z.literal('KE'),
	z.literal('KG'),
	z.literal('KH'),
	z.literal('KI'),
	z.literal('KM'),
	z.literal('KN'),
	z.literal('KP'),
	z.literal('KR'),
	z.literal('KW'),
	z.literal('KY'),
	z.literal('KZ'),
	z.literal('LA'),
	z.literal('LB'),
	z.literal('LC'),
	z.literal('LI'),
	z.literal('LK'),
	z.literal('LR'),
	z.literal('LS'),
	z.literal('LT'),
	z.literal('LU'),
	z.literal('LV'),
	z.literal('LY'),
	z.literal('MA'),
	z.literal('MC'),
	z.literal('MD'),
	z.literal('ME'),
	z.literal('MF'),
	z.literal('MG'),
	z.literal('MH'),
	z.literal('MK'),
	z.literal('ML'),
	z.literal('MM'),
	z.literal('MN'),
	z.literal('MO'),
	z.literal('MP'),
	z.literal('MQ'),
	z.literal('MR'),
	z.literal('MS'),
	z.literal('MT'),
	z.literal('MU'),
	z.literal('MV'),
	z.literal('MW'),
	z.literal('MX'),
	z.literal('MY'),
	z.literal('MZ'),
	z.literal('NA'),
	z.literal('NC'),
	z.literal('NE'),
	z.literal('NF'),
	z.literal('NG'),
	z.literal('NI'),
	z.literal('NL'),
	z.literal('NO'),
	z.literal('NP'),
	z.literal('NR'),
	z.literal('NU'),
	z.literal('NZ'),
	z.literal('PA'),
	z.literal('PE'),
	z.literal('PF'),
	z.literal('PG'),
	z.literal('PH'),
	z.literal('PK'),
	z.literal('PL'),
	z.literal('PM'),
	z.literal('PN'),
	z.literal('PR'),
	z.literal('PS'),
	z.literal('PT'),
	z.literal('PW'),
	z.literal('PY'),
	z.literal('QA'),
	z.literal('RE'),
	z.literal('RO'),
	z.literal('RS'),
	z.literal('RU'),
	z.literal('RW'),
	z.literal('SA'),
	z.literal('SB'),
	z.literal('SC'),
	z.literal('SD'),
	z.literal('SE'),
	z.literal('SG'),
	z.literal('SH'),
	z.literal('SI'),
	z.literal('SJ'),
	z.literal('SK'),
	z.literal('SL'),
	z.literal('SM'),
	z.literal('SN'),
	z.literal('SO'),
	z.literal('SR'),
	z.literal('SS'),
	z.literal('ST'),
	z.literal('SV'),
	z.literal('SX'),
	z.literal('SY'),
	z.literal('SZ'),
	z.literal('TC'),
	z.literal('TD'),
	z.literal('TF'),
	z.literal('TG'),
	z.literal('TH'),
	z.literal('TJ'),
	z.literal('TK'),
	z.literal('TL'),
	z.literal('TM'),
	z.literal('TN'),
	z.literal('TO'),
	z.literal('TR'),
	z.literal('TT'),
	z.literal('TV'),
	z.literal('TW'),
	z.literal('TZ'),
	z.literal('UA'),
	z.literal('UG'),
	z.literal('UM'),
	z.literal('US'),
	z.literal('UY'),
	z.literal('UZ'),
	z.literal('VA'),
	z.literal('VC'),
	z.literal('VE'),
	z.literal('VG'),
	z.literal('VI'),
	z.literal('VN'),
	z.literal('VU'),
	z.literal('WF'),
	z.literal('WS'),
	z.literal('XK'),
	z.literal('YE'),
	z.literal('YT'),
	z.literal('ZA'),
	z.literal('ZM'),
	z.literal('ZW'),
]);

export const tCurrencyCodesSchema = z.union([
	z.literal('AED'),
	z.literal('AFN'),
	z.literal('ALL'),
	z.literal('AMD'),
	z.literal('ANG'),
	z.literal('AOA'),
	z.literal('ARS'),
	z.literal('AUD'),
	z.literal('AWG'),
	z.literal('AZN'),
	z.literal('BAM'),
	z.literal('BBD'),
	z.literal('BDT'),
	z.literal('BGN'),
	z.literal('BHD'),
	z.literal('BIF'),
	z.literal('BMD'),
	z.literal('BND'),
	z.literal('BOB'),
	z.literal('BOV'),
	z.literal('BRL'),
	z.literal('BSD'),
	z.literal('BTN'),
	z.literal('BWP'),
	z.literal('BYN'),
	z.literal('BZD'),
	z.literal('CAD'),
	z.literal('CDF'),
	z.literal('CHE'),
	z.literal('CHF'),
	z.literal('CHW'),
	z.literal('CLF'),
	z.literal('CLP'),
	z.literal('CNY'),
	z.literal('COP'),
	z.literal('COU'),
	z.literal('CRC'),
	z.literal('CUC'),
	z.literal('CUP'),
	z.literal('CVE'),
	z.literal('CZK'),
	z.literal('DJF'),
	z.literal('DKK'),
	z.literal('DOP'),
	z.literal('DZD'),
	z.literal('EGP'),
	z.literal('ERN'),
	z.literal('ETB'),
	z.literal('EUR'),
	z.literal('FJD'),
	z.literal('FKP'),
	z.literal('GBP'),
	z.literal('GEL'),
	z.literal('GHS'),
	z.literal('GIP'),
	z.literal('GMD'),
	z.literal('GNF'),
	z.literal('GTQ'),
	z.literal('GYD'),
	z.literal('HKD'),
	z.literal('HNL'),
	z.literal('HRK'),
	z.literal('HTG'),
	z.literal('HUF'),
	z.literal('IDR'),
	z.literal('ILS'),
	z.literal('INR'),
	z.literal('IQD'),
	z.literal('IRR'),
	z.literal('ISK'),
	z.literal('JMD'),
	z.literal('JOD'),
	z.literal('JPY'),
	z.literal('KES'),
	z.literal('KGS'),
	z.literal('KHR'),
	z.literal('KMF'),
	z.literal('KPW'),
	z.literal('KRW'),
	z.literal('KWD'),
	z.literal('KYD'),
	z.literal('KZT'),
	z.literal('LAK'),
	z.literal('LBP'),
	z.literal('LKR'),
	z.literal('LRD'),
	z.literal('LSL'),
	z.literal('LYD'),
	z.literal('MAD'),
	z.literal('MDL'),
	z.literal('MGA'),
	z.literal('MKD'),
	z.literal('MMK'),
	z.literal('MNT'),
	z.literal('MOP'),
	z.literal('MRU'),
	z.literal('MUR'),
	z.literal('MVR'),
	z.literal('MWK'),
	z.literal('MXN'),
	z.literal('MYR'),
	z.literal('MZN'),
	z.literal('NAD'),
	z.literal('NGN'),
	z.literal('NIO'),
	z.literal('NOK'),
	z.literal('NPR'),
	z.literal('NZD'),
	z.literal('OMR'),
	z.literal('PAB'),
	z.literal('PEN'),
	z.literal('PGK'),
	z.literal('PHP'),
	z.literal('PKR'),
	z.literal('PLN'),
	z.literal('PYG'),
	z.literal('QAR'),
	z.literal('RON'),
	z.literal('RSD'),
	z.literal('RUB'),
	z.literal('RWF'),
	z.literal('SAR'),
	z.literal('SBD'),
	z.literal('SCR'),
	z.literal('SDG'),
	z.literal('SEK'),
	z.literal('SGD'),
	z.literal('SHP'),
	z.literal('SLL'),
	z.literal('SOS'),
	z.literal('SRD'),
	z.literal('SSP'),
	z.literal('STN'),
	z.literal('SVC'),
	z.literal('SYP'),
	z.literal('SZL'),
	z.literal('THB'),
	z.literal('TJS'),
	z.literal('TMT'),
	z.literal('TND'),
	z.literal('TOP'),
	z.literal('TRY'),
	z.literal('TTD'),
	z.literal('TWD'),
	z.literal('TZS'),
	z.literal('UAH'),
	z.literal('UGX'),
	z.literal('USD'),
	z.literal('USN'),
	z.literal('UYI'),
	z.literal('UYU'),
	z.literal('UYW'),
	z.literal('UZS'),
	z.literal('VED'),
	z.literal('VES'),
	z.literal('VND'),
	z.literal('VUV'),
	z.literal('WST'),
	z.literal('XAF'),
	z.literal('XAG'),
	z.literal('XAU'),
	z.literal('XBA'),
	z.literal('XBB'),
	z.literal('XBC'),
	z.literal('XBD'),
	z.literal('XCD'),
	z.literal('XDR'),
	z.literal('XOF'),
	z.literal('XPD'),
	z.literal('XPF'),
	z.literal('XPT'),
	z.literal('XSU'),
	z.literal('XTS'),
	z.literal('XUA'),
	z.literal('XXX'),
	z.literal('YER'),
	z.literal('ZAR'),
	z.literal('ZMW'),
	z.literal('ZWL'),
]);

export const tDiscountRulesTypeSchema = z.union([z.literal('fixed'), z.literal('percentage'), z.literal('shipping')]);

export const tIndustrySchema = z.union([
	z.literal('adult_entertainment'),
	z.literal('alcohol'),
	z.literal('art_n_photograhy'),
	z.literal('banking_n_financial_services'),
	z.literal('clothing_n_fashion'),
	z.literal('cosmethics_n_beauty'),
	z.literal('digital_products'),
	z.literal('education_n_professional_services'),
	z.literal('electronics'),
	z.literal('food_services'),
	z.literal('furniture'),
	z.literal('health_n_wellness'),
	z.literal('home_n_garden'),
	z.literal('jewelry_n_accessories'),
	z.literal('real_estate'),
	z.literal('sports_n_recreation'),
	z.literal('tobacco_vaping_or_cannabis'),
	z.literal('toys_n_games'),
	z.literal('transportation'),
	z.literal('travel_n_entertainment'),
	z.literal('internal_testing'),
	z.literal('other'),
]);

export const tIntegrationsCategorySchema = z.union([z.literal('ecommerce'), z.literal('messaging')]);

export const tItemTypesSchema = z.union([z.literal('addon'), z.literal('charge'), z.literal('plan')]);

export const tKbSourceSchema = z.union([z.literal('agent'), z.literal('faq'), z.literal('web'), z.literal('flow')]);

export const tKbStatusSchema = z.union([z.literal('draft'), z.literal('published'), z.literal('unpublished')]);

export const tOrdersBillingStatusSchema = z.union([
	z.literal('billable'),
	z.literal('complete'),
	z.literal('processing'),
]);

export const tOrdersStateSchema = z.union([z.literal('complete'), z.literal('draft'), z.literal('pending')]);

export const tPaymentTypesSchema = z.union([z.literal('not_applicable'), z.literal('shopify'), z.literal('stripe')]);

export const tPermissionsActionSchema = z.union([
	z.literal('create'),
	z.literal('delete'),
	z.literal('list'),
	z.literal('read'),
	z.literal('update'),
]);

export const tPermissionsStateSchema = z.union([z.literal('denied'), z.literal('permitted')]);

export const tPlansFrequencySchema = z.union([z.literal('MONTHLY'), z.literal('YEARLY')]);

export const tPlansStatusSchema = z.union([z.literal('custom'), z.literal('discontinued'), z.literal('published')]);

export const tSubscriptionChargesStatusSchema = z.union([
	z.literal('complete'),
	z.literal('error'),
	z.literal('in_review'),
	z.literal('initial'),
	z.literal('pending'),
	z.literal('queued'),
	z.literal('trialled'),
	z.literal('uninstalled'),
]);

export const tSubscriptionChargesTypeSchema = z.union([z.literal('recurring'), z.literal('usage')]);

export const tSubscriptionCollectionTypeSchema = z.union([z.literal('auto'), z.literal('manual')]);

export const tSubscriptionItemPricingModelSchema = z.union([
	z.literal('flat_fee'),
	z.literal('per_unit'),
	z.literal('stairstep'),
	z.literal('tiered'),
	z.literal('volume'),
]);

export const tSubscriptionStatusSchema = z.union([
	z.literal('active'),
	z.literal('cancelled'),
	z.literal('future'),
	z.literal('in_trial'),
	z.literal('incomplete'),
	z.literal('incomplete_expired'),
	z.literal('non_renewing'),
	z.literal('past_due'),
	z.literal('paused'),
	z.literal('pending'),
	z.literal('pending_setup'),
	z.literal('unpaid'),
]);

export const tTenantsTypeSchema = z.union([z.literal('brand'), z.literal('staff')]);

export const tUsersRoleSchema = z.union([
	z.literal('admin'),
	z.literal('member'),
	z.literal('suspended'),
	z.literal('agent_admin'),
	z.literal('agent_member'),
]);

export const tUsersStateSchema = z.union([z.literal('active'), z.literal('invited'), z.literal('suspended')]);

export const tWorkflowStateChangeEventStateSchema = z.union([
	z.literal('completed'),
	z.literal('converted'),
	z.literal('exited_early'),
	z.literal('filtered_out'),
	z.literal('in_progress'),
	z.literal('link_clicked'),
	z.literal('started'),
	z.literal('triggered'),
]);

export const tWorkflowsGoalSchema = z.union([
	z.literal('cart_recovered'),
	z.literal('link_clicked'),
	z.literal('none'),
]);

export const tWorkflowsGoalAttributionSchema = z.union([z.literal('discount'), z.literal('link')]);

export const tWorkflowsTypeSchema = z.union([
	z.literal('checkout'),
	z.literal('schedule'),
	z.literal('cart'),
	z.literal('order'),
	z.literal('tap_to_text'),
]);

const RulePropertiesSchema = z.object({
	conditions: z.any(),
	event: z.object({
		type: z.string(),
		params: z.record(z.any()).optional(),
	}),
	name: z.string().optional(),
	priority: z.number().optional(),
});

export const tBrandAttributionSchema = z.array(RulePropertiesSchema);

export const prismaMigrationsSchema = z.object({
	id: z.string(),
	checksum: z.string(),
	finished_at: z.date().nullable(),
	migration_name: z.string(),
	logs: z.string().nullable(),
	rolled_back_at: z.date().nullable(),
	started_at: z.date(),
	applied_steps_count: z.number(),
});

export const prismaMigrationsInputSchema = z.object({
	id: z.string(),
	checksum: z.string(),
	finished_at: z.date().optional().nullable(),
	migration_name: z.string(),
	logs: z.string().optional().nullable(),
	rolled_back_at: z.date().optional().nullable(),
	started_at: z.date().optional(),
	applied_steps_count: z.number().optional(),
});

export const alembicVersionSchema = z.object({
	version_num: z.string(),
});

export const alembicVersionInputSchema = z.object({
	version_num: z.string(),
});

export const brandAuditLogSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	brand_id: z.string(),
	is_compliant: z.boolean(),
	last_checked: z.date(),
	issue_type: tBrandAuditLogIssueTypeSchema.nullable(),
	description: z.string(),
	rule_id: z.string(),
	status: tBrandAuditLogStatusSchema,
});

export const brandAuditLogInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	brand_id: z.string(),
	is_compliant: z.boolean(),
	last_checked: z.date(),
	issue_type: tBrandAuditLogIssueTypeSchema.optional().nullable(),
	description: z.string(),
	rule_id: z.string(),
	status: tBrandAuditLogStatusSchema,
});

export const brandIntegrationsSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	brand_id: z.string(),
	integration_id: z.string(),
	is_enabled: z.boolean(),
	settings: jsonSchema,
	status: tBrandIntegrationsStatusSchema,
	lookup_id: z.string().nullable(),
});

export const brandIntegrationsInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	brand_id: z.string(),
	integration_id: z.string(),
	is_enabled: z.boolean(),
	settings: jsonSchema,
	status: tBrandIntegrationsStatusSchema,
	lookup_id: z.string().optional().nullable(),
});

export const brandPermissionsSchema = z.object({
	id: z.string(),
	permission_id: z.string(),
	brand_id: z.string(),
	author_id: z.string(),
	extra_data: jsonSchema.nullable(),
	state: tPermissionsStateSchema,
	created_at: z.date(),
	updated_at: z.date(),
});

export const brandPermissionsInputSchema = z.object({
	id: z.string(),
	permission_id: z.string(),
	brand_id: z.string(),
	author_id: z.string(),
	extra_data: jsonSchema.optional().nullable(),
	state: tPermissionsStateSchema.optional(),
	created_at: z.date(),
	updated_at: z.date(),
});

export const brandsSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	tenant_id: z.string(),
	name: z.string(),
	domain: z.string(),
	legacy_id: z.number().nullable(),
	legacy_migrated_at: z.date().nullable(),
	is_enabled: z.boolean(),
	hubspot_id: z.string(),
	industry: tIndustrySchema.nullable(),
	support_email: z.string(),
	abandoned_cart_period: z.number(),
	address: jsonSchema.nullable(),
	billing_customer_id: z.string().nullable(),
	faq_scraped_at: z.date().nullable(),
	faq_page_url: z.string().nullable(),
	attribution_hard_cap: z.number(),
	tos_url: z.string(),
	privacy_policy_url: z.string(),
	attribution_type: tBrandsAttributionTypeSchema,
	extra_data: jsonSchema.nullable(),
	support_phone: z.string(),
	check_sms_marketing_consent: z.boolean(),
	re_engage_period: z.number(),
	attribution_rules: tBrandAttributionSchema.optional(),
	status: tBrandsStatusSchema.optional(),
});

export const brandsInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	tenant_id: z.string(),
	name: z.string(),
	domain: z.string(),
	legacy_id: z.number().optional().nullable(),
	legacy_migrated_at: z.date().optional().nullable(),
	is_enabled: z.boolean(),
	hubspot_id: z.string().optional(),
	industry: tIndustrySchema.optional().nullable(),
	support_email: z.string().optional(),
	escalation_email: z.string().optional(),
	abandoned_cart_period: z.number().optional(),
	address: jsonSchema.optional().nullable(),
	billing_customer_id: z.string().optional().nullable(),
	faq_scraped_at: z.date().optional().nullable(),
	faq_page_url: z.string().optional().nullable(),
	attribution_hard_cap: z.number().optional(),
	tos_url: z.string().optional(),
	privacy_policy_url: z.string().optional(),
	attribution_type: tBrandsAttributionTypeSchema.optional(),
	attribution_rules: tBrandAttributionSchema.optional(),
	extra_data: jsonSchema.optional().nullable(),
	support_phone: z.string().optional(),
	check_sms_marketing_consent: z.boolean().optional(),
	re_engage_period: z.number().optional(),
});

export const cartsSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	external_id: z.string(),
	total: z.number(),
	state: tCartsStateSchema,
	brand_integration_id: z.string(),
	contact_id: z.string().nullable(),
	legacy_id: z.number().nullable(),
	legacy_migrated_at: z.date().nullable(),
	legacy_segment_id: z.number().nullable(),
});

export const cartsInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	external_id: z.string(),
	total: z.number(),
	state: tCartsStateSchema,
	brand_integration_id: z.string(),
	contact_id: z.string().optional().nullable(),
	legacy_id: z.number().optional().nullable(),
	legacy_migrated_at: z.date().optional().nullable(),
	legacy_segment_id: z.number().optional().nullable(),
});

export const checkoutsSchema = z.object({
	id: z.string(),
	currency_code: tCurrencyCodesSchema,
	shipping_country_code: tCountryCodesSchema.nullable(),
	shipping_province: z.string(),
	shipping_tz: z.string(),
	total_price: z.number(),
	contact_id: z.string().nullable(),
	brand_integration_id: z.string(),
	external_id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	completed_at: z.date().nullable(),
	extra_data: jsonSchema.nullable(),
	cleaned_raw_data: jsonSchema.nullable(),
	abandoned_at: z.date().nullable(),
	state: tCheckoutStateSchema,
});

export const checkoutsInputSchema = z.object({
	id: z.string(),
	currency_code: tCurrencyCodesSchema,
	shipping_country_code: tCountryCodesSchema.optional().nullable(),
	shipping_province: z.string(),
	shipping_tz: z.string(),
	total_price: z.number(),
	contact_id: z.string().optional().nullable(),
	brand_integration_id: z.string(),
	external_id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	completed_at: z.date().optional().nullable(),
	extra_data: jsonSchema.optional().nullable(),
	cleaned_raw_data: jsonSchema.optional().nullable(),
	abandoned_at: z.date().optional().nullable(),
	state: tCheckoutStateSchema.optional(),
});

export const contactChannelsSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	contact_id: z.string(),
	brand_integration_id: z.string(),
	username: z.string(),
	external_id: z.string(),
	compliance_type: tContactChannelsComplianceSchema,
	last_engaged_at: z.date().nullable(),
	status: tContactChannelsStatusSchema,
	extra_data: jsonSchema.nullable(),
	engagement_status: z.string().optional().nullable(),
});

export const contactChannelsInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	contact_id: z.string(),
	brand_integration_id: z.string(),
	username: z.string(),
	external_id: z.string(),
	compliance_type: tContactChannelsComplianceSchema.optional(),
	last_engaged_at: z.date().optional().nullable(),
	status: tContactChannelsStatusSchema.optional(),
	extra_data: jsonSchema.optional().nullable(),
});

export const contactsSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	given_name: z.string(),
	family_name: z.string(),
	external_id: z.string(),
	legacy_id: z.string().nullable(),
	legacy_migrated_at: z.date().nullable(),
	brand_id: z.string(),
	source: tContactsSourceSchema,
	buyer_accepts_sms_marketing: z.boolean().nullable(),
	extra_data: jsonSchema.nullable(),
});

export const contactsInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	given_name: z.string(),
	family_name: z.string(),
	external_id: z.string(),
	legacy_id: z.string().optional().nullable(),
	legacy_migrated_at: z.date().optional().nullable(),
	brand_id: z.string(),
	source: tContactsSourceSchema,
	buyer_accepts_sms_marketing: z.boolean().optional().nullable(),
	extra_data: jsonSchema.optional().nullable(),
});

export const conversationFeedbackSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	conversation_id: z.string(),
	rating: z.number(),
	comment: z.string(),
	legacy_id: z.number().nullable(),
	legacy_migrated_at: z.date().nullable(),
});

export const conversationFeedbackInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	conversation_id: z.string(),
	message_id: z.string(),
	rating: z.number(),
	comment: z.string(),
	legacy_id: z.number().optional().nullable(),
	legacy_migrated_at: z.date().optional().nullable(),
});

export const conversationMessagesSchema = z.object({
	id: z.string(),
	updated_at: z.date(),
	created_at: z.date(),
	conversation_id: z.string(),
	direction: tConversationMessagesDirectionSchema,
	legacy_id: z.number().nullable(),
	legacy_migrated_at: z.date().nullable(),
	body: z.string(),
	status: tConversationMessagesStatusSchema,
	sent_at: z.date().nullable(),
	scheduled_for: z.date().nullable(),
	external_id: z.string(),
	extra_data: jsonSchema.nullable(),
});

export const conversationMessagesInputSchema = z.object({
	id: z.string(),
	updated_at: z.coerce.date(),
	created_at: z.coerce.date().optional(),
	conversation_id: z.string(),
	direction: tConversationMessagesDirectionSchema,
	legacy_id: z.number().optional().nullable(),
	legacy_migrated_at: z.date().optional().nullable(),
	body: z.string(),
	status: tConversationMessagesStatusSchema.optional(),
	sent_at: z.date().optional().nullable(),
	scheduled_for: z.date().optional().nullable(),
	external_id: z.string().optional(),
	extra_data: jsonSchema.optional().nullable(),
});

export const conversationsSchema = z.object({
	id: z.string(),
	updated_at: z.date(),
	created_at: z.date(),
	channel_id: z.string(),
	is_hidden: z.boolean(),
	legacy_id: z.number().nullable(),
	legacy_migrated_at: z.date().nullable(),
	extra_data: jsonSchema.nullable(),
	brand_id: z.string(),
	checkout_id: z.string().nullable(),
	workflow_id: z.string().nullable(),
});

export const conversationsInputSchema = z.object({
	id: z.string(),
	updated_at: z.coerce.date(),
	created_at: z.coerce.date().optional(),
	channel_id: z.string(),
	is_hidden: z.boolean(),
	legacy_id: z.number().optional().nullable(),
	legacy_migrated_at: z.date().optional().nullable(),
	cart_id: z.string().optional().nullable(),
	extra_data: jsonSchema.optional().nullable(),
	brand_id: z.string(),
	checkout_id: z.string().optional().nullable(),
	workflow_id: z.string().optional().nullable(),
});

export const discountCodesSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	brand_id: z.string(),
	conversation_id: z.string().nullable(),
	code: z.string(),
	external_id: z.string().nullable(),
});

export const discountCodesInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	brand_id: z.string(),
	conversation_id: z.string().optional().nullable(),
	code: z.string(),
	external_id: z.string().optional().nullable(),
});

export const discountRulesSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	brand_id: z.string(),
	type: tDiscountRulesTypeSchema,
	value: z.number(),
	duration: z.number(),
	legacy_id: z.number().nullable(),
	legacy_price_rule_id: z.number().nullable(),
	legacy_discount_id: z.number().nullable(),
	legacy_discount_code: z.string(),
	legacy_migrated_at: z.date().nullable(),
	extra_data: jsonSchema.nullable(),
});

export const discountRulesInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	brand_id: z.string(),
	type: tDiscountRulesTypeSchema,
	value: z.number(),
	duration: z.number(),
	legacy_id: z.number().optional().nullable(),
	legacy_price_rule_id: z.number().optional().nullable(),
	legacy_discount_id: z.number().optional().nullable(),
	legacy_discount_code: z.string(),
	legacy_migrated_at: z.date().optional().nullable(),
	extra_data: jsonSchema.optional().nullable(),
});

export const integrationsSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	name: z.string(),
	summary: z.string(),
	category: tIntegrationsCategorySchema,
	support_url: z.string(),
	is_published: z.boolean(),
	class_path: z.string(),
	description: z.string(),
	properties: jsonSchema,
});

export const integrationsInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	name: z.string(),
	summary: z.string(),
	category: tIntegrationsCategorySchema,
	support_url: z.string(),
	is_published: z.boolean(),
	class_path: z.string(),
	description: z.string().optional(),
	properties: jsonSchema.optional(),
});

export const kbCardsSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	brand_id: z.string(),
	author_id: z.string().nullable(),
	title: z.string(),
	content: z.string(),
	legacy_migrated: z.boolean(),
	source: tKbSourceSchema.nullable(),
	status: tKbStatusSchema.nullable(),
	flow_id: z.string().nullable(),
	extra_data: jsonSchema.nullable(),
});

export const kbCardsInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	brand_id: z.string(),
	author_id: z.string().optional().nullable(),
	title: z.string(),
	content: z.string(),
	legacy_migrated: z.boolean(),
	source: tKbSourceSchema.optional().nullable(),
	status: tKbStatusSchema.optional().nullable(),
	flow_id: z.string().optional().nullable(),
	extra_data: jsonSchema.optional().nullable(),
});

export const leadsSchema = z.object({
	id: z.string(),
	email: z.string(),
	source_name: z.string().nullable(),
	first_name: z.string().nullable(),
	last_name: z.string().nullable(),
	store_url: z.string().nullable(),
	store_type: z.string().nullable(),
	support_email: z.string().nullable(),
	support_phone: z.string().nullable(),
	billing_email: z.string().nullable(),
	extra_data: jsonSchema.nullable(),
	brand_id: z.string().nullable(),
	company_name: z.string().nullable(),
	company_website: z.string().nullable(),
	created_at: z.date(),
	source_type: z.string().nullable(),
	tenant_id: z.string().nullable(),
	updated_at: z.date(),
	user_id: z.string().nullable(),
});

export const leadsInputSchema = z.object({
	id: z.string(),
	email: z.string(),
	source_name: z.string().optional(),
	first_name: z.string().optional(),
	last_name: z.string().optional(),
	store_url: z.string().optional().nullable(),
	store_type: z.string().optional().nullable(),
	support_email: z.string().optional().nullable(),
	support_phone: z.string().optional().nullable(),
	billing_email: z.string().optional().nullable(),
	extra_data: jsonSchema.optional().nullable(),
	brand_id: z.string().optional().nullable(),
	annual_revenue: z.number().optional(),
	company_name: z.string().optional(),
	company_website: z.string().optional().nullable(),
	created_at: z.date(),
	source_type: z.string().optional().nullable(),
	tenant_id: z.string().optional().nullable(),
	updated_at: z.date(),
	user_id: z.string().optional().nullable(),
});

export const orderConversationsSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	conversation_id: z.string(),
	order_id: z.string(),
});

export const orderConversationsInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	conversation_id: z.string(),
	order_id: z.string(),
});

export const ordersSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	external_id: z.string(),
	total: z.number(),
	cart_id: z.string().nullable(),
	state: tOrdersStateSchema,
	number: z.string(),
	brand_integration_id: z.string(),
	contact_id: z.string().nullable(),
	legacy_id: z.number().nullable(),
	legacy_migrated_at: z.date().nullable(),
	currency_code: tCurrencyCodesSchema,
	checkout_id: z.string().nullable(),
	cleaned_raw_data: jsonSchema.nullable(),
	workflow_id: z.string().nullable(),
	billing_status: tOrdersBillingStatusSchema.nullable(),
	charge_id: z.string().nullable(),
});

export const ordersInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	external_id: z.string(),
	total: z.number(),
	cart_id: z.string().optional().nullable(),
	state: tOrdersStateSchema,
	number: z.string(),
	brand_integration_id: z.string(),
	contact_id: z.string().optional().nullable(),
	legacy_id: z.number().optional().nullable(),
	legacy_migrated_at: z.date().optional().nullable(),
	currency_code: tCurrencyCodesSchema.optional(),
	checkout_id: z.string().optional().nullable(),
	cleaned_raw_data: jsonSchema.optional().nullable(),
	workflow_id: z.string().optional().nullable(),
	billing_status: tOrdersBillingStatusSchema.optional().nullable(),
	charge_id: z.string().optional().nullable(),
});

export const permissionsSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	action: tPermissionsActionSchema,
	attribute: z.string(),
	extra_data: jsonSchema.nullable(),
});

export const permissionsInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	action: tPermissionsActionSchema.optional(),
	attribute: z.string(),
	extra_data: jsonSchema.optional().nullable(),
});

export const planGatewaysSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	external_id: z.string().nullable(),
	gateway: tPaymentTypesSchema,
	plan_id: z.string(),
});

export const planGatewaysInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	external_id: z.string().optional().nullable(),
	gateway: tPaymentTypesSchema,
	plan_id: z.string(),
});

export const planPermissionsSchema = z.object({
	id: z.string(),
	permission_id: z.string(),
	plan_id: z.string(),
	extra_data: jsonSchema.nullable(),
	state: tPermissionsStateSchema,
	created_at: z.date(),
	updated_at: z.date(),
});

export const planPermissionsInputSchema = z.object({
	id: z.string(),
	permission_id: z.string(),
	plan_id: z.string(),
	extra_data: jsonSchema.optional().nullable(),
	state: tPermissionsStateSchema.optional(),
	created_at: z.date(),
	updated_at: z.date(),
});

export const plansSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	name: z.string(),
	description: z.string(),
	price: z.number(),
	usage_charge_percentage: z.number(),
	extra_data: jsonSchema.nullable(),
	frequency: tPlansFrequencySchema,
	status: tPlansStatusSchema,
});

export const plansInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	name: z.string(),
	description: z.string(),
	price: z.number(),
	usage_charge_percentage: z.number(),
	extra_data: jsonSchema.optional().nullable(),
	frequency: tPlansFrequencySchema.optional(),
	status: tPlansStatusSchema.optional(),
});

export const productsSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	brand_integration_id: z.string(),
	product_id: z.number().nullable(),
	title: z.string().nullable(),
	original_title: z.string(),
	legacy_id: z.number().nullable(),
	legacy_migrated_at: z.date().nullable(),
});

export const productsInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	brand_integration_id: z.string(),
	product_id: z.number().optional().nullable(),
	title: z.string().optional().nullable(),
	original_title: z.string(),
	legacy_id: z.number().optional().nullable(),
	legacy_migrated_at: z.date().optional().nullable(),
});

export const subscriptionChargesSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	amount: z.number(),
	brand_id: z.string(),
	charge_collected_at: z.date().nullable(),
	external_id: z.string().nullable(),
	subscription_id: z.string(),
	extra_data: jsonSchema.nullable(),
	status: tSubscriptionChargesStatusSchema,
	type: tSubscriptionChargesTypeSchema,
	legacy_id: z.string().nullable(),
	plan_id: z.string().nullable(),
});

export const subscriptionChargesInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	amount: z.number(),
	brand_id: z.string(),
	charge_collected_at: z.date().optional().nullable(),
	external_id: z.string().optional().nullable(),
	subscription_id: z.string(),
	extra_data: jsonSchema.optional().nullable(),
	status: tSubscriptionChargesStatusSchema,
	type: tSubscriptionChargesTypeSchema,
	legacy_id: z.string().optional().nullable(),
	plan_id: z.string().optional().nullable(),
});

export const subscriptionPaymentSourceSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	external_id: z.string(),
	gateway: tPaymentTypesSchema,
	gateway_account_id: z.string(),
	legacy_id: z.string(),
	brand_id: z.string(),
});

export const subscriptionPaymentSourceInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	external_id: z.string(),
	gateway: tPaymentTypesSchema,
	gateway_account_id: z.string(),
	legacy_id: z.string(),
	brand_id: z.string(),
});

export const subscriptionsSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	status: tSubscriptionStatusSchema,
	external_id: z.string(),
	started_at: z.date(),
	activated_at: z.date().nullable(),
	legacy_id: z.string().nullable(),
	brand_id: z.string(),
	plan_id: z.string(),
	payment_source_id: z.string(),
	last_billed_at: z.date().nullable(),
	billing_email: z.string().nullable(),
	collection_type: tSubscriptionCollectionTypeSchema,
	trial_end_at: z.date().nullable(),
	trial_start_at: z.date().nullable(),
	extra_data: jsonSchema.nullable(),
});

export const subscriptionsInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	status: tSubscriptionStatusSchema,
	external_id: z.string(),
	started_at: z.date(),
	activated_at: z.date().optional().nullable(),
	legacy_id: z.string().optional().nullable(),
	brand_id: z.string(),
	plan_id: z.string(),
	payment_source_id: z.string(),
	last_billed_at: z.date().optional().nullable(),
	billing_email: z.string().optional().nullable(),
	collection_type: tSubscriptionCollectionTypeSchema.optional(),
	trial_end_at: z.date().optional().nullable(),
	trial_start_at: z.date().optional().nullable(),
	extra_data: jsonSchema.optional().nullable(),
});

export const tenantPermissionsSchema = z.object({
	id: z.string(),
	permission_id: z.string(),
	tenant_id: z.string(),
	author_id: z.string(),
	extra_data: jsonSchema.nullable(),
	state: tPermissionsStateSchema,
	created_at: z.date(),
	updated_at: z.date(),
});

export const tenantPermissionsInputSchema = z.object({
	id: z.string(),
	permission_id: z.string(),
	tenant_id: z.string(),
	author_id: z.string(),
	extra_data: jsonSchema.optional().nullable(),
	state: tPermissionsStateSchema.optional(),
	created_at: z.date(),
	updated_at: z.date(),
});

export const tenantsSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	name: z.string(),
	phone: z.string().nullable(),
	email: z.string(),
	is_enabled: z.boolean(),
	hubspot_id: z.string(),
	type: tTenantsTypeSchema.nullable(),
	extra_data: jsonSchema.nullable(),
});

export const tenantsInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	name: z.string(),
	phone: z.string().optional().nullable(),
	email: z.string(),
	is_enabled: z.boolean(),
	hubspot_id: z.string().optional(),
	type: tTenantsTypeSchema.optional().nullable(),
	extra_data: jsonSchema.optional().nullable(),
});

export const trusthubRulesSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	order: z.number(),
	name: z.string(),
	title: z.string(),
	locked: z.boolean(),
	brief_description: z.string(),
	how_rule_works: z.string(),
	documentation_link: z.string(),
});

export const trusthubRulesInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	order: z.number(),
	name: z.string().optional(),
	title: z.string().optional(),
	locked: z.boolean().optional(),
	brief_description: z.string().optional(),
	how_rule_works: z.string().optional(),
	documentation_link: z.string().optional(),
});

export const userPermissionsSchema = z.object({
	id: z.string(),
	permission_id: z.string(),
	user_id: z.string(),
	author_id: z.string(),
	state: tPermissionsStateSchema,
	extra_data: jsonSchema.nullable(),
	created_at: z.date(),
	updated_at: z.date(),
});

export const userPermissionsInputSchema = z.object({
	id: z.string(),
	permission_id: z.string(),
	user_id: z.string(),
	author_id: z.string(),
	state: tPermissionsStateSchema.optional(),
	extra_data: jsonSchema.optional().nullable(),
	created_at: z.date(),
	updated_at: z.date(),
});

export const usersSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	tenant_id: z.string(),
	given_name: z.string().nullable(),
	family_name: z.string().nullable(),
	email: z.string(),
	phone: z.string(),
	is_enabled: z.boolean(),
	is_staff: z.boolean(),
	hubspot_id: z.string(),
	last_login: z.date().nullable(),
	cognito_id: z.string().nullable(),
	password: z.string().nullable(),
	role: tUsersRoleSchema,
	state: tUsersStateSchema,
});

export const usersInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	tenant_id: z.string(),
	given_name: z.string().optional().nullable(),
	family_name: z.string().optional().nullable(),
	email: z.string(),
	phone: z.string(),
	is_enabled: z.boolean(),
	is_staff: z.boolean(),
	hubspot_id: z.string().optional(),
	last_login: z.date().optional().nullable(),
	cognito_id: z.string().optional().nullable(),
	password: z.string().optional().nullable(),
	role: tUsersRoleSchema.optional(),
	state: tUsersStateSchema.optional(),
});

export const workflowGoalStateChangeEventsSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	workflow_id: z.string(),
	state: tWorkflowStateChangeEventStateSchema,
	checkout_id: z.string().nullable(),
	conversation_id: z.string().nullable(),
});

export const workflowGoalStateChangeEventsInputSchema = z.object({
	id: z.string().optional(),
	created_at: z.date().optional(),
	workflow_id: z.string(),
	state: tWorkflowStateChangeEventStateSchema,
	checkout_id: z.string().optional().nullable(),
	conversation_id: z.string().optional().nullable(),
});

export const workflowGoalsSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	brand_id: z.string(),
	workflow_id: z.string(),
	contact_id: z.string().nullable(),
	conversation_id: z.string().nullable(),
	legacy_id: z.number().nullable(),
	attribution: tWorkflowsGoalAttributionSchema.nullable(),
});

export const workflowGoalsInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	brand_id: z.string(),
	workflow_id: z.string(),
	contact_id: z.string().optional().nullable(),
	conversation_id: z.string().optional().nullable(),
	legacy_id: z.number().optional().nullable(),
	attribution: tWorkflowsGoalAttributionSchema.optional().nullable(),
});

export const workflowsSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	brand_integration_id: z.string(),
	name: z.string(),
	type: tWorkflowsTypeSchema,
	rules: jsonSchema,
	action: jsonSchema,
	starts_at: z.date().nullable(),
	ends_at: z.date().nullable(),
	is_paused: z.boolean(),
	legacy_id: z.number().nullable(),
	legacy_migrated_at: z.date().nullable(),
	message: jsonSchema.nullable(),
	goal: tWorkflowsGoalSchema,
	discount_rule_id: z.string().nullable(),
	timezone: z.string().nullable(),
	delay_minutes: z.number(),
});

export const workflowsInputSchema = z.object({
	id: z.string(),
	created_at: z.date(),
	updated_at: z.date(),
	brand_integration_id: z.string(),
	name: z.string(),
	type: tWorkflowsTypeSchema,
	rules: jsonSchema,
	action: jsonSchema,
	starts_at: z.string().datetime({ offset: true }).optional().nullable(),
	ends_at: z.string().datetime({ offset: true }).optional().nullable(),
	is_paused: z.boolean(),
	legacy_id: z.number().optional().nullable(),
	legacy_migrated_at: z.date().optional().nullable(),
	message: jsonSchema.optional().nullable(),
	goal: tWorkflowsGoalSchema.optional(),
	discount_rule_id: z.string().optional().nullable(),
	timezone: z.string().optional().nullable(),
	delay_minutes: z.number().optional(),
});

export const phoneNumberRegistrationSchema = z.object({
	legal_company_name: z.string(),
	address: jsonSchema.optional().nullable(),
	tax_ein: z.string(),
	business_type: z.string(),
	industry_type: tIndustrySchema.nullable(),
	area_code_preference: z.string(),
	is_company_public: z.boolean(),
	stock_symbol: z.string(),
	stock_market: z.string(),
	compliance_contact_name: z.string(),
	compliant_contact_phone: z.string(),
	compliance_contact_email: z.string(),
	privacy_policy_url: z.string(),
	terms_n_conditions_url: z.string(),
});

export const tableTypesSchema = z.object({
	_prisma_migrations: z.object({
		select: prismaMigrationsSchema,
		input: prismaMigrationsInputSchema,
	}),
	alembic_version: z.object({
		select: alembicVersionSchema,
		input: alembicVersionInputSchema,
	}),
	brand_audit_log: z.object({
		select: brandAuditLogSchema,
		input: brandAuditLogInputSchema,
	}),
	brand_integrations: z.object({
		select: brandIntegrationsSchema,
		input: brandIntegrationsInputSchema,
	}),
	brand_permissions: z.object({
		select: brandPermissionsSchema,
		input: brandPermissionsInputSchema,
	}),
	brands: z.object({
		select: brandsSchema,
		input: brandsInputSchema,
	}),
	carts: z.object({
		select: cartsSchema,
		input: cartsInputSchema,
	}),
	checkouts: z.object({
		select: checkoutsSchema,
		input: checkoutsInputSchema,
	}),
	contact_channels: z.object({
		select: contactChannelsSchema,
		input: contactChannelsInputSchema,
	}),
	contacts: z.object({
		select: contactsSchema,
		input: contactsInputSchema,
	}),
	conversation_feedback: z.object({
		select: conversationFeedbackSchema,
		input: conversationFeedbackInputSchema,
	}),
	conversation_messages: z.object({
		select: conversationMessagesSchema,
		input: conversationMessagesInputSchema,
	}),
	conversations: z.object({
		select: conversationsSchema,
		input: conversationsInputSchema,
	}),
	discount_codes: z.object({
		select: discountCodesSchema,
		input: discountCodesInputSchema,
	}),
	discount_rules: z.object({
		select: discountRulesSchema,
		input: discountRulesInputSchema,
	}),
	integrations: z.object({
		select: integrationsSchema,
		input: integrationsInputSchema,
	}),
	kb_cards: z.object({
		select: kbCardsSchema,
		input: kbCardsInputSchema,
	}),
	leads: z.object({
		select: leadsSchema,
		input: leadsInputSchema,
	}),
	order_conversations: z.object({
		select: orderConversationsSchema,
		input: orderConversationsInputSchema,
	}),
	orders: z.object({
		select: ordersSchema,
		input: ordersInputSchema,
	}),
	permissions: z.object({
		select: permissionsSchema,
		input: permissionsInputSchema,
	}),
	plan_gateways: z.object({
		select: planGatewaysSchema,
		input: planGatewaysInputSchema,
	}),
	plan_permissions: z.object({
		select: planPermissionsSchema,
		input: planPermissionsInputSchema,
	}),
	plans: z.object({
		select: plansSchema,
		input: plansInputSchema,
	}),
	products: z.object({
		select: productsSchema,
		input: productsInputSchema,
	}),
	subscription_charges: z.object({
		select: subscriptionChargesSchema,
		input: subscriptionChargesInputSchema,
	}),
	subscription_payment_source: z.object({
		select: subscriptionPaymentSourceSchema,
		input: subscriptionPaymentSourceInputSchema,
	}),
	subscriptions: z.object({
		select: subscriptionsSchema,
		input: subscriptionsInputSchema,
	}),
	tenant_permissions: z.object({
		select: tenantPermissionsSchema,
		input: tenantPermissionsInputSchema,
	}),
	tenants: z.object({
		select: tenantsSchema,
		input: tenantsInputSchema,
	}),
	trusthub_rules: z.object({
		select: trusthubRulesSchema,
		input: trusthubRulesInputSchema,
	}),
	user_permissions: z.object({
		select: userPermissionsSchema,
		input: userPermissionsInputSchema,
	}),
	users: z.object({
		select: usersSchema,
		input: usersInputSchema,
	}),
	workflow_goal_state_change_events: z.object({
		select: workflowGoalStateChangeEventsSchema,
		input: workflowGoalStateChangeEventsInputSchema,
	}),
	workflow_goals: z.object({
		select: workflowGoalsSchema,
		input: workflowGoalsInputSchema,
	}),
	workflows: z.object({
		select: workflowsSchema,
		input: workflowsInputSchema,
	}),
});
